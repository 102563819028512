import Select, { StylesConfig } from 'react-select';
import { OptionType } from '../../../pages/user/ApproveSubmittedTimeEntries';


interface CustomSelectProps {
    value: OptionType | OptionType[] | null;
    placeholder: string;
    options: OptionType[];
    isClearable?: boolean;
    isSearchable?: boolean;
    isMulti?: boolean;
    onChange: (value: any) => void;
}

const customStyles: StylesConfig<OptionType, boolean> = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? '#3182ce' : '#E2E8F0',
        boxShadow: state.isFocused ? '0 0 0 1px #3182ce' : '',
    }),
    placeholder: (baseStyles) => ({
        ...baseStyles,
        fontSize: '1rem',
        color: '#758399',
    }),
};

const CustomSelect = ({
    value,
    placeholder,
    options,
    isClearable = true,
    isSearchable = false,
    isMulti = false,
    onChange,
}: CustomSelectProps) => {
    return (
        <Select
            value={value}
            placeholder={placeholder}
            options={options}
            isClearable={isClearable}
            isSearchable={isSearchable}
            styles={customStyles}
            isMulti={isMulti}
            onChange={onChange}
        />
    );
};

export default CustomSelect;
