import { cloneElement, isValidElement, Children, ReactElement } from "react"

interface FormPanelDisplayChildrenProps {
  entry?: any
}

export interface FormPanelDisplayProps {
  entry?: any
  children: React.ReactNode | React.ReactNode[]
}

export function FormPanelDisplay({ entry, children }: FormPanelDisplayProps) {
  const clonedChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      // return <child.type {...child.props} entry={entry}>{child.props.children}</child.type>
      return cloneElement(
        child as ReactElement<FormPanelDisplayChildrenProps>,
        { entry },
      )
    }
    return child
  })

  return <>{clonedChildren}</>
}
