import { useMemo } from "react"
import { useAppSelector, useTrait } from "../../app/hooks"
import { RootState } from "../../app/store"
import { TraitLine } from "../../components/TraitLine"
import { selectAllLocations } from "./locationsSlice"
import { selectLocationById, selectTerminalById } from "./terminalsSlice"

interface LocationTraitProps extends TraitProps {
  terminalId?: ExistingOrNew
}

export function LocationTrait({ id, terminalId }: LocationTraitProps) {
  const locations = useAppSelector(selectAllLocations)
  const terminal = useAppSelector((state) =>
    selectTerminalById(state, typeof id == "number" ? terminalId || 0 : 0),
  )
  const terminalLocations = terminal && terminal.locations
  const { isNew, trait } = useMemo(() => {
    if (typeof id === 'number') {
      // Check if the location exists in the terminal's locations
      const terminalLocation = terminalLocations?.find((location) => location.id === id);
      if (terminalLocation) {
        return { isNew: false, trait: terminalLocation?.name };
      }
      // If not found in terminal locations, check all locations
      const generalLocation = locations.find((location) => location.id === id);
      if (generalLocation) {
        return { isNew: true, trait: generalLocation?.name };
      }
    }

    return { isNew: true, trait: id };
  }, [terminalLocations, locations, id]);

  return <TraitLine isNew={isNew}>{trait}</TraitLine>
}
