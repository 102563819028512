import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from "react-redux"
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import { ChakraProvider } from "@chakra-ui/react"
import { store, persistor } from "./app/store"
import { AuthArea } from "./layout/areas/AuthArea"
import { DriverHomePage } from "./pages/user/DriverHomePage"
import { UserArea } from "./layout/areas/UserArea"
import { TicketsPage } from "./pages/user/TicketsPage"
import { TicketDetailPage } from "./pages/user/TicketDetailPage"
import { LoginPage } from "./pages/auth/LoginPage"
import { LogoutPage } from "./pages/auth/LogoutPage"
import { ProfilePage } from "./pages/user/ProfilePage"
import { DraftPage } from "./pages/user/DraftPage"
import { TrackingPage } from "./pages/user/TrackingPage"
import { ChargesPage } from "./pages/user/ChargesPage"
import { ProtectedRoute } from "./ProtectedRoute"
import { PrintArea } from "./layout/areas/PrintArea"
import { TicketDetailPrint } from "./pages/print/TicketDetailPrint"
import { StampTicketDetail } from "./pages/stamp/StampTicketDetail"
import { PrintDraft } from "./pages/print/PrintDraft"
import { SubmittedTimeEntries } from "./pages/user/SubmittedTimeEntries"
import { TicketEditPage } from "./pages/user/TicketEditPage"
import { DraftCharge } from "./pages/user/DraftCharge"
import { NetworkProvider } from "./contaxt/NetworkProvider"
import { ApproveSubmittedTimeEntries } from "./pages/user/ApproveSubmittedTimeEntries"
import { ForgotPasswordPage } from "./pages/user/ForgotPasswordPage"
import { ResetPasswordPage } from "./pages/user/ResetPasswordPage"

const router = createBrowserRouter([
  {
    path: "/user",
    element: <UserArea />,
    children: [
      {
        index: true,
        element: <Navigate to="home" />
      },
      {
        path: "home",
        element: <DriverHomePage />
      },

      {
        path: "profile",
        element: <ProfilePage />,
      },
      {
        path: "draft/:id?",
        element: <DraftPage />,
      },
      {
        path: "/user/draft/:id/charges",
        element: <DraftCharge />,
      },
      {
        path: "tracking",
        element: <TrackingPage />,
      },
      {
        path: "tickets",
        element: <TicketsPage />,
      },
      {
        path: "tickets/:ticketId",
        element: <TicketDetailPage />,
      },
      {
        path: "tickets/:ticketId/edit",
        element: <TicketEditPage />,
      },
      {
        path: "tickets/:ticketId/charges",
        element: <ProtectedRoute Component={ChargesPage} roles={['driver-lead', 'driver']} />,
      },
      {
        path: "stamp/tickets/:ticketId",
        element: <StampTicketDetail />,
      },
      {
        path: "time-entries",
        element: <SubmittedTimeEntries />,
      },
      {
        path: "approve-time-entries",
        element: <ProtectedRoute Component={ApproveSubmittedTimeEntries} roles={['driver-lead']} />,
      },
    ],
  },
  {
    path: "/print",
    element: <PrintArea />,
    children: [
      {
        path: "tickets/:ticketId/",
        element: <TicketDetailPrint />,
      },
      {
        path: "draft/:id",
        element: <PrintDraft />,
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthArea />,
    children: [
      { index: true, element: <Navigate to="login" /> },
      { path: "login", element: <LoginPage /> },
      { path: "logout", element: <LogoutPage /> },
      { path: "forgot-password", element: <ForgotPasswordPage /> },
      { path: "reset-password/:token?", element: <ResetPasswordPage /> },
    ],
  },
  {
    path: "/b/admin",
    element: null, // This will prevent the React router from handling this route this route is for Django admin panel
  },
  {
    path: "*",
    element: <Navigate to="/user/home" />,
  }
])


ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider>
          <NetworkProvider>
            <RouterProvider router={router} />
          </NetworkProvider>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
