import { Box } from "@chakra-ui/react"

interface StripLabelProps {
  children: React.ReactNode
  right?: boolean
  strong?: boolean
}

export function StripLabel({
  children,
  right = false,
  strong = false,
}: StripLabelProps) {
  return (
    <Box
      flexGrow={1}
      textAlign={right ? "right" : undefined}
      fontWeight={strong ? "700" : undefined}
    >
      {children}
    </Box>
  )
}
