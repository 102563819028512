import { components } from "../../app/api/schema"
import { useListingPaginatedTicketDataQuery } from "../../features/tickets/ticketsApi"
import { ErrorBox } from "../../components/ErrorBox"
import { TicketPanel } from "../../features/tickets/TicketPanel"
import { Box, Divider, Flex, Text, useMediaQuery, VStack } from "@chakra-ui/react"
import { useState, useMemo, useEffect, Fragment } from "react"
import { ListView } from "../../layout/views/ListView"
import { FilterValues, OptionType } from "./ApproveSubmittedTimeEntries"
import { getDateString } from "../../app/utils"
import ResponsivePagination from 'react-responsive-pagination';
import Select from "react-select"
import { useAppSelector } from "../../app/hooks"
import { selectSession } from "../../features/session/sessionSlice"

export type TicketData = components["schemas"]["Paginated_Ticket"]
type DateRange = [Date | null, Date | null];


export function TicketsPage() {

  const session = useAppSelector(selectSession)
  const role = session?.profile?.role
  const [networkError, setNetworkError] = useState<string>("")

  const pageSizeOptions = [10, 20, 30, 40].map((pageSize) => {
    return {
      value: pageSize,
      label: `${pageSize} / page`,
    }
  })

  const initialState = {
    status: [],
    filterByName: "",
    dateRange: [null, null] as DateRange,
    currentPage: 1,
    itemsPerPage: pageSizeOptions[0],
  }

  const [filters, setFilters] = useState<FilterValues>(initialState);
  const [finalFilter, setFinalFilter] = useState<FilterValues>(filters)

  const { status, filterByName, dateRange, currentPage, itemsPerPage } = finalFilter;

  const url = useMemo(() => {
    const params = new URLSearchParams();
    if (filterByName && filterByName?.trim().length > 0) params.append('search', `${filterByName}`);
    if (dateRange[0]) params.append('date_range_after', getDateString(dateRange[0]));
    if (dateRange[1]) params.append('date_range_before', getDateString(dateRange[1]));
    if (currentPage) params.append('page', currentPage.toString());
    if (itemsPerPage) params.append('paginate', itemsPerPage?.value.toString());
    status && status.forEach(status => {
      params.append('status', status.value.toString());
    });
    return params.toString();
  }, [finalFilter]);

  // const { isLoading, error, isError } = useListTicketsQuery()
  const { data, isFetching, error, isError, refetch, isSuccess } = useListingPaginatedTicketDataQuery(url)

  const handlePageChange = (newPage?: number) => {
    setFilters(prev => ({ ...prev, currentPage: newPage || 1 }));
    setFinalFilter(prev => ({ ...prev, currentPage: newPage || 1 }))
  }
  const handlePageSizeChange = (size: OptionType) => {
    setFilters(prev => ({ ...prev, itemsPerPage: size }));
    setFinalFilter(prev => ({ ...prev, itemsPerPage: size }))
  }

  const handleApplyFilter = () => {
    setFinalFilter(filters)
  }
  const handleResetFilter = () => {
    setFilters(initialState)
    setFinalFilter(initialState)
  }
  useEffect(() => {
    if (error && 'status' in error && error.status === 'FETCH_ERROR') {
      setNetworkError("Please try again later. The data you are attempting to view is either unavailable or your network connection is unstable");
      window.scrollTo({ top: 0, behavior: "smooth" })
    } else if (isSuccess && data) {
      // Clear the error if data fetch is successful
      setNetworkError("");
    }
  }, [isError, isSuccess, data])

  useEffect(() => {
    refetch()
  }, [finalFilter])

  const [isSmallScreen] = useMediaQuery('(max-width: 600px)')
  const [isMobileScreen] = useMediaQuery('(max-width: 480px)')

  return (
    <ListView
      title="Tickets"
      filter_label="Filter Tickets"
      isLoading={isFetching}
      filters={filters}
      setFilters={setFilters}
      handleApplyFilter={handleApplyFilter}
      handleResetFilter={handleResetFilter}
    >
      <ErrorBox show={isError ? true : false} message={networkError} />
      {
        !isError && data && data?.tickets?.length > 0 &&
        <Flex w="full" p={2} justifyContent="center" alignItems="center" >
          {
            !isSmallScreen &&
            <Box w="30%">
              <Text fontSize={13} color={"#475f7b"}  >
                {data?.tickets?.length > 0 && `Showing ${data?.from}-${data?.to} of ${data?.total} Tickets`}
              </Text>
            </Box>
          }
          <Box w={isSmallScreen ? "90%" : "70%"} display="flex" justifyContent="flex-end" alignItems="center">
            <Box w={isMobileScreen ? "100%" : "80%"}>
              <ResponsivePagination
                total={data?.totalpage}
                current={currentPage || 1}
                onPageChange={page => handlePageChange(page)}
              />
            </Box>
            {
              !isMobileScreen &&
              <Box w="20%">
                <Select
                  value={itemsPerPage}
                  options={pageSizeOptions}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? '#3182ce' : '#E2E8F0',
                      boxShadow: state.isFocused ? '0 0 0 1px #3182ce' : '',
                      fontSize: "12px",
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "12px"
                    })
                  }}
                  components={{
                    DropdownIndicator: null,
                    IndicatorSeparator: null,
                  }}
                  onChange={(value) => value && handlePageSizeChange(value)}
                />
              </Box>
            }
          </Box>
        </Flex>
      }
      <VStack align="start" w="full" gap={6} p={[0, 1, 4]} justifyContent={"center"} alignItems="center">
        {
          !isError &&
          (data && data?.tickets?.length > 0 ? (
            data?.tickets.map((item: TicketData) => (
              <Fragment key={item.id}>
                <TicketPanel ticket={item} />
                <Divider />
              </Fragment>
            ))
          ) : (
            role === 'customer' && (
              finalFilter?.status?.length === 0 &&
              finalFilter.dateRange[0] === null &&
              finalFilter.dateRange[1] === null
            ) ?
              <>
                <Text>You do not have any tickets requiring a stamp</Text>
              </>
              :
              <>
                <Text>No tickets found</Text>
              </>
          ))
        }
      </VStack>
    </ListView >
  )
}

