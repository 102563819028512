import { Button, ButtonGroup } from "@chakra-ui/react"
import { TimeEntryPanel } from "../../features/timeEntries/TimeEntryPanel"
import { DetailView } from "../../layout/views/DetailView"
import { useNavigate } from "react-router-dom"
import { KnownIcon } from "../../components/widgets/KnownIcon"
import { useListActivitiesQuery } from "../../features/activities/activitiesApi"
import { SubmittedTimeEntries } from "./SubmittedTimeEntries"
import { useState } from "react"
import OverlayLoader from "../../components/OverlayLoader"

export function TrackingPage() {
  const navigate = useNavigate()
  const [formIsSubmitting, setFormIsSubmiting] = useState<boolean>(false)
  const handleGoHome = () => {
    navigate("/user/home")
  }
  useListActivitiesQuery()

  return (
    <DetailView title="Time Tracking" >
      {formIsSubmitting && <OverlayLoader />}
      <TimeEntryPanel setFormIsSubmiting={setFormIsSubmiting} />
      <SubmittedTimeEntries />
      <ButtonGroup w="full" justifyContent="flex-end">
        <Button size={["sm", "sm", "md"]} p={4} colorScheme="blue" onClick={handleGoHome} leftIcon={<KnownIcon name="back" />}>
          Home
        </Button>
      </ButtonGroup>
    </DetailView>
  )
}
