import { Box, Button, FormControl, FormErrorMessage } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas'

interface DigitalSignPadFieldProps {
    name: string
}

export function DigitalSignPadField({ name }: DigitalSignPadFieldProps) {
    const { setValue, watch, setError, clearErrors, formState: { errors, isSubmitting }, } = useFormContext()
    const isValue = watch("digital_sign")
    const [hasSignature, setHasSignature] = useState<boolean>(false);
    const sigCanvasRef = useRef<SignatureCanvas | null>(null);

    useEffect(() => {
        if (isValue) {
            setHasSignature(true);
            sigCanvasRef.current?.fromDataURL(isValue)
        }
    }, [])
    const handleCanvasChange = () => {
        if (sigCanvasRef.current) {
            const isEmpty = sigCanvasRef.current.isEmpty();
            setHasSignature(!isEmpty);
            const canvasDataUrl = isEmpty ? null : sigCanvasRef.current.getCanvas().toDataURL("image/png");
            setValue("digital_sign", canvasDataUrl);
            if (!isEmpty) {
                clearErrors(name);
            }
        }
    };
    const clear = () => {
        sigCanvasRef.current?.clear()
        setValue("digital_sign", null)
        setHasSignature(false)
    }
    useEffect(() => {
        if (!hasSignature && isSubmitting) {
            setError(name, { type: "required", message: "Signature is required" });
        } else if (hasSignature) {
            clearErrors(name);
        }
    }, [hasSignature, isSubmitting, setError, clearErrors, name]);

    return (
        <>
            <FormControl isInvalid={errors[name] !== undefined}
            >
                <Box w="full" position={"relative"} mt={2}>
                    <SignatureCanvas
                        penColor="black"
                        canvasProps={{ className: "sigCanvas" }}
                        ref={sigCanvasRef}
                        onEnd={handleCanvasChange} // Update state when the user finishes drawing
                        onBegin={handleCanvasChange} // Update state when the user starts drawing
                    />
                    {hasSignature && (
                        <Button
                            colorScheme="blue"
                            size='xs'
                            position={"absolute"}
                            top={2}
                            right={2}
                            onClick={() => clear()}
                        >
                            Clear
                        </Button>
                    )}
                </Box>
                <FormErrorMessage>{errors[name]?.message?.toString()}</FormErrorMessage>
            </FormControl>
        </>
    )
}
