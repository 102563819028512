import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react"

import { KnownIcon } from "./widgets/KnownIcon"
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { FilterValues } from "../pages/user/ApproveSubmittedTimeEntries";

interface SearchInputProps {
  setFilterByName: Dispatch<SetStateAction<FilterValues>>;
  value: string | null
  label?: string
}

export function SearchInput({ setFilterByName, value, label }: SearchInputProps) {

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setFilterByName(prev => ({ ...prev, filterByName: value }))
  }

  return (
    <InputGroup>
      <InputLeftElement>
        <KnownIcon name="search" />
      </InputLeftElement>
      <Input
        value={value || ""}
        placeholder={label}
        type="search"
        onChange={(e) => handleChange(e)} />
    </InputGroup>
  )
}
