import { createSlice, createEntityAdapter } from "@reduxjs/toolkit"
import { components } from "../../app/api/schema"
import { servicesApi } from "./servicesApi"
import { RootState } from "../../app/store"

export type ServiceResource = components["schemas"]["Service"]

const servicesAdapter = createEntityAdapter<ServiceResource>({
  selectId: (service) => service.id,
})

const initialState = servicesAdapter.getInitialState({
  status: "idle",
  error: null,
})

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    // Reducer functions will go here if needed
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      servicesApi.endpoints.listServices.matchFulfilled,
      (state, action) => {
        servicesAdapter.setAll(state, action.payload)
      },
    )
    builder.addMatcher(
      servicesApi.endpoints.createService.matchFulfilled,
      (state, action) => {
        servicesAdapter.addOne(state, action.payload)
      },
    )
    builder.addMatcher(
      servicesApi.endpoints.retrieveService.matchFulfilled,
      (state, action) => {
        servicesAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      servicesApi.endpoints.updateService.matchFulfilled,
      (state, action) => {
        servicesAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      servicesApi.endpoints.partialUpdateService.matchFulfilled,
      (state, action) => {
        servicesAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      servicesApi.endpoints.destroyService.matchFulfilled,
      (state, action) => {
        servicesAdapter.removeOne(state, action.meta.arg.originalArgs)
      },
    )
  },
})

export default servicesSlice.reducer

export const {
  selectAll: selectAllServices,
  selectById: selectServiceById,
  selectIds: selectServiceIds,
  selectEntities: selectServiceEntities,
  selectTotal: selectTotalServices,
} = servicesAdapter.getSelectors<RootState>((state) => state.services)
