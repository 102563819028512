import { useAppSelector } from "../../app/hooks"
import { AutocompleteField } from "../../components/forms/fields/AutocompleteField"
import { selectAllServices } from "./servicesSlice"

interface ServiceAutocompleteProps extends FieldProps {}

export function ServiceAutocomplete({
  label,
  name,
  registerOptions,
  helper,
}: ServiceAutocompleteProps) {
  const services = useAppSelector(selectAllServices)
  const suggestions = services.map((service) => ({
    value: service.id,
    text: service.name,
  }))

  return (
    <AutocompleteField
      label={label}
      name={name}
      suggestions={suggestions}
      registerOptions={registerOptions}
      helper={helper}
    />
  )
}
