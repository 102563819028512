import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Textarea,
  Text,
} from "@chakra-ui/react"

import { RegisterOptions, useFormContext } from "react-hook-form"

interface TextareaFieldProps {
  label: string
  name: string
  registerOptions?: RegisterOptions
  helper?: string
}

export function TextareaField({
  label,
  name,
  registerOptions,
  helper,
}: TextareaFieldProps) {
  const { register, watch } = useFormContext()
  const value = watch(name)
  const charLength = value?.length || 0
  const maxLength = 200;

  return (
    <FormControl>
      <FormLabel size="sm" fontWeight={600}>{label}</FormLabel>
      <InputGroup>
        <Textarea
          {...register(name, registerOptions)}
          placeholder="Write it here..."
          maxLength={maxLength}
        />
      </InputGroup>
      <FormHelperText display="flex" justifyContent="space-between">
        {helper}
        <Text display={"inline-block"} color={charLength === maxLength ? "red.500" : "grey.600"}>{charLength}/{maxLength}</Text>
      </FormHelperText>
      <FormErrorMessage>You missed this</FormErrorMessage>
    </FormControl>
  )
}
