import { createSlice, createEntityAdapter } from "@reduxjs/toolkit"

import { components } from "../../app/api/schema"
import { terminalsApi } from "./terminalsApi"
import { RootState } from "../../app/store"

export type TerminalResource = components["schemas"]["Terminal"]

const terminalsAdapter = createEntityAdapter<TerminalResource>({
  selectId: (terminal) => terminal.id,
})

const initialState = terminalsAdapter.getInitialState({
  status: "idle",
  error: null,
})

const terminalsSlice = createSlice({
  name: "terminals",
  initialState,
  reducers: {
    // Reducer functions will go here if needed
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      terminalsApi.endpoints.listTerminals.matchFulfilled,
      (state, action) => {
        terminalsAdapter.setAll(state, action.payload)
      },
    )
    builder.addMatcher(
      terminalsApi.endpoints.createTerminal.matchFulfilled,
      (state, action) => {
        terminalsAdapter.addOne(state, action.payload)
      },
    )
    builder.addMatcher(
      terminalsApi.endpoints.retrieveTerminal.matchFulfilled,
      (state, action) => {
        terminalsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      terminalsApi.endpoints.updateTerminal.matchFulfilled,
      (state, action) => {
        terminalsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      terminalsApi.endpoints.partialUpdateTerminal.matchFulfilled,
      (state, action) => {
        terminalsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      terminalsApi.endpoints.destroyTerminal.matchFulfilled,
      (state, action) => {
        terminalsAdapter.removeOne(state, action.meta.arg.originalArgs)
      },
    )
  },
})

export default terminalsSlice.reducer

export const {
  selectAll: selectAllTerminals,
  selectById: selectTerminalById,
  selectIds: selectTerminalIds,
  selectEntities: selectTerminalEntities,
  selectTotal: selectTotalTerminals,
} = terminalsAdapter.getSelectors<RootState>((state) => state.terminals)

export const selectLocationById = (
  state: RootState,
  terminalId: number,
  id: number,
) =>
  selectTerminalById(state, terminalId)?.locations?.find(
    (location) => location.id === id,
  )
