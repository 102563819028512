import { Box, Divider, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react"
import { useDriverTimeEntryQuery } from "../../features/timeEntries/timeEntriesApi"
import { getDateString } from "../../app/utils"
import { Streak } from "../../components/Streak"
import { ListView } from "../../layout/views/ListView"
import { FilterValues } from "./ApproveSubmittedTimeEntries"
import { useEffect, useMemo, useState } from "react"
import { ErrorBox } from "../../components/ErrorBox"

export type DateRange = [Date | null, Date | null];

export function SubmittedTimeEntries() {

  const initialState: FilterValues = {
    dateRange: [null, null],
  }
  const [networkError, setNetworkError] = useState("")
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [filters, setFilters] = useState<FilterValues>(initialState);
  const [finalFilter, setFinalFilter] = useState<FilterValues>(filters)
  const { dateRange } = finalFilter;

  const url = useMemo(() => {
    const params = new URLSearchParams();
    if (dateRange[0]) params.append('date_range_after', getDateString(dateRange[0]));
    if (dateRange[1]) params.append('date_range_before', getDateString(dateRange[1]));
    return params.toString();
  }, [finalFilter]);

  const { data, isFetching, refetch, isError, error } = useDriverTimeEntryQuery(url)

  const handleApplyFilter = () => {
    setIsFilterApplied(true)
    setFinalFilter(filters)
  }
  const handleResetFilter = () => {
    setIsFilterApplied(false)
    setFilters(initialState)
    setFinalFilter(initialState)
  }

  useEffect(() => {
    refetch()
  }, [finalFilter])

  useEffect(() => {
    if (error && 'status' in error && error.status === 'FETCH_ERROR') {
      setNetworkError("Ticket details are not available without network connectivity");
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [isError])

  return (
    <ListView
      title="My Time"
      filter_label="Filter Time Entries"
      isLoading={isFetching}
      filters={filters}
      setFilters={setFilters}
      handleApplyFilter={handleApplyFilter}
      handleResetFilter={handleResetFilter}
    >
      <ErrorBox show={isError ? true : false} message={networkError} />
      {
        isFilterApplied && dateRange[0] !== null && dateRange[1] !== null && data?.daily_summary.length > 0 &&
        <Box>
          <Heading as="h4" size={["sm", "md"]}>
            Total Hours : {`${data?.grand_total_hours}`}
          </Heading>
        </Box>
      }

      <VStack gap={4} align="start" w="full" justifyContent={"center"} alignItems="center">
        {
          !isError &&
            data && data?.daily_summary.length > 0 ?
            data?.daily_summary?.map((item: any, index: number) => (
              <Box w="full" key={index}>
                <Heading as="h4" size={["sm", "md"]}>
                  {getDateString(item.date)} <br />
                  <Text as="span" fontSize="0.8em">
                    {" "}
                    {item.total_hours}
                  </Text>
                </Heading>
                <Box marginY={5}>
                  {item.tasks.map((item: any, index: number) => (
                    <SimpleGrid key={index} w="full" columns={3} spacing={2} fontSize="1em">
                      <Streak label="Task " size="large">
                        <Text fontSize="0.8em">
                          {item.Task}
                        </Text>{" "}
                      </Streak>
                      <Streak label="Company " size="large">
                        <Text fontSize="0.8em">{item.Customer}</Text>{" "}
                      </Streak>
                      <Streak label="Time " size="large">
                        <Text fontSize="0.8em">{item.Time}</Text>{" "}
                      </Streak>
                    </SimpleGrid>
                  ))}
                </Box>
                <Divider />
              </Box>
            )) :
            <Text>
              No time entries found
            </Text>
        }
      </VStack>
    </ListView >
  )
}
