import { IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useMediaQuery } from "@chakra-ui/react"
import { components } from "../../app/api/schema"
import { KnownIcon } from "../../components/widgets/KnownIcon"
import { ModalForm } from "../../components/forms/ModalForm"
import { useState } from "react"
import { ChargesForm } from "../charges/ChargesForm"
import { useNetworkCheck } from "../../app/hooks"

type TicketResource = components["schemas"]["Ticket"]

export interface VolumeTableProps {
  ticket?: TicketResource
  onSave(data: any): void
}

export function VolumeTable({ ticket, onSave }: VolumeTableProps) {
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
  const modalSize = isLargerThanMobile ? "lg" : "full"
  const [defaultValues, setDefaultValues] = useState(null)
  const isInCharges = () => {
    return ticket?.chargedetails.some((item) => item.description === "Actual Volume")
  }

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure()
  const { isOnline } = useNetworkCheck()
  return (
    <>
      {
        ticket?.actual_volume ? (
          <div style={{ maxWidth: "calc(100vw - 90px)", width: "100%", overflowX: "auto" }}>
            <Table size={["sm"]}>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>Cubic Meters</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr h="50px">
                  <Td>Actual</Td>
                  <Td>{ticket?.actual_volume}</Td>
                  <Td>{
                    !isInCharges()
                      ?
                      <IconButton
                        aria-label="create-charge"
                        icon={<KnownIcon name="dollar" />}
                        size={["xs", "sm", "md"]}
                        onClick={() => {
                          openModal()
                          const data = {
                            description: "Actual Volume",
                            unit: "Cubic Meters",
                            quantity: ticket?.actual_volume,
                            allow_surcharge: true,
                          }
                          setDefaultValues(data as any)
                        }}
                        isDisabled={!isOnline ? true : false}
                      />
                      :
                      null
                  }
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </div>
        )
          :
          (
            <Text>{"Volume charges will be added by the Driver Lead"}</Text>
          )
      }

      {/* Dialog for adding new charge entry */}
      <ModalForm
        title="Add New Entry"
        isOpen={isModalOpen}
        size={modalSize}
        onClose={closeModal}
        onCancel={closeModal}
        onSave={onSave}
        defaultValues={defaultValues}
      >
        <ChargesForm />
      </ModalForm>
    </>
  )
}
