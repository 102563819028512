import { BooleanField } from "../../components/forms/fields/BooleanField"
import { InputField } from "../../components/forms/fields/InputField"
import { NumberField } from "../../components/forms/fields/NumberField"
import { ProductAutocomplete } from "../products/ProductAutocomplete"

interface ProductsFormProps {
}

export function ProductsForm({ }: ProductsFormProps) {
  return (
    <>
      <ProductAutocomplete
        label="Shipping Name"
        name="product"
        helper="Select the name of the product being shipped"
        registerOptions={{ required: "Plese Enter Product Name" }}
      />
      <InputField label="Subsidiary Class" name="subsidiary_class" />
      <BooleanField label="Toxic by Inhalation" name="toxic_by_inhalation" />
      <NumberField
        label="Quantity"
        name="quantity"
        helper="The quantity or volume of product being shipped (numeric value only)"
        registerOptions={{ required: "Plese Enter Quantity" }}
      />
      <NumberField
        label="Packages"
        name="packages"
        helper="Number of packages requiring a TDG label"
      />
    </>
  )
}
