import { Icon } from "@chakra-ui/react"
import {
  FaCircleInfo,
  FaCircleExclamation,
  FaCircleXmark,
  FaArrowUpRightFromSquare,
  FaRegClock,
  FaCube,
  FaRegPaperPlane,
  FaPhone,
  FaPlus,
  FaMinus,
  FaDollarSign,
  FaPercent

} from "react-icons/fa6"
import { FaSearch, FaRegTrashAlt, FaRegCalendar, FaUpload, FaEye } from "react-icons/fa"
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { CiFilter } from "react-icons/ci";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { IoSettingsSharp } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";



// Mapping object
const iconMappings = {
  info: FaCircleInfo,
  error: FaCircleXmark,
  warn: FaCircleExclamation,
  link: FaArrowUpRightFromSquare,
  clock: FaRegClock,
  cube: FaCube,
  search: FaSearch,
  plane: FaRegPaperPlane,
  trash: FaRegTrashAlt,
  calendar: FaRegCalendar,
  phone: FaPhone,
  plus: FaPlus,
  minus: FaMinus,
  dollar: FaDollarSign,
  dots: HiOutlineDotsHorizontal,
  percentage: FaPercent,
  upload: FaUpload,
  cancel: MdCancel,
  preview: FaEye,
  filter: CiFilter,
  attantion: AiOutlineExclamationCircle,
  roundDollar: AiOutlineDollarCircle,
  settings: IoSettingsSharp,
  back: IoIosArrowBack
}

export type KnownIconName = keyof typeof iconMappings

interface KnownIconProps {
  name: KnownIconName
  size?: number
  color?: string
}

export function KnownIcon({ name, size, color }: KnownIconProps) {
  const component = getIconComponent(name)
  return <Icon as={component} boxSize={size} color={color} />
}

export function getIconComponent(name: KnownIconName) {
  const component = iconMappings[name]
  if (!component) {
    return null
  }
  return component
}


