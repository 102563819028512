import { Box } from "@chakra-ui/react"
import React from "react"

interface StreakProps {
  label?: string
  size: "small" | "medium" | "large"
  children: React.ReactNode | React.ReactNode[]
}

export function Streak({ label, size, children }: StreakProps) {
  const getSize = () => {
    switch (size) {
      case "small":
        return "22%"
      case "medium":
        return "44%"
      case "large":
        return "100%"
      default:
        return "50%"
    }
  }

  return (
    <Box
      display="inline-flex"
      flexWrap="wrap"
      columnGap={2}
      width={size === "large" ? "100%" : getSize()}
      alignItems="center"
      wordBreak="break-word"
      whiteSpace="wrap"
    >
      {label && (
        <Box fontWeight="bold" display="inline" fontSize={["0.8em", "1em"]}>
          {label}
        </Box>
      )
      }
      <Box display="inline-block">{children}</Box>
    </Box >
  )
}
