import { createSlice, createEntityAdapter } from "@reduxjs/toolkit"

import { components } from "../../app/api/schema"
import { RootState } from "../../app/store"
import { locationsApi } from "./locationsApi"

export type LocationResource = components["schemas"]["Location"]

const locationsAdapter = createEntityAdapter<LocationResource>({
  selectId: (location) => location.id,
})

const initialState = locationsAdapter.getInitialState({
  status: "idle",
  error: null,
})

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    // Reducer functions will go here if needed
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      locationsApi.endpoints.listLocations.matchFulfilled,
      (state, action) => {
        locationsAdapter.setAll(state, action.payload)
      },
    )
    builder.addMatcher(
      locationsApi.endpoints.createLocation.matchFulfilled,
      (state, action) => {
        locationsAdapter.addOne(state, action.payload)
      },
    )
    builder.addMatcher(
      locationsApi.endpoints.retrieveLocation.matchFulfilled,
      (state, action) => {
        locationsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      locationsApi.endpoints.updateLocation.matchFulfilled,
      (state, action) => {
        locationsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      locationsApi.endpoints.partialUpdateLocation.matchFulfilled,
      (state, action) => {
        locationsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      locationsApi.endpoints.destroyLocation.matchFulfilled,
      (state, action) => {
        locationsAdapter.removeOne(state, action.meta.arg.originalArgs)
      },
    )
  },
})

export default locationsSlice.reducer

export const {
  selectAll: selectAllLocations,
  selectById: selectLocationById,
  selectIds: selectLocationIds,
  selectEntities: selectLocationEntities,
  selectTotal: selectTotalLocations,
} = locationsAdapter.getSelectors<RootState>((state) => state.locations)
