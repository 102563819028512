import { useTrait } from "../../app/hooks"
import { TraitLine } from "../../components/TraitLine"
import { selectTerminalById } from "./terminalsSlice"

interface TerminalTraitProps extends TraitProps { }

export function TerminalTrait({ id }: TerminalTraitProps) {
  const { trait, isNew } = useTrait(
    (terminal) => terminal.name,
    id,
    selectTerminalById,
  )

  return <TraitLine isNew={isNew}>{trait}</TraitLine>
}
