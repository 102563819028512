import { Box, Heading, VStack } from "@chakra-ui/react"

interface StreamProps {
  title?: string
  children: React.ReactNode | React.ReactNode[]
}

export function Stream({ title, children }: StreamProps) {
  return (
    <Box w="full">
      <VStack align="left" gap={7} w="full">
        {
          title &&
          <Heading as="h3" size="md">
            {title}
          </Heading>
        }
        {children}
      </VStack>
    </Box>
  )
}
