import { WrapItem } from "@chakra-ui/react"
import { ReadonlyField } from "../../components/forms/fields/ReadonlyField"
import { ProductTrait } from "../products/ProductTrait"
import { DraftProductsValues } from "./draftSlice"

interface ProductsDisplayProps {
  entry?: DraftProductsValues
}

export function ProductsDisplay({ entry }: ProductsDisplayProps) {
  return (
    <>
      <WrapItem flexGrow={1}>
        <ReadonlyField label="Shipping Name">
          <ProductTrait id={entry?.product} />
        </ReadonlyField>
      </WrapItem>
      <WrapItem>
        <ReadonlyField label="Subsidiary Class">
          {entry?.subsidiary_class || "-"}
        </ReadonlyField>
      </WrapItem>
      <WrapItem>
        <ReadonlyField label="Toxic">
          {(entry?.toxic_by_inhalation && "Yes") || "No"}
        </ReadonlyField>
      </WrapItem>
      <WrapItem>
        <ReadonlyField label="Quantity">{entry?.quantity || "-"}</ReadonlyField>
      </WrapItem>
      <WrapItem>
        <ReadonlyField label="Packages">{entry?.packages || "-"}</ReadonlyField>
      </WrapItem>
    </>
  )
}
