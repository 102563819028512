import { ReadonlyField } from "../../components/forms/fields/ReadonlyField"
import { InputStreak } from "../../components/forms/InputStreak"
import { CustomerTrait } from "../customers/CustomerTrait"
import { EquipmentTrait } from "../equipments/EquipmentTrait"
import { Fragment } from "react"
import { formatDateOnly } from "../../app/utils"
import { ProductTrait } from "../products/ProductTrait"

interface BasicDisplayProps {
  draftBasic: DraftBasicValues | null
}

export function BasicDisplay({ draftBasic }: BasicDisplayProps) {

  return (
    <>
      <ReadonlyField
        label="Date"
        helper="Enter the date for this ticket"
        isRequired={true}
      >
        {formatDateOnly(draftBasic?.reference_date) || "--"}
      </ReadonlyField>
      <InputStreak
        label="Consignor Contact"
        helper="Enter emergency name and phone"
      >
        <ReadonlyField helper="Name">
          {draftBasic?.consignor_contact || "--"}
        </ReadonlyField>
        <ReadonlyField helper="Phone number">
          {draftBasic?.consignor_phone || "--"}
        </ReadonlyField>
      </InputStreak>

      <InputStreak label="Billing Email Address" >
        <ReadonlyField helper="Billing email address">
          {draftBasic?.billing_email || "--"}
        </ReadonlyField>
      </InputStreak>

      <InputStreak label="Emergency Contact" helper="Enter Emergency contact">
        <ReadonlyField helper="Emergency contact">
          {draftBasic?.emergency_contact || "--"}
        </ReadonlyField>
      </InputStreak>

      <ReadonlyField
        label="Customer"
        helper="Pick the customer that will be invoiced"
        isRequired={true}
      >
        {draftBasic?.customer ? (
          <CustomerTrait id={draftBasic.customer} />
        ) : (
          "--"
        )}
      </ReadonlyField>
      <ReadonlyField label="Equipment" helper="">
        {draftBasic?.equipments?.length
          ? draftBasic.equipments.map((equipment, index) => (
            <Fragment key={index}>
              <EquipmentTrait id={equipment} />
              <br />
            </Fragment>
          ))
          : "--"}
      </ReadonlyField>
      {/* <ReadonlyField label="Confirmed Empty">
        {(draftBasic?.confirmed_empty && "Yes") || "No"}
      </ReadonlyField>
      <ReadonlyField label="Residue Last Contained">
        {draftBasic?.residue_last_contained ? (
          <ProductTrait id={draftBasic?.residue_last_contained} />
        ) : (
          "--"
        )}
      </ReadonlyField> */}
    </>
  )
}
