import { createSlice, createEntityAdapter } from "@reduxjs/toolkit"

import { components } from "../../app/api/schema"
import { timeEntriesApi } from "./timeEntriesApi"
import { RootState } from "../../app/store"

type TimeEntryResource = components["schemas"]["TimeEntry"]

const timeEntriesAdapter = createEntityAdapter<TimeEntryResource>({
  selectId: (timeEntry) => timeEntry.id,
})

const initialState = timeEntriesAdapter.getInitialState({
  status: "idle",
  error: null,
})

const timeEntriesSlice = createSlice({
  name: "timeEntries",
  initialState,
  reducers: {
    // Reducer functions will go here if needed
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      timeEntriesApi.endpoints.listTimeEntries.matchFulfilled,
      (state, action) => {
        timeEntriesAdapter.setAll(state, action.payload)
      },
    )
    builder.addMatcher(
      timeEntriesApi.endpoints.createTimeEntry.matchFulfilled,
      (state, action) => {
        timeEntriesAdapter.addOne(state, action.payload)
      },
    )
    builder.addMatcher(
      timeEntriesApi.endpoints.retrieveTimeEntry.matchFulfilled,
      (state, action) => {
        timeEntriesAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      timeEntriesApi.endpoints.updateTimeEntry.matchFulfilled,
      (state, action) => {
        timeEntriesAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      timeEntriesApi.endpoints.partialUpdateTimeEntry.matchFulfilled,
      (state, action) => {
        timeEntriesAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      timeEntriesApi.endpoints.destroyTimeEntry.matchFulfilled,
      (state, action) => {
        timeEntriesAdapter.removeOne(state, action.meta.arg.originalArgs)
      },
    )
  },
})

export default timeEntriesSlice.reducer

export const {
  selectAll: selectAllTimeEntries,
  selectById: selectTimeEntryById,
  selectIds: selectTimeEntryIds,
  selectEntities: selectTimeEntryEntities,
  selectTotal: selectTotalTimeEntries,
} = timeEntriesAdapter.getSelectors<RootState>((state) => state.timeEntries)
