import { useState } from "react"
import {
  Button,
  ButtonGroup,
  Modal,
  ModalProps,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"

type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

interface DateModalProps extends ModalProps {
  title: string
  onPick(value: Value): void
  onCancel(): void
  initialDate?: Value // Accept initialDate as a prop
}

export function DateModal({
  title,
  onClose,
  onCancel,
  onPick,
  initialDate,
  ...modalProps
}: DateModalProps) {
  const [selectedDate, setSelectedDate] = useState<Value>(new Date())
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
  const modalSize = isLargerThanMobile ? "md" : "sm"

  const handleCancel = () => {
    onClose()
    onCancel()
  }

  const handleSave = () => {
    onClose()
    onPick(selectedDate)
  }

  const handleChange = (value: Value) => {
    setSelectedDate(value)
  }

  return (
    <Modal onClose={handleCancel} size={modalSize} {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack w="full" gap={6}>
            <Calendar onChange={handleChange} defaultValue={initialDate} />
          </VStack>
        </ModalBody>
        <ButtonGroup w="full" p={3} flex="row" justifyContent="flex-end">
          <Button onClick={handleSave} colorScheme="blue">
            Select
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </ButtonGroup>
      </ModalContent>
    </Modal>
  )
}
