import { Box } from "@chakra-ui/react"
import { FormCollectionPanel } from "../../components/forms/FormCollectionPanel"
import { FormPanelControls } from "../../components/forms/FormPanelControls"
import { FormPanelDisplay } from "../../components/forms/FormPanelDisplay"
import { ProductsDisplay } from "./ProductsDisplay"
import { ProductsForm } from "./ProductsForm"

interface ProductsPanelProps {
  defaultValues?: any,
  onSave(data: any): void
  onRemove(index: number): void
  isLoading?: boolean
}

export function ProductsPanel({ defaultValues, onSave, onRemove, isLoading }: ProductsPanelProps) {

  return (
    <FormCollectionPanel
      title="Label Information"
      subtitle="Details specific to TDG requirements"
      entries={defaultValues}
      onSave={onSave}
      onRemove={onRemove}
      isLoading={isLoading}
    >
      <FormPanelDisplay>
        <ProductsDisplay />
      </FormPanelDisplay>
      <FormPanelControls>
        <ProductsForm />
      </FormPanelControls>
    </FormCollectionPanel>
  )
}
