import { capFirstLetter, formatDateOnly } from "../../app/utils"
import { Streak } from "../../components/Streak"
import { Strip } from "../../components/Strip"
import { StripLine } from "../../components/StripLine"
import { TicketResource } from "../../pages/user/TicketDetailPage"

export interface TicketBasicDetailsProps {
  ticket?: TicketResource
}

export function TicketBasicDetails({ ticket }: TicketBasicDetailsProps) {
  if (ticket === undefined) return null
  return (
    <Strip>
      <StripLine wrap>
        <Streak label="Status:" size="medium">
          {capFirstLetter(ticket?.status) || "--"}
        </Streak>
        <Streak label="Date:" size="medium">
          {formatDateOnly(ticket?.reference_date) || "--"}
        </Streak>
        <Streak label="Emergency Contact:" size="large">
          {ticket?.emergency_contact || "--"}
        </Streak>
        <Streak label="Consignor Contact:" size="large">
          {ticket.consignor_contact ? `${ticket.consignor_contact} (${ticket.consignor_phone})` : ticket.consignor_phone || "--"}
        </Streak>
        <Streak label="Billing Email Address:" size="large">
          {ticket.billing_email || "--"}
        </Streak>
        <Streak label="Customer:" size="large">
          {ticket?.customer.name || "--"}
        </Streak>
        {/* <Streak label="Confirmed Empty:" size="large">
          {ticket?.confirmed_empty ? "Yes" : "No"}
        </Streak>
        <Streak label="Residue Last Contained:" size="large">
          {ticket?.residue_last_contained?.name || "--"}
        </Streak> */}
        <Streak label="Equipment:" size="large">
          {ticket?.equipments.length ? ticket?.equipments.map((equipment, index) => (
            <span key={index}>
              {equipment.unit} ({equipment?.category?.name}){" "}
            </span>
          )) : "--"}
        </Streak>
      </StripLine>
    </Strip>
  )
}
