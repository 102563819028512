import { createContext, ReactNode, useCallback, useEffect, useState, Context } from "react"


interface NetworkContextProps {
    isOnline: boolean;
}

export const NetworkContext: Context<NetworkContextProps | undefined> = createContext<NetworkContextProps | undefined>(undefined);

interface NetwrokProviderProps {
    children: ReactNode
}

export const NetworkProvider = ({ children }: NetwrokProviderProps) => {
    const [isOnline, setOnline] = useState<boolean>((): boolean => {
        return navigator.onLine
    })
    const setOnlineToTrue = useCallback(() => {
        setOnline(true)
    }, [])
    const setOnlineToFalse = useCallback(() => {
        setOnline(false)
    }, [])

    useEffect(() => {
        window.addEventListener('online', setOnlineToTrue)
        window.addEventListener('offline', setOnlineToFalse)
        return () => {
            window.removeEventListener('online', setOnlineToTrue)
            window.removeEventListener('offline', setOnlineToFalse)
        }
    }, [setOnlineToTrue, setOnlineToFalse])


    return <NetworkContext.Provider value={{ isOnline }}>
        {children}
    </NetworkContext.Provider>
}
