import { createSlice, createEntityAdapter } from "@reduxjs/toolkit"
import { components } from "../../app/api/schema"
import { customersApi } from "./customersApi"
import { RootState } from "../../app/store"

type CustomerResource = components["schemas"]["Customer"]

const customersAdapter = createEntityAdapter<CustomerResource>({
  selectId: (customer) => customer.id,
})

const initialState = customersAdapter.getInitialState({
  status: "idle",
  error: null,
})

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    // Reducer functions will go here if needed
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      customersApi.endpoints.listCustomers.matchFulfilled,
      (state, action) => {
        customersAdapter.setAll(state, action.payload)
      },
    )
    builder.addMatcher(
      customersApi.endpoints.createCustomer.matchFulfilled,
      (state, action) => {
        customersAdapter.addOne(state, action.payload)
      },
    )
    builder.addMatcher(
      customersApi.endpoints.retrieveCustomer.matchFulfilled,
      (state, action) => {
        customersAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      customersApi.endpoints.updateCustomer.matchFulfilled,
      (state, action) => {
        customersAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      customersApi.endpoints.partialUpdateCustomer.matchFulfilled,
      (state, action) => {
        customersAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      customersApi.endpoints.destroyCustomer.matchFulfilled,
      (state, action) => {
        customersAdapter.removeOne(state, action.meta.arg.originalArgs)
      },
    )
  },
})

export default customersSlice.reducer

export const {
  selectAll: selectAllCustomers,
  selectById: selectCustomerById,
  selectIds: selectCustomerIds,
  selectEntities: selectCustomerEntities,
  selectTotal: selectTotalCustomers,
} = customersAdapter.getSelectors<RootState>((state) => state.customers)
