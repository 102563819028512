import { format } from "date-fns"
import { Link } from "react-router-dom"
import { VStack } from "@chakra-ui/react"
import { StripLine } from "../../components/StripLine"
import { Strip } from "../../components/Strip"
import { Streak } from "../../components/Streak"
import { Stream } from "../../components/Stream"
import { StripLabel } from "../../components/StripLabel"
import { StripIcon } from "../../components/StripIcon"
import { useAppSelector } from "../../app/hooks"
import { selectSession } from "../session/sessionSlice"
import { useNetworkCheck } from "../../app/hooks"
import { TicketData } from "../../pages/user/TicketsPage"

export interface TicketPanelProps {
  ticket: TicketData
}

export function TicketPanel({ ticket }: TicketPanelProps) {
  //get session and current user role
  const session = useAppSelector(selectSession)
  const role = session?.profile.role
  const { isOnline } = useNetworkCheck()

  function stringifyDate(date: any) {
    return date && format(date, "MM/dd/yyyy")
  }

  return (
    <Stream>
      <VStack align="start" w="full" gap={6}>
        <Strip condensed>
          <StripLine>
            <StripLabel strong># {ticket?.number}</StripLabel>
            <StripLabel right>
              <Link to={role === 'customer' ?
                `/user/stamp/tickets/${ticket?.id}`
                : `/user/tickets/${ticket?.id}`}
                style={{
                  pointerEvents: isOnline ? "auto" : "none"
                }}
              > View Details
              </Link>
            </StripLabel>
            <StripIcon name="link"
            // color="#3182ce"
            />
            {
              role === "driver-lead" && ticket?.driver_lead_add_charges &&
              <StripIcon name="roundDollar" size={5} color="red" />
            }
          </StripLine>

          <StripLine wrap>
            <Streak label="Status" size="small">
              {ticket.status.toUpperCase()}
            </Streak>
            <Streak label="Date" size="small">
              {stringifyDate(ticket.reference_date)}
            </Streak>
            <Streak label="Customer" size="medium">
              {ticket?.customer}
            </Streak>
            <Streak label="From " size="medium">
              {ticket?.consignor_name}
            </Streak>
            <Streak label="To " size="medium">
              {ticket?.consignee_name}
            </Streak>
          </StripLine>
        </Strip>
      </VStack>
    </Stream>
  )
}
