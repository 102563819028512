import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"

interface ErrorBoxProps {
  message?: string | string[]
  error?: any
  show: boolean
}

export function ErrorBox({
  show,
  message,
  error,
}: ErrorBoxProps) {
  return show ? (
    <Alert status="error" rounded="md">
      <AlertIcon />
      <AlertTitle>

        {!Array.isArray(message) ? message :
          <UnorderedList >
            {message.map((item) => (
              <ListItem>{item}</ListItem>
            ))}
          </UnorderedList>
        }
      </AlertTitle>
      <AlertDescription>{error?.data?.detail}</AlertDescription>
    </Alert>
  ) : null
}
