import { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import { Box, Flex, VStack, Spinner } from "@chakra-ui/react"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  loadSession,
  selectIsLoggedIn,
  selectSession,
} from "../../features/session/sessionSlice"

export function PrintArea() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const session = useAppSelector(selectSession)

  useEffect(() => {
    if (!isLoggedIn) {
      if (!dispatch(loadSession())) {
        navigate("/auth/login")
      }
    }
  }, [isLoggedIn, dispatch, navigate])

  if (!isLoggedIn) {
    return <Spinner />
  }

  return (
    <Flex w="full" justifyContent="center">
      <Box w="full" margin={10}>
        <VStack align="start">
          <Outlet />
        </VStack>
      </Box>
    </Flex>
  )
}
