import { useEffect, useMemo } from "react"
import { Link, Outlet } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { Image, Icon } from "@chakra-ui/react"
import { FiLogOut } from "react-icons/fi"
import logo from "/images/logo.png"
import {
  Box,
  Flex,
  HStack,
  VStack,
  Spacer,
  Avatar,
} from "@chakra-ui/react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  loadSession,
  selectIsLoggedIn,
  selectSession,
} from "../../features/session/sessionSlice"
import { discardAllDrafts } from "../../features/draft/draftSlice"
import { clearAllLocalEntries } from "../../features/timeEntries/localEntriesSlice"
import { useNetworkCheck } from "../../app/hooks"
import { useCleanAttachmentMutation } from "../../features/aws/awsApi"
import { PuffLoader } from "react-spinners"

export function UserArea() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const session = useAppSelector(selectSession)
  const role = session?.profile.role
  const { isOnline } = useNetworkCheck()
  const drafts = useAppSelector((state) => state.drafts.drafts)
  const [cleanAttachment] = useCleanAttachmentMutation()

  useEffect(() => {
    if (!isLoggedIn) {
      if (!dispatch(loadSession())) {
        navigate("/auth/login")
      }
    }
  }, [isLoggedIn, dispatch, navigate])

  const draftWithAttachments = useMemo(() => {
    if (!drafts || drafts.length === 0) {
      return [];
    }
    return drafts.filter((item) => item.attachments && item.attachments.length > 0 && item.number).map((item) => `${item.number}`)
  }, [drafts])

  const handleLogout = () => {
    if (draftWithAttachments.length > 0) cleanAttachment({ draft_ids: draftWithAttachments })
    dispatch(discardAllDrafts())
    dispatch(clearAllLocalEntries())
    navigate("/auth/logout")
  }
  if (!isLoggedIn) {
    return (
      <Flex w="full" h="full" justifyContent="center" alignItems="center">
        <PuffLoader
          color="#3D82CE"
          size={80}
        />
      </Flex>
    )
  }
  return (
    <Flex bg="blue.100" w="full" justifyContent="center" minH="100vh">
      <Box w="full" bg="white" maxW={850} display="flex" flexDirection="column">
        <HStack
          w="full"
          bg={isOnline ? "blue.500" : "red.500"}
          color="white"
          textAlign="right"
          fontSize="sm"
          fontWeight="600"
          py={2}
          px={6}
          alignItems="center"
        >
          <Box>
            <Link to={role !== "customer" ? "/user/home" : "/user/tickets"}>
              <Image src={logo} width={20} />
            </Link>
          </Box>
          <Spacer />
          <Box>{!isOnline && <p>YOU ARE OFFLINE</p>}</Box>
          <Spacer />
          <Link to="/user/profile">
            <HStack>
              {session?.profile.user.first_name ? (
                <Box>
                  {session?.profile.user.first_name} {session?.profile.user.last_name}
                </Box>
              ) : (
                <Box>{session?.profile.user.username}</Box>
              )}
              <Avatar bg="blue.100" fontSize="xs" boxSize={8} />
            </HStack>
          </Link>

          <button onClick={handleLogout}>
            <Icon as={FiLogOut} boxSize={5} mt={1} />
          </button>
        </HStack>
        <Box w="full" flex="1" p={[2, 2, 4, 5, 6]} mb={6}>
          <VStack align="start" h="full">
            <Outlet />
          </VStack>
        </Box>
      </Box>
    </Flex>

  )
}
