import { FocusView } from "../../layout/views/FocusView"
import { SessionLogout } from "../../features/session/SessionLogout"

export function LogoutPage() {
  return (
    <FocusView>
      <SessionLogout />
    </FocusView>
  )
}
