import {
  Button,
  ButtonGroup,
  Modal,
  ModalProps,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react"
import { Form } from "./Form"
import { SubmitButton } from "./SubmitButton"
import { useNetworkCheck } from "../../app/hooks"

interface ModalFormProps extends ModalProps {
  title: string
  defaultValues?: any
  onCancel(): void
  onSave(data: any): void
  isSubmitToTimeSheet?: boolean
}

export function ModalForm({
  title,
  defaultValues,
  onClose,
  onCancel,
  onSave,
  children,
  isSubmitToTimeSheet,
  ...modalProps
}: ModalFormProps) {
  const { isOnline } = useNetworkCheck()
  const handleCancel = () => {
    console.log("Cancelled modal")
    onClose()
    onCancel()
  }

  const handleSave = (data: any) => {
    console.log("Saved modal", data)
    onSave(data)
    onClose()
  }

  return (
    <Modal onClose={handleCancel} {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <Form defaultValues={defaultValues}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack w="full" gap={6}>
              {children}
            </VStack>
          </ModalBody>
          <ModalFooter alignContent="end">
            <ButtonGroup>
              <SubmitButton onSubmit={handleSave}>{isSubmitToTimeSheet && isOnline ? 'Save to Timesheet' : 'Save'}</SubmitButton>
              <Button onClick={handleCancel}>Cancel</Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}
