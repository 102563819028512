import { useAppSelector } from "../../app/hooks"
import {
  AutocompleteField,
  AutocompleteSuggestion,
} from "../../components/forms/fields/AutocompleteField"
import { selectTerminalById } from "./terminalsSlice"

interface LocationAutocompleteProps extends FieldProps {
  terminalId?: ExistingOrNew
}

export function LocationAutocomplete({
  label,
  name,
  terminalId,
  helper,
  registerOptions
}: LocationAutocompleteProps) {
  const terminal = useAppSelector((state) =>
    selectTerminalById(state, terminalId || 0),
  )
  const locations = terminal?.locations || []
  const suggestions = locations.map((location) => ({
    value: location.id,
    text: location.name || "unknown",
  }))

  const handleMatch = (suggestion: AutocompleteSuggestion) => {
    console.log("Selected", suggestion)
  }

  return (
    <AutocompleteField
      label={label}
      name={name}
      suggestions={suggestions}
      onMatch={handleMatch}
      helper={helper}
      registerOptions={registerOptions}
    />
  )
}
