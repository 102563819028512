import { Box, VStack } from "@chakra-ui/react"

interface ViewStreamProps {
  children: React.ReactNode
}

export function ViewStream({ children }: ViewStreamProps) {
  return (
    <Box w="full" h="full">
      <VStack gap={4} h="full" p={[2, 3, 4, 5, 6]} alignItems={"flex-start"} >
        {children}
      </VStack>
    </Box>
  )
}
