import { useTrait } from "../../app/hooks"
import { TraitLine } from "../../components/TraitLine"
import { selectServiceById } from "./servicesSlice"

interface ServiceTraitProps extends TraitProps {}

export function ServiceTrait({ id }: ServiceTraitProps) {
  const { trait, isNew } = useTrait(
    (service) => service.name,
    id,
    selectServiceById,
  )

  return <TraitLine isNew={isNew}>{trait}</TraitLine>
}
