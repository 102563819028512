import { useEffect, useState } from "react"
import ActivitySelect from "./ActivitySelect"
import DraftSelect from "./DraftSelect"
import { useFormContext } from "react-hook-form"
import "react-datetime/css/react-datetime.css";
import { DatetimeField } from "../../components/forms/fields/DatetimeField"
import { parse } from "date-fns"
import { TextareaField } from "../../components/forms/fields/TextareaField"
import { useAppSelector, useNetworkCheck } from "../../app/hooks";
import { selectActivityById } from "../activities/activitySlice";

interface TimeEntryFormProps {
  setIsSubmitToTimeSheet?(arg: boolean): void
}

export function TimeEntryForm({
  setIsSubmitToTimeSheet
}: TimeEntryFormProps) {
  const [startTime, setStartTime] = useState("");
  const [finishTime, setFinishTime] = useState("");
  const [finishTimeError, setFinishTimeError] = useState("");

  const { isOnline } = useNetworkCheck();
  const { watch, setValue } = useFormContext();

  const selectedActivity = useAppSelector(state => selectActivityById(state, watch("activity")));

  const validateTime = (start: string, finish: string) => {
    const starting = parse(start, 'MM/dd/yyyy hh:mm a', new Date());
    const ending = parse(finish, 'MM/dd/yyyy hh:mm a', new Date());
    if (starting > ending) {
      setFinishTimeError(
        "The finish time cannot be earlier than the start time.",
      )
    } else {
      setFinishTimeError("")
    }
  }

  const handleStartTimeChange = (value: string) => {
    setStartTime(value)
    validateTime(value, finishTime)
  }

  const handleFinishTimeChange = (value: string) => {
    setFinishTime(value)
    validateTime(startTime, value)
  }

  useEffect(() => {
    if (selectedActivity?.description_field) {
      setValue("ticketDraft", null);
    }
  }, [selectedActivity, setValue]);

  useEffect(() => {
    setIsSubmitToTimeSheet?.(!watch("ticketDraft"));
  }, [watch("ticketDraft"), setIsSubmitToTimeSheet]);

  return (
    <>
      <ActivitySelect label="Activity" name="activity" isRequired={true} />
      {!selectedActivity?.description_field && (
        <DraftSelect label="Select draft" name="ticketDraft" isRequired={!isOnline} />
      )}
      <DatetimeField label="Start" name="start" onChange={handleStartTimeChange} isRequired={true} />
      <DatetimeField label="Finish" name="finish" onChange={handleFinishTimeChange} error={finishTimeError} isRequired={true} />
      {selectedActivity?.description_field && (
        <TextareaField label="Description" name="description" />
      )}
    </>
  )
}