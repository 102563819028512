import { ConsignorForm } from '../terminals/ConsignorForm'
import { ConsigneeForm } from '../terminals/ConsigneeForm'
import { Divider } from '@chakra-ui/react'

function LocationsForm() {
    return (
        <>
            <ConsignorForm title="Consignor / From" />
            <Divider orientation='horizontal' borderColor='blue.200' />
            <ConsigneeForm title="Consignee / To" />
        </>
    )
}

export default LocationsForm
