import { Button } from "@chakra-ui/react"
import { useFormContext } from "react-hook-form"

interface SubmitButtonProps {
  children: React.ReactNode | React.ReactNode[]
  onSubmit(data: any): void
}

export function SubmitButton({ children, onSubmit }: SubmitButtonProps) {
  const { handleSubmit: createSubmitHandler } = useFormContext()

  return (
    <Button colorScheme="blue" onClick={createSubmitHandler(onSubmit)}>
      {children}
    </Button>
  )
}
