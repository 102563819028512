import { Box, Heading } from "@chakra-ui/react"
import { InputStreak } from "../../components/forms/InputStreak"
import { ReadonlyField } from "../../components/forms/fields/ReadonlyField"
import { LocationTrait } from "./LocationTrait"
import { TerminalTrait } from "./TerminalTrait"

interface ConsignorDisplayProps {
  draftConsignor: DraftConsignorValues | null
  title?: string
}

export function ConsignorDisplay({ draftConsignor, title }: ConsignorDisplayProps) {
  return (
    <>
      <Heading as="h6" fontSize="large" alignSelf="start" w="full">
        {title}
      </Heading>
      <ReadonlyField
        label="Company Name"
        helper="Consignor sending the shipment"
      // isRequired={true}
      >
        {draftConsignor?.terminal ? (
          <TerminalTrait id={draftConsignor?.terminal} />
        ) : (
          "--"
        )}
      </ReadonlyField>
      <InputStreak
        label="LSD/Well/Battery"
        helper="Enter an LSD and its prefix"
      >
        <Box width={"25%"}>
          <ReadonlyField helper="LSD prefix">
            {draftConsignor?.lsdprefix ? (
              <LocationTrait
                terminalId={draftConsignor?.terminal}
                id={draftConsignor?.lsdprefix}
              />
            ) : (
              "--"
            )}
          </ReadonlyField>
        </Box>
        <Box width={"75%"}>
          <ReadonlyField helper="Well or battery LSD location">
            {draftConsignor?.lsd ? (
              <LocationTrait
                terminalId={draftConsignor?.terminal}
                id={draftConsignor?.lsd}
              />
            ) : (
              "--"
            )}
          </ReadonlyField>
        </Box>
      </InputStreak>
      <ReadonlyField
        label="Point of Origin"
        helper="Consignor location"
      // isRequired={true}
      >
        {draftConsignor?.location ? (
          <LocationTrait
            terminalId={draftConsignor?.terminal}
            id={draftConsignor?.location}
          />
        ) : (
          "--"
        )}
      </ReadonlyField>
    </>
  )
}
