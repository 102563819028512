import { bolmintApi } from "../../app/api"
import { operations } from "../../app/api/schema"

export const ticketsApi = bolmintApi.injectEndpoints({
  endpoints: (builder) => ({
    listTickets: builder.query<
      operations["tickets_list"]["responses"]["200"]["content"]["application/json"],
      void
    >({
      query: () => ({
        url: "/tickets/",
      }),
      providesTags: ['ticket']
    }),
    listingPaginatedTicketData: builder.query<
      operations["tickets_paginated_list"]["responses"]["200"]["content"]["application/json"],
      string
    >({
      query: (url) => ({
        url: url ? `/ticket-filter/?${url}` : `/ticket-filter/`,
      }),
      providesTags: ['ticket']
    }),
    createTicket: builder.mutation<
      operations["tickets_create"]["responses"]["201"]["content"]["application/json"],
      any
    >({
      query: (resource: any) => ({
        url: "/tickets/",
        method: "POST",
        body: resource,
      }),
      invalidatesTags: ['ticket']
    }),
    retrieveTicket: builder.query<
      operations["tickets_retrieve"]["responses"]["200"]["content"]["application/json"],
      number
    >({
      query: (ticketId) => ({
        url: `/tickets/${ticketId}/`,
      }),
      providesTags: ['ticket']
    }),
    retriveTicketAttachments: builder.query({
      query: (ticketId) => ({
        url: `/tickets/${ticketId}/attachments`,
      }),
      // providesTags: ['ticket']
    }),
    updateTicket: builder.mutation<
      operations["tickets_update"]["responses"]["200"]["content"]["application/json"],
      operations["tickets_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/tickets/${resource.id}/`,
        method: "PUT",
        body: resource,
      }),
    }),
    partialUpdateTicket: builder.mutation<
      operations["tickets_partial_update"]["responses"]["200"]["content"]["application/json"],
      any
    >({
      query: (resource) => ({
        url: `/tickets/${resource.id}/`,
        method: "PATCH",
        body: resource,
      }),
      invalidatesTags: ['ticket']
    }),
    destroyTicket: builder.mutation<void, number>({
      query: (ticketId) => ({
        url: `/tickets/${ticketId}/`,
        method: "DELETE",
      }),
    }),
    removeTicketProduct: builder.mutation({
      query: (resource) => ({
        url: `/tickets/${resource.id}/products/${resource.ticket_product_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ['ticket']
    }),
    removeTicketService: builder.mutation({
      query: (resource) => ({
        url: `/tickets/${resource.id}/services/${resource.ticket_service_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ['ticket']
    }),
    removeTankGaugeRecord: builder.mutation({
      query: (resource) => ({
        url: `/tickets/${resource.id}/tankgauge/${resource.tank_gauge_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ['ticket']
    }),
    removeEquipment: builder.mutation({
      query: (resource) => ({
        url: `/tickets/${resource.id}/equipment/${resource.ticket_equipment_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ['ticket']
    })
  }),
})

export const {
  useListTicketsQuery,
  useCreateTicketMutation,
  useRetrieveTicketQuery,
  useRetriveTicketAttachmentsQuery,
  useUpdateTicketMutation,
  usePartialUpdateTicketMutation,
  useDestroyTicketMutation,
  useRemoveTankGaugeRecordMutation,
  useListingPaginatedTicketDataQuery,
  useRemoveEquipmentMutation
} = ticketsApi
