import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { Flex, Box, VStack, Image } from "@chakra-ui/react"

import logo from "/images/logo.png"
import BOLTlogo from "/images/BOLTLogo.png"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useEffect } from "react"
import { loadSession, selectIsLoggedIn } from "../../features/session/sessionSlice"

export function AuthArea() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  useEffect(() => {
    dispatch(loadSession())
  }, [dispatch])


  useEffect(() => {
    if (isLoggedIn && location.pathname !== "/auth/logout") {
      navigate("/user");
    }
  }, [isLoggedIn, location.pathname, navigate]);

  return (
    <Flex
      bg="gray.100"
      justify="center" align="start"
      minH="100vh"
      paddingX={{ base: 4, md: 8, lg: 16 }}
    >
      <VStack
        alignItems={"center"}
        spacing={{ base: '2rem', md: '3rem' }}
        w={{ base: '100%', sm: 400 }}
        marginX={{ base: 2, md: 10 }}
      >
        <Box marginTop={{ base: 5, md: 10 }}>
          <Image src={logo} width={{ base: 100, md: 150, lg: 200 }} />
        </Box>
        <Outlet />
        <Image src={BOLTlogo} width={{ base: 100, md: 125, lg: 150 }} mb={10} />
      </VStack>
    </Flex>
  )
}
