import { bolmintApi } from "../../app/api"
import { operations } from "../../app/api/schema"

export const profileApi = bolmintApi.injectEndpoints({
  endpoints: (builder) => ({
    retrieveProfile: builder.query<
      operations["profile_retrieve"]["responses"]["200"]["content"]["application/json"],
      void
    >({
      query: () => ({
        url: "/profile/",
      }),
    }),
    updateProfile: builder.mutation<
      operations["profile_update"]["responses"]["200"]["content"]["application/json"],
      operations["profile_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: "/profile/",
        method: "PUT",
        body: resource,
      }),
    }),
    changePassword: builder.mutation({
      query: (resource) => ({
        url: "/change-password/",
        method: "POST",
        body: resource,
      }),
    }),
    forgotPasswordEmailSend: builder.mutation({
      query: (resource) => ({
        url: "/forgot-password/",
        method: "POST",
        body: resource,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ token, ...data }) => ({
        url: `/reset-password/?token=${token}`,
        method: "POST",
        body: data,
      }),
    }),
    isValidToken: builder.query({
      query: ({ token }) => ({
        url: `/decode-token/?token=${token}`,
        method: "GET",
      }),
    })
  }),
})

export const {
  useRetrieveProfileQuery,
  useUpdateProfileMutation,
  useChangePasswordMutation,
  useForgotPasswordEmailSendMutation,
  useResetPasswordMutation,
  useIsValidTokenQuery
} = profileApi
