import { useState } from "react"
import { useAppDispatch, useAppSelector, useCustomToast } from "../../app/hooks"
import { FormCollectionPanel } from "../../components/forms/FormCollectionPanel"
import { FormPanelControls } from "../../components/forms/FormPanelControls"
import { FormPanelDisplay } from "../../components/forms/FormPanelDisplay"
import { TimeEntryDisplay } from "./TimeEntryDisplay"
import { TimeEntryForm } from "./TimeEntryForm"
import {
  addLocalEntry,
  removeLocalEntry,
  selectLocalEntries,
} from "./localEntriesSlice"
import { useCreateTimeEntryMutation } from "./timeEntriesApi"

interface TimeEntryPanelProps {
  setFormIsSubmiting?(data: boolean): void
}

export function TimeEntryPanel({ setFormIsSubmiting }: TimeEntryPanelProps) {
  const dispatch = useAppDispatch()
  const { showToast } = useCustomToast();
  const localEntries = useAppSelector(selectLocalEntries)
  const [isSubmitToTimeSheet, setIsSubmitToTimeSheet] = useState<boolean>(false)
  const [createTimeEntry] = useCreateTimeEntryMutation()

  const handleSave = async (data: any) => {
    if (!isSubmitToTimeSheet) {
      dispatch(addLocalEntry(data))
    } else {
      setFormIsSubmiting?.(true)
      try {
        await createTimeEntry(data).unwrap()
        showToast({
          status: 'success',
          description: 'Successful submission of the time entry',
        });
      } catch (error) {
        showToast({
          status: 'error',
          description: 'Something went wrong',
        });
      } finally {
        setFormIsSubmiting?.(false)
      }
    }
  }

  const handleRemove = (index: number, data: any) => {
    dispatch(removeLocalEntry(index))
  }

  return (
    <FormCollectionPanel
      title="Time Entry"
      entries={localEntries}
      onSave={handleSave}
      onRemove={handleRemove}
      isSubmitToTimeSheet={isSubmitToTimeSheet}
    >
      <FormPanelDisplay>
        <TimeEntryDisplay />
      </FormPanelDisplay>
      <FormPanelControls>
        <TimeEntryForm setIsSubmitToTimeSheet={setIsSubmitToTimeSheet} />
      </FormPanelControls>
    </FormCollectionPanel>
  )
}
