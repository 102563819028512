import { useAppSelector } from "../../app/hooks"
import { SelectField } from "../../components/forms/fields/SelectField"
import { selectAllEquipments } from "./equipmentsSlice"

interface EquipmentSelectProps extends FieldProps {
  setIsValue(data: number | null): void
}

export function EquipmentSelect(props: EquipmentSelectProps) {
  const equipments = useAppSelector(selectAllEquipments)
  const options = equipments.map((equipment) => ({
    value: equipment.id,
    label: `${equipment.unit} (${equipment?.category?.name})`,
  }))
  return <SelectField {...props} options={options} setIsValue={props.setIsValue} />
}
