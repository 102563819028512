import React, { forwardRef, useEffect, useMemo, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { Draft, selectDraft } from '../../features/draft/draftSlice'
import { Box, Divider, HStack, Image, SimpleGrid, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import logo from "/images/logo.png"
import { formatDateOnly } from '../../app/utils'
import { selectSession } from '../../features/session/sessionSlice'
import { selectCustomerById } from '../../features/customers/customersSlice'
import { EquipmentTrait } from '../../features/equipments/EquipmentTrait'
import { selectTerminalById, selectLocationById } from '../../features/terminals/terminalsSlice'
import { ProductResource, selectAllProducts, selectProductById } from '../../features/products/productsSlice'

interface PrintFieldProps {
    label?: string
    children: any
}

interface PrintContentProps {
    draft: Draft
    isWithCharge?: boolean
}

function PrintField({ label, children }: PrintFieldProps) {
    return (
        <Box>
            <Text textTransform="uppercase" fontWeight={600}>
                {label}:&nbsp;
            </Text>
            <Text>{children}</Text>
        </Box>
    )
}

function PrintHeader({ draft }: PrintContentProps) {
    return (
        <HStack w="full" gap={[2, 3]} >
            <Image src={logo} width={20} minWidth={"15%"} />
            <Text w="60%" align="center" fontSize={{ base: 12, lg: 15 }} whiteSpace="nowrap">
                Box 189, Red Earth Creek, Alberta T0G 1X0
                <Text whiteSpace="nowrap">Ph: 780-649-1111 • Fax: 780-649-3897</Text>
                <Text mt={1} fontSize={[14, 16]} fontWeight={600} whiteSpace="nowrap">GST# 837365865 RT001</Text>
            </Text>
            <Text
                w="60%"
                align="center"
                fontSize={{ base: "lg", xl: "2xl" }}
                fontWeight={600}
                textTransform="uppercase"
            >
                Loon River Trucking <br /> Bill of Lading
            </Text>
            <Text
                w="20%"
                align="right"
                fontSize={["xl", "2xl"]}
                fontWeight={600}
                fontFamily="monospace"
            >
                {draft?.number}
            </Text>
        </HStack>
    )
}

function StampField({ label, children }: PrintFieldProps) {
    return (
        <Box>
            <Text fontWeight={600} display={"inline"}>
                {label && `${label}:`}
            </Text>
            <Text display={"inline"}>{children}</Text>
        </Box>
    )
}


function PrintOverview({ draft, isWithCharge }: PrintContentProps) {
    const { basic, locations, pickUp, stampDetails } = draft || {}
    const { profile } = useAppSelector(selectSession)!
    const getTerminalName = (terminal: string | number | undefined) => {
        if (typeof terminal === "number") {
            return useAppSelector(state => selectTerminalById(state, terminal)?.name);
        }
        return terminal;
    };
    const getLocationName = (terminal: string | number | undefined, location: number | string | undefined) => {
        if (typeof terminal === "string" || typeof location === "string") {
            return location;
        }
        return location && useAppSelector(state => selectLocationById(state, terminal || 0, location)?.name);
    };
    const consignorTerminal = getTerminalName(locations?.consignor?.terminal);
    const consignorLocation = getLocationName(locations?.consignor?.terminal, locations?.consignor?.location);
    const consigneeTerminal = getTerminalName(locations?.consignee?.terminal);
    const consigneeLocation = getLocationName(locations?.consignee?.terminal, locations?.consignee?.location);
    const customer = useAppSelector(
        (state) => selectCustomerById(state, basic?.customer || 0)?.name,
    )
    const residue_last_contained = typeof pickUp?.residue_last_contained == "number" ? useAppSelector((state) => selectProductById(state, pickUp?.residue_last_contained)?.name) : pickUp?.residue_last_contained

    return (
        <HStack w="full" align="start" alignItems="center" gap={1} fontSize={[13, 14]}>
            <VStack flex="1" align="start" h="full" gap={2}>
                <SimpleGrid w="full" columns={1} spacing={1}>
                    <Text fontWeight={600} fontSize={20} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" >
                        {customer || "--"}
                    </Text>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1}>
                    <PrintField label="Driver Name">
                        {profile.phone ? `${profile.user?.first_name}${' '}${profile.user.last_name}${' '}(${profile.phone})` : `${profile.user.first_name}${' '}${profile.user.last_name}`}
                    </PrintField>
                    <PrintField label="Date">
                        {formatDateOnly(basic?.reference_date)}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1}>
                    <PrintField label="Equipment Used">
                        {basic?.equipments.map((equipment, index) => (
                            <React.Fragment key={index}>
                                <EquipmentTrait id={equipment} />
                                {index !== (basic?.equipments?.length ?? 0) - 1 && " / "}
                            </React.Fragment>
                        ))}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1}>
                    <PrintField label="Received From">
                        {consignorTerminal || "--"}
                    </PrintField>
                    <PrintField label="LSD/Well/Battery From">
                        {locations?.consignor?.lsd || "--"}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1}>
                    <PrintField label="Delivered To">
                        {consigneeTerminal || "--"}
                    </PrintField>
                    <PrintField label="LSD/Well/Battery To">
                        {locations?.consignee?.lsd || "--"}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1}>
                    <PrintField label="Point of Origin ">
                        {consignorLocation || "--"}
                    </PrintField>
                    <PrintField label="Destination Point">
                        {consigneeLocation || "--"}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1}>
                    <PrintField label="Confirmed Empty">
                        {pickUp?.confirmed_empty ? "Yes" : "No"}
                    </PrintField>
                    <PrintField label="Last Contained">
                        {residue_last_contained || "--"}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <PrintField label="Sequence">
                        {pickUp?.sequence || '--'}
                    </PrintField>
                    <PrintField label="SW Percent">
                        {pickUp?.sw_percent || '--'}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <PrintField label="Billing Email Address">
                        {draft?.basic?.billing_email || "--"}
                    </PrintField>
                </SimpleGrid>
            </VStack>
            {
                isWithCharge &&
                <VStack w="300px" h="350px" borderWidth={2} borderColor="gray" p={2} fontWeight={500} justifyContent="space-between">
                    <Text w="full" align="center">
                        AFE STAMP
                    </Text>
                    {/* <VStack align="start" w="full" p={1} gap={10}>
                        <VStack align="start" gap={2}>
                            <Text>AEF# / Cost Class:</Text>
                            <Text>Minor:</Text>
                            <Text>Major:</Text>
                            <Text>Other:</Text>
                        </VStack>
                        <Box w="full">
                            <Divider borderColor="black" />
                            <Text>
                                Signature
                            </Text>
                        </Box>
                    </VStack> */}
                    <VStack align="start" w="full" p={1} gap={8}>
                        <VStack align="start" gap={2}>
                            <StampField label="AEF# / Cost Class"> {stampDetails?.cost_class} </StampField>
                            <StampField label="Minor"> {stampDetails?.minor_class} </StampField>
                            <StampField label="Major"> {stampDetails?.major_class} </StampField>
                            <StampField label="Other"> {stampDetails?.other} </StampField>
                        </VStack>
                        <Box w="full">
                            {
                                isWithCharge && stampDetails?.digital_sign &&
                                < Image
                                    w="full"
                                    h="50px"
                                    src={stampDetails?.digital_sign || ""}
                                    objectFit="contain"
                                />
                            }
                            <Divider borderColor="black" />
                            <Text fontWeight={600}>
                                Signature
                            </Text>
                        </Box>
                    </VStack>
                </VStack>
            }
        </HStack >
    )
}

function PrintTankGaugeReadings({ draft }: PrintContentProps) {
    return (
        <>
            <VStack w="full" gap={0}   >
                <Text fontWeight={600} >TANK GUAGE READINGS</Text>
                <Table fontSize={[13, 14]} >
                    <Thead>
                        <Tr>
                            <Th p={2} textAlign="center">TANK</Th>
                            <Th p={2} textAlign="center" w={[0, 300]}>START</Th>
                            <Th p={2} textAlign="center">FINISH</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            draft?.pickUp?.tank_gauge_details && draft?.pickUp?.tank_gauge_details?.length !== 0 ?
                                draft?.pickUp?.tank_gauge_details.map((record, index) => {
                                    return (
                                        <Tr >
                                            <Td p={1} textAlign="center">{++index}</Td>
                                            <Td p={1} textAlign="center">{record?.start || '--'}</Td>
                                            <Td p={1} textAlign="center">{record?.finish || '--'}</Td>
                                        </Tr>
                                    )
                                })
                                :
                                <Tr>
                                    <Td p={1} colSpan={3} textAlign="center">No tank gauge readings.</Td>
                                </Tr>
                        }
                    </Tbody>
                </Table>
            </VStack>
        </>
    )
}

function PrintProducts({ draft }: PrintContentProps) {
    const products = useAppSelector(selectAllProducts)
    const productsById = useMemo(() => {
        const productsObj: { [key: number]: ProductResource } = {}
        products.forEach((item) => {
            productsObj[item.id] = item
        })
        return productsObj
    }, [draft?.products])

    return (
        <VStack w="full" className="container"  >
            <Text fontWeight={700} >SHIPMENT</Text>
            <Table >
                <Thead>
                    <Tr>
                        <Th p={2} textAlign="center">Number</Th>
                        <Th p={2} textAlign="center" w={[0, 300]}>Shipping Name</Th>
                        <Th p={2} textAlign="center">Primary Class</Th>
                        <Th p={2} textAlign="center">Subsidiary Class</Th>
                        <Th p={2} textAlign="center">Packing Group</Th>
                        <Th p={2} textAlign="center">Toxic by Inhalation</Th>
                        <Th p={2} textAlign="center">Total Quantity</Th>
                        <Th p={2} textAlign="center"># of Packages</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {draft?.products && draft.products.length > 0 ? (
                        draft.products.map((detail, index) => {
                            const product = typeof detail?.product === "number" ? productsById[Number(detail.product)] : detail?.product;
                            const isProductValid = product && typeof product !== 'string';

                            return (
                                <Tr key={index}>
                                    <Td p={1} textAlign="center">{isProductValid ? product.un_number || "--" : "--"}</Td>
                                    <Td p={1} textAlign="center">{isProductValid ? product.name || "--" : "--"}</Td>
                                    <Td p={1} textAlign="center">{isProductValid ? product.primary_class || "--" : "--"}</Td>
                                    <Td p={1} textAlign="center">{detail?.subsidiary_class || "--"}</Td>
                                    <Td p={1} textAlign="center">{isProductValid ? product.packing_group || "--" : "--"}</Td>
                                    <Td p={1} textAlign="center">{detail?.toxic_by_inhalation ? "Yes" : 'No'}</Td>
                                    <Td p={1} textAlign="center">{detail?.quantity || "--"}</Td>
                                    <Td p={1} textAlign="center">{detail?.packages || "--"}</Td>
                                </Tr>
                            );
                        })
                    ) : (
                        <Tr>
                            <Td p={1} colSpan={8} textAlign="center">No entiries!!</Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </VStack>
    )
}

function PrintCharges({ draft }: PrintContentProps) {
    return (
        <VStack w="full" className="container" textAlign={"center"} >
            <Text fontWeight={600} w={"full"} textAlign={"center"}>BILLING</Text>
            <Table fontSize={[13, 14]} >
                <Thead>
                    <Tr>
                        <Th p={2} w={300}>Description</Th>
                        <Th p={2}>Quantity</Th>
                        <Th p={2}>Unit</Th>
                        <Th p={2}>Rate</Th>
                        <Th p={2}>Amount</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        draft?.charges && draft?.charges.length > 0 ? (
                            draft?.charges?.map((charge, index) => {
                                return (
                                    <Tr key={index} >
                                        <Td p={1}>{charge.description || '--'}</Td>
                                        <Td p={1}>{charge.quantity || '--'}</Td>
                                        <Td p={1}>{charge.unit || '--'}</Td>
                                        <Td p={1}>{charge.rate || '--'}</Td>
                                        <Td p={1}>{charge.amount || '--'}</Td>
                                    </Tr>
                                )
                            })
                        ) : (
                            <Tr>
                                <Td p={1} colSpan={5} textAlign="center">No entiries!!</Td>
                            </Tr>
                        )
                    }
                </Tbody>
            </Table>
        </VStack>
    )
}

function PrintDescription({ draft }: PrintContentProps) {

    const { charges, droppOff, services } = draft

    const totalAmount = useMemo(() =>
        charges?.reduce((total, { amount }) => total + Number(amount), 0)
        , [draft?.charges]);

    const description = useMemo(() => {
        const desc: string[] = [];
        if (droppOff?.work_description) {
            desc.push(droppOff.work_description);
        }
        services?.forEach(({ description }) => {
            if (description) {
                desc.push(description);
            }
        });
        return desc.join('. ');
    }, [draft]);

    return (
        <HStack w="full" className="avoidSplit" h="fit-content" borderWidth={2} p={2} justifyContent="space-between" fontSize={[13, 14]} >
            <Box p={3} maxW={"70%"}>
                <Text fontWeight={600}>DESCRIPTION OF WORK</Text>
                <Text whiteSpace="normal" wordBreak="break-word">{description || '--'}</Text>
            </Box>
            <Box p={3}>
                <Text fontWeight={600}>TOTAL CHARGES : </Text>
                <Text > {(totalAmount || 0).toFixed(2)}</Text>
            </Box>
        </HStack>
    )
}

export const PrintContent = forwardRef<HTMLDivElement, PrintContentProps>(
    ({ draft, isWithCharge }: PrintContentProps, ref) => {
        return (
            <VStack w="full" gap={0} mt={3} ref={ref} p={10}>
                <PrintHeader draft={draft} />
                <PrintOverview draft={draft} isWithCharge={isWithCharge} />
                <PrintTankGaugeReadings draft={draft} />
                <PrintProducts draft={draft} />
                {isWithCharge ? <PrintCharges draft={draft} /> : null}
                {isWithCharge ? <PrintDescription draft={draft} /> : null}
                {/* {isWithCharge ? <PrintFooter ticket={ticket} /> : null} */}
            </VStack>
        )
    },
)

export function PrintDraft() {
    const { id } = useParams()
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0
    const currentDraft = useAppSelector(selectDraft(draftIndex))
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const withCharge = searchParams.get('withCharge') === "true";
    const hasPrinted = useRef(false);

    useEffect(() => {
        setTimeout(() => {
            if (currentDraft && !hasPrinted.current) {
                window.print()
                hasPrinted.current = true
            }
        }, 20)

    }, [currentDraft])
    return (
        <PrintContent draft={currentDraft} isWithCharge={withCharge} />
    )
}

