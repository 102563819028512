import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk, AppDispatch } from "../../app/store"
import { sessionApi } from "./sessionApi"
import { clearToken, setToken } from "../../app/auth"
import { components } from "../../app/api/schema"

type Profile = components["schemas"]["MyProfile"]
interface LoginCredentials {
  username: string
  password: string
}

interface SessionResource {
  id: number
  token: string
  profile: any
}

interface SessionState {
  isLoggedIn: boolean
  resource: SessionResource | null
}

const initialState: SessionState = {
  resource: null,
  isLoggedIn: false,
}

export const loginUser = createAsyncThunk<
  SessionResource,
  LoginCredentials,
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  "session/loginUser",
  async (
    { username, password }: LoginCredentials,
    { dispatch, rejectWithValue },
  ) => {
    try {
      // Create session against backend using proper API endpoint
      const sessionResource = await dispatch(
        sessionApi.endpoints.createSession.initiate({
          username,
          password,
        }),
      ).unwrap()

      // Update state and save on localStorage
      dispatch(saveSession(sessionResource))

      // Return thunk result
      return sessionResource
    } catch (error) {
      // Handle login error if needed
      // throw error
      return rejectWithValue(error)
    }
  },
)

export const logoutUser = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>("session/logoutUser", async (_, { getState, dispatch }) => {
  const sessionId = (getState() as RootState).session.resource?.id
  if (sessionId) {
    try {
      // Delete session against backend using proper API endpoint
      await dispatch(
        sessionApi.endpoints.deleteSession.initiate(sessionId),
      ).unwrap()
    } catch (error) {
      // Handle logout error if needed
      throw error
    } finally {
      // Delete session even if API fails
      dispatch(deleteSession())
    }
  } else {
    // Delete session even if we don't identify it
    dispatch(deleteSession())
  }
})

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    sessionStarted(state, action: PayloadAction<SessionResource>) {
      state.isLoggedIn = true
      state.resource = action.payload
    },
    sessionFinished(state) {
      state.isLoggedIn = false
      state.resource = null
    },
  },
})

export const selectSession = (state: RootState) => state.session.resource
export const selectIsLoggedIn = (state: RootState) => state.session.isLoggedIn

export const { sessionStarted, sessionFinished } = sessionSlice.actions

export const saveSession =
  (resource: SessionResource): AppThunk =>
  (dispatch, getState) => {
    localStorage.setItem("session", JSON.stringify(resource))
    dispatch(setToken(resource.token))
    dispatch(sessionStarted(resource))
  }

export const loadSession = (): AppThunk<boolean> => (dispatch, getState) => {
  const storedSessionResource = localStorage.getItem("session")
  const parsedSessionResource: SessionResource = storedSessionResource
    ? JSON.parse(storedSessionResource)
    : null
  if (parsedSessionResource) {
    dispatch(setToken(parsedSessionResource.token))
    dispatch(sessionStarted(parsedSessionResource))
    return true
  }
  return false
}

export const deleteSession = (): AppThunk => (dispatch, getState) => {
  console.log("Removing session...")
  localStorage.removeItem("session")
  dispatch(clearToken())
  dispatch(sessionFinished())
}

export default sessionSlice.reducer
