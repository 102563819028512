import { InputStreak } from '../../components/forms/InputStreak'
import { DigitalSignPadField } from '../../components/forms/fields/DigitalSignPadField'
import { NumberField } from '../../components/forms/fields/NumberField'
function StampForm() {
    return (
        <>
            <InputStreak label="AEF# / Cost Class">
                <NumberField name="cost_class" />
            </InputStreak>
            <InputStreak label="Minor Class">
                <NumberField name="minor_class" />
            </InputStreak>
            <InputStreak label="Major Class" >
                <NumberField name="major_class" />
            </InputStreak>
            <InputStreak label="Other" >
                <NumberField name="other" />
            </InputStreak>
            <InputStreak label="Signature" >
                <DigitalSignPadField name="digital_sign" />
            </InputStreak>
        </>
    )
}

export default StampForm
