import { createSlice, createEntityAdapter } from "@reduxjs/toolkit"
import { components } from "../../app/api/schema"
import { chargesApi } from "./chargesApi"
import { RootState } from "../../app/store"

type ChargeResource = components["schemas"]["Charge"]

const chargesAdapter = createEntityAdapter<ChargeResource>({
  selectId: (charge) => charge.id,
})

const initialState = chargesAdapter.getInitialState({
  status: "idle",
  error: null,
})

const chargesSlice = createSlice({
  name: "charges",
  initialState,
  reducers: {
    // Reducer functions will go here if needed
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      chargesApi.endpoints.listChargesByTicketId.matchFulfilled,
      (state, action) => {
        chargesAdapter.setAll(state, action.payload)
      },
    )
    builder.addMatcher(
      chargesApi.endpoints.createCharge.matchFulfilled,
      (state, action) => {
        chargesAdapter.addOne(state, action.payload)
      },
    )
    builder.addMatcher(
      chargesApi.endpoints.retrieveCharge.matchFulfilled,
      (state, action) => {
        chargesAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      chargesApi.endpoints.updateCharge.matchFulfilled,
      (state, action) => {
        chargesAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      chargesApi.endpoints.partialUpdateCharge.matchFulfilled,
      (state, action) => {
        chargesAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      chargesApi.endpoints.destroyCharge.matchFulfilled,
      (state, action) => {
        chargesAdapter.removeOne(state, action.meta.arg.originalArgs)
      },
    )
    builder.addMatcher(
      chargesApi.endpoints.destroyChargesByTicket.matchFulfilled,
      (state, action) => {
        chargesAdapter.removeAll(state)
      },
    )
  },
})

export default chargesSlice.reducer

export const {
  selectAll: selectAllCharges,
  selectById: selectChargeById,
  selectIds: selectChargeIds,
  selectEntities: selectChargeEntities,
  selectTotal: selectTotalCharges,
} = chargesAdapter.getSelectors<RootState>((state) => state.charges)
