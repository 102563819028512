import { bolmintApi } from "../../app/api"
import { operations } from "../../app/api/schema"

export const locationsApi = bolmintApi.injectEndpoints({
  endpoints: (builder) => ({
    listLocations: builder.query<
      operations["locations_list"]["responses"]["200"]["content"]["application/json"],
      void
    >({
      query: () => ({
        url: "/locations/",
      }),
      providesTags: ["locations"]
    }),
    createLocation: builder.mutation<
      operations["locations_create"]["responses"]["201"]["content"]["application/json"],
      Partial<
        operations["locations_create"]["requestBody"]["content"]["application/json"]
      >
    >({
      query: (resource) => ({
        url: `/locations/?for=${resource.for}`,
        method: "POST",
        body: resource,
      }),
      invalidatesTags: ["locations", "terminals"]
    }),
    retrieveLocation: builder.query<
      operations["locations_retrieve"]["responses"]["200"]["content"]["application/json"],
      number
    >({
      query: (locationId) => ({
        url: `/locations/${locationId}/`,
      }),
    }),
    updateLocation: builder.mutation<
      operations["locations_update"]["responses"]["200"]["content"]["application/json"],
      operations["locations_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/locations/${resource.id}/`,
        method: "PUT",
        body: resource,
      }),
    }),
    partialUpdateLocation: builder.mutation<
      operations["locations_partial_update"]["responses"]["200"]["content"]["application/json"],
      operations["locations_partial_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/locations/${resource.id}/`,
        method: "PATCH",
        body: resource,
      }),
    }),
    destroyLocation: builder.mutation<void, number>({
      query: (locationId) => ({
        url: `/locations/${locationId}/`,
        method: "DELETE",
      }),
    }),
  }),
})

export const {
  useListLocationsQuery,
  useCreateLocationMutation,
  useRetrieveLocationQuery,
  useUpdateLocationMutation,
  usePartialUpdateLocationMutation,
  useDestroyLocationMutation,
} = locationsApi
