import { Text } from "@chakra-ui/react";
import { useAppSelector, useChoiceTrait } from "../../app/hooks"
import { TraitLine } from "../../components/TraitLine"
import { selectActivityById } from "../activities/activitySlice";
import { ACTIVITY_OPTIONS } from "./choices"

interface ActivityTraitProps extends ChoiceTraitProps { }

export function ActivityTrait({ choice }: ActivityTraitProps) {
  const activity = useAppSelector(state => selectActivityById(state, Number(choice)))?.name;
  // const options = ACTIVITY_OPTIONS
  // const { trait } = useChoiceTrait(options, choice)

  // return <TraitLine>{trait}</TraitLine>
  return <Text>{activity}</Text>
}
