import { KnownIcon, KnownIconName } from "./widgets/KnownIcon"

interface StripIconProps {
  name: KnownIconName
  size?: number
  color?: string
}

export function StripIcon({ name, size, color }: StripIconProps) {
  return <KnownIcon name={name} size={size} color={color} />
}
