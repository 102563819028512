
export const REQUIRED_FIELDS = [
    { value: "customer_id", label: "Customer" },
    { value: "equipmentdetails", label: "Equipment" },
    { value: "consignor_phone", label: "Consignor Contact" },
    { value: "emergency_contact", label: "Emergency contact" },
    { value: "consignor_terminal_id", label: "Consignor company name" },
    { value: "consignor_location_id", label: "Consignor point of origin" },
    { value: "consignee_terminal_id", label: "Consignee company name" },
    { value: "consignee_location_id", label: "Consignee point of origin" },
    { value: "actual_volume", label: "Actual volume" },
    { value: "tank_gauge_finish", label: "Tank gauge start" },
    { value: "tank_gauge_start", label: "Tank gauge finish" },

    //for authentication
    { value: "username", label: "Username" },
    { value: "password", label: "Password" }
]