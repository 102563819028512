import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Switch,
} from "@chakra-ui/react"
import { RegisterOptions, useFormContext } from "react-hook-form"

interface BooleanFieldProps {
  label: string
  name: string
  registerOptions?: RegisterOptions
  helper?: string
}

export function BooleanField({
  label,
  name,
  registerOptions,
  helper,
}: BooleanFieldProps) {
  const { register } = useFormContext()

  return (
    <FormControl>
      <FormLabel size="sm" fontWeight={600}>{label}</FormLabel>
      <Switch {...register(name, registerOptions)} />
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>You missed this</FormErrorMessage>
    </FormControl>
  )
}
