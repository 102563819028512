import React from 'react'
import { NumberField } from '../../components/forms/fields/NumberField'
import { TextareaField } from '../../components/forms/fields/TextareaField'


interface DraftDroppOffFormProps {
    // defaultValues: any
}

export function DroppOffForm({ }: DraftDroppOffFormProps) {
    return (
        <>
            <NumberField
                label="Actual Volume"
                name="actual_volume"
            />
            <TextareaField
                label="Description of Work"
                name="work_description"
                helper="Describe the work in few words"
            />
        </>
    )
}

