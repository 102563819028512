import { Box, VStack } from "@chakra-ui/react"

import { ViewHeader } from "./parts/ViewHeader"
import { ViewStream } from "./parts/ViewStream"

interface HomeViewProps {
  children: React.ReactNode
  title: string
  subtitle?: string
}

export function HomeView({ title, subtitle, children }: HomeViewProps) {
  return (
    <Box w="full">
      <VStack w="full">
        <Box w="full" p={2}>
          <ViewHeader subtitle={subtitle}>{title}</ViewHeader>
        </Box>
        <ViewStream>{children}</ViewStream>
      </VStack>
    </Box>
  )
}
