import { FormControl, FormLabel, FormHelperText, Box } from "@chakra-ui/react"

import React from "react"

interface ReadonlyFieldProps {
  label?: string
  helper?: string
  children: React.ReactNode | React.ReactNode[]
  isRequired?: boolean
}

export function ReadonlyField({ label, helper, children, isRequired }: ReadonlyFieldProps) {
  return (
    <FormControl isRequired={isRequired}>
      <FormLabel fontWeight={600} >{label}</FormLabel>
      <Box py={2} px={4} border="1px solid" borderColor="gray.200" rounded="md" style={{ overflowX: 'auto', whiteSpace: 'wrap', wordBreak: 'break-word' }} >
        {children}
      </Box>
      {helper && <FormHelperText color="gray.400">{helper}</FormHelperText>}
    </FormControl>
  )
}