import { Box, Grid, GridItem, HStack, VStack } from "@chakra-ui/react"
import { InputStreak } from "../../components/forms/InputStreak"
import { ReadonlyField } from "../../components/forms/fields/ReadonlyField"
import { ProductTrait } from "../products/ProductTrait"
import { Fragment } from "react"

interface DangerousGoodsPickUpDisplayProps {
  draftExtra: DraftExtraValues | null
}

export function PickUpDisplay({ draftExtra }: DangerousGoodsPickUpDisplayProps) {

  return (
    <>
      <ReadonlyField
        label="Estimated Volume"
        helper="Estimated volume as seen by the driver"
      >
        {draftExtra?.estimated_volume || "--"}
      </ReadonlyField>
      {/* <ReadonlyField label="Actual Volume"
      // isRequired={true}
      >
        {draftExtra?.actual_volume || "--"}
      </ReadonlyField> */}
      <ReadonlyField label="Sequence">
        {draftExtra?.sequence || "--"}
      </ReadonlyField>
      <ReadonlyField label="SW Percent">
        {draftExtra?.sw_percent || "--"}
      </ReadonlyField>
      <InputStreak label="Tank Gauge" helper="">
        <Grid templateColumns="1fr 1fr" gap={2} w="full" >
          {
            draftExtra?.tank_gauge_details !== undefined ?
              draftExtra?.tank_gauge_details.map((record, index) => (
                <Fragment key={index}>
                  <GridItem>
                    <ReadonlyField
                      label="Start"
                    //  isRequired={true}
                    >
                      {record?.start || "--"}
                    </ReadonlyField>
                  </GridItem>
                  <GridItem>
                    <ReadonlyField
                      label="Finish"
                    // isRequired={true}
                    >
                      {record?.finish || "--"}
                    </ReadonlyField>
                  </GridItem>
                </Fragment>
              )) :
              <>
                <ReadonlyField
                  label="Start"
                //  isRequired={true}
                >
                  --
                </ReadonlyField>
                <ReadonlyField
                  label="Finish"
                // isRequired={true}
                >
                  --
                </ReadonlyField>
              </>
          }
        </Grid>
      </InputStreak>
      {/* <ReadonlyField
        label="Description of Work"
        helper="Describe the work in few words"
      >
        {draftExtra?.work_description || "--"}
      </ReadonlyField> */}

      <ReadonlyField label="Confirmed Empty">
        {(draftExtra?.confirmed_empty && "Yes") || "No"}
      </ReadonlyField>
      <ReadonlyField label="Residue Last Contained">
        {draftExtra?.residue_last_contained ? (
          <ProductTrait id={draftExtra?.residue_last_contained} />
        ) : (
          "--"
        )}
      </ReadonlyField>
    </>
  )
}
