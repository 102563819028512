import { DetailView } from "../../layout/views/DetailView"
import { useAppSelector } from "../../app/hooks"
import { Button, Flex, HStack, Switch, Text, useMediaQuery } from "@chakra-ui/react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { selectTicketById } from "../../features/tickets/ticketsSlice"
import { usePartialUpdateTicketMutation, useRetrieveTicketQuery } from "../../features/tickets/ticketsApi"
import { useCreateChargeMutation, useDestroyChargeMutation, useDestroyChargesByTicketMutation } from "../../features/charges/chargesApi"
import { TicketServicesOverview } from "../../features/tickets/TicketServicesOverview"
import { TimeEntriesAggregated } from "../../features/timeEntries/TimeEntriesAggregated"
import { Panel } from "../../components/Panel"
import { VolumeTable } from "../../features/tickets/VolumeTable"
import { ChargePanelOverview } from "../../features/charges/ChargePanelOverview"
import { selectSession } from "../../features/session/sessionSlice"
import { KnownIcon } from "../../components/widgets/KnownIcon"
import { useNetworkCheck } from "../../app/hooks"
import { ErrorBox } from "../../components/ErrorBox"

export function ChargesPage() {
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const [isSmallScreen] = useMediaQuery('(max-width: 700px)');
  const { isOnline } = useNetworkCheck()
  const session = useAppSelector(selectSession);
  const role = session?.profile.role;
  const [partialUpdateTicket] = usePartialUpdateTicketMutation();

  //retrive ticket
  const { isLoading } = useRetrieveTicketQuery(Number(ticketId))
  const ticket = useAppSelector((state) =>
    selectTicketById(state, ticketId || 0),
  );
  const charges = ticket?.chargedetails ?? []
  const handleNavigation = () => {
    isOnline ? navigate(`/user/tickets/${ticketId}`) : navigate(`/user/home`)
  }

  const [createCharge] = useCreateChargeMutation()
  const [destroyCharge] = useDestroyChargeMutation()

  const handleSaveCharge = async (data: any) => {
    const { unit } = data
    //make unit capitalize first letter
    const capitalizedUnit = unit.charAt(0).toUpperCase() + unit.slice(1)
    const payload = {
      ...data,
      ticket: ticketId,
      unit: capitalizedUnit,
    }
    const newCharge = await createCharge(payload)
    console.log("Created charge", newCharge)
  }

  const handleRemoveCharge = (index: number) => {
    const destroyingCharge = charges[index]
    destroyCharge(destroyingCharge.id)
    console.log("Destroyed charge", index, destroyingCharge)
  }

  //discard all charges and navigate to ticket details page if mutation get successfull
  const [destroyChargesByTicket, { isLoading: isChargesDistroyed }] = useDestroyChargesByTicketMutation()
  const handleDiscardCharges = async () => {
    try {
      await destroyChargesByTicket(Number(ticketId))
      navigate(`/user/tickets/${ticketId}`)
    } catch (error) {
      console.error("failed to discrad charges :", error);
    }
  }
  const handleSaveAndApprove = async () => {
    try {
      const body = {
        id: Number(ticketId),
        status: ticket?.digital_signed ? "stamped" : "approved",
      }
      await partialUpdateTicket(body)
      navigate(`/user/tickets/${ticketId}`)
    } catch (error) {
      console.error("failed to approve ticket :", error);
    }
  }
  const handleAskToDriverLead = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    const body = {
      id: Number(ticketId),
      driver_lead_add_charges: checked,
    }
    partialUpdateTicket(body)
  }
  return (
    <DetailView
      title={ticket && !isLoading ? `Charges for #${ticket?.number}` : ""}
      isLoading={isLoading}
    >
      {
        ticket ? <>
          <Panel title="Volume">
            <VolumeTable
              ticket={ticket}
              onSave={handleSaveCharge}
            />
          </Panel>
          <Panel title="Billable Time">
            <TimeEntriesAggregated
              ticket={ticket}
              onSave={handleSaveCharge}
            />
          </Panel>
          <Panel title="Additional Billable Items">
            <TicketServicesOverview
              ticket={ticket}
              onSave={handleSaveCharge}
            />
          </Panel>
          <Panel title="Charges" subtitle="Billing items for this ticket">
            <ChargePanelOverview
              charges={charges}
              onSave={handleSaveCharge}
              onRemove={handleRemoveCharge}
            />
          </Panel>
          {
            role === "driver-lead"
            &&
            <HStack>
              <Text fontWeight={600} fontSize="sm"  >Ask Driver Lead to add Charges : </Text>
              <Switch onChange={handleAskToDriverLead} isChecked={ticket?.driver_lead_add_charges} />
            </HStack>
          }
          <Flex w="full"
            flexDirection={isSmallScreen ? "column" : "row"}
            gap={isSmallScreen ? 2 : 3}
            justifyContent={isSmallScreen ? "center" : "flex-end"}
            alignItems={isSmallScreen ? "center" : "flex-end"}
          >
            {(role === "driver-lead" || role === "driver") && (
              <>
                <Button
                  as={isOnline ? Link : 'button'}
                  {...(isOnline ? { to: `/print/tickets/${ticketId}?withCharge=${true}`, target: "_blank" } : {})}
                  colorScheme="blue"
                  size={["xs", "sm"]}
                  w={isSmallScreen ? "45%" : "auto"}
                  rightIcon={<KnownIcon name="roundDollar" size={5} />}
                  isDisabled={isOnline ? false : true}
                  p={4}
                >
                  Print with
                </Button>
              </>
            )}
            {(role === "driver-lead" || role === "driver") && (
              <>
                <Button
                  as={isOnline ? Link : 'button'}
                  {...(isOnline ? { to: `/print/tickets/${ticketId}?withCharge=${false}`, target: "_blank" } : {})}
                  colorScheme="blue"
                  size={["xs", "sm"]}
                  w={isSmallScreen ? "45%" : "auto"}
                  rightIcon={<KnownIcon name="roundDollar" size={5} />}
                  isDisabled={isOnline ? false : true}
                  p={4}
                >
                  Print without
                </Button>
              </>
            )}
            {
              role === "driver-lead" && (ticket?.status === "submitted" || ticket?.status === "unapproved")
              &&
              <Button
                size={["xs", "sm"]}
                p={4}
                colorScheme="blue"
                onClick={handleSaveAndApprove}
                w={isSmallScreen ? "45%" : "auto"}
                // isLoading={isUpdating}
                isDisabled={(charges?.length === 0 || !isOnline) ? true : false}>
                Save and Approve
              </Button>

            }
            {
              charges?.length !== 0
              &&
              <Button
                size={["xs", "sm"]}
                p={4}
                colorScheme="blue"
                isLoading={isChargesDistroyed}
                w={isSmallScreen ? "45%" : "auto"}
                onClick={handleDiscardCharges}
                isDisabled={isOnline ? false : true}
              >
                Discard Charges
              </Button>

            }
            <Button
              size={["xs", "sm"]}
              p={4}
              colorScheme="blue"
              w={isSmallScreen ? "45%" : "auto"}
              onClick={handleNavigation}
            >
              {isOnline ? "Save" : "Back to Home"}
            </Button>
          </Flex>
        </> :
          <>
            <ErrorBox show={!ticket && !isOnline ? true : false} message={"Please try again later. The data you are attempting to view is either unavailable or your network connection is unstable."} />
          </>
      }
    </DetailView >
  )
}
