import { IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, useMediaQuery } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ModalForm } from "../../components/forms/ModalForm";
import { ChargesForm } from "../charges/ChargesForm";
import { useAppSelector } from "../../app/hooks";
import { selectDraftCharges } from "./draftSlice";
import { KnownIcon } from "../../components/widgets/KnownIcon";
import { useState } from "react";

interface DraftVolumeProps {
    draftVolume: DroppOffValues | null
    onSave(data: any): void
}

export function DraftVolumeTable({ draftVolume, onSave }: DraftVolumeProps) {
    const { id } = useParams()
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0
    const draftCharges = useAppSelector(selectDraftCharges(draftIndex))
    const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
    const modalSize = isLargerThanMobile ? "lg" : "full"
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [defaultValues, setDefaultValues] = useState(null)
    const handleClose = () => {
        setIsModalOpen(false)
    }
    const handleOpen = () => {
        setIsModalOpen(true)
    }
    const isInCharges = () => {
        return draftCharges?.some((item) => item.description === "Actual Volume")
    }

    return (
        <>
            {
                draftVolume?.actual_volume ? (
                    <div style={{ maxWidth: "calc(100vw - 100px)", width: "100%", overflowX: "auto" }}>
                        <Table >
                            <Thead>
                                <Tr>
                                    <Th></Th>
                                    <Th>Cubic Meters</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>Actual</Td>
                                    <Td>{draftVolume?.actual_volume || '--'}</Td>
                                    <Td>
                                        {
                                            !isInCharges()
                                                ?
                                                <IconButton
                                                    aria-label="create-charge"
                                                    icon={<KnownIcon name="dollar" />}
                                                    size={["xs", "sm", "md"]}
                                                    onClick={() => {
                                                        handleOpen()
                                                        const data = {
                                                            description: "Actual Volume",
                                                            unit: "Cubic Meters",
                                                            quantity: draftVolume?.actual_volume,
                                                            allow_surcharge: true
                                                        }
                                                        setDefaultValues(data as any)
                                                    }}
                                                />
                                                :
                                                null
                                        }
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </div>
                ) : (
                    <Text>{"Volume charges will be added by the Driver Lead"}</Text>
                )
            }



            {/* Dialog for adding new charge entry */}
            <ModalForm
                title="Add New Entry"
                isOpen={isModalOpen}
                size={modalSize}
                onClose={handleClose}
                onCancel={handleClose}
                onSave={onSave}
                defaultValues={defaultValues}
            >
                <ChargesForm />
            </ModalForm>
        </>
    )
}