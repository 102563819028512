import { ChangeEvent, Fragment, useEffect, useMemo, useState } from 'react'
import { ListView } from '../../layout/views/ListView'
import { Box, Divider, Flex, Heading, HStack, SimpleGrid, Spinner, Switch, Text, useMediaQuery, VStack } from '@chakra-ui/react';
import ResponsivePagination from 'react-responsive-pagination';
import Select from 'react-select'
import { usePaginatedTimeEntriesQuery, usePartialUpdateTimeEntryMutation } from '../../features/timeEntries/timeEntriesApi';
import { formatMinutesAsHours, getDateString, getDateTimeString } from '../../app/utils';
import { Streak } from '../../components/Streak';
import { components } from '../../app/api/schema';
import { ErrorBox } from '../../components/ErrorBox';

export type OptionType = {
    value: string | number;
    label: string;
};
export type DateRange = [Date | null, Date | null];
export interface FilterValues {
    activity?: OptionType[],
    filterByName?: string | null,
    customer?: OptionType | null,
    is_Nonticket?: boolean,
    ticket?: number | null,
    currentPage?: number,
    itemsPerPage?: OptionType,
    dateRange: DateRange,
    is_approved?: boolean,
    status?: OptionType[],
    filterCustomer?: string,
}
type TimeEntriesResource = components["schemas"]["TimeEntry"]

export function ApproveSubmittedTimeEntries() {

    const pageSizeOptions = [10, 20, 30, 40].map((pageSize) => {
        return {
            value: pageSize,
            label: `${pageSize} / page`,
        }
    })
    const initialState: FilterValues = {
        activity: [],
        customer: null,
        filterByName: null,
        dateRange: [null, null],
        currentPage: 1,
        itemsPerPage: pageSizeOptions[0],
        is_Nonticket: false,
        is_approved: false,
        ticket: null
    }
    const [filters, setFilters] = useState<FilterValues>(initialState);
    const [finalFilter, setFinalFilter] = useState<FilterValues>(filters)
    const { activity, customer, filterByName, dateRange, currentPage, itemsPerPage, is_Nonticket, ticket, is_approved } = finalFilter;
    const [isSmallScreen] = useMediaQuery('(max-width: 600px)')
    const [isMobileScreen] = useMediaQuery('(max-width: 480px)')
    const [networkError, setNetworkError] = useState("")
    const url = useMemo(() => {
        const params = new URLSearchParams();
        if (filterByName && filterByName?.trim().length > 0) params.append('search', `${filterByName}`);
        if (ticket) params.append('ticket', `${ticket}`);
        if (customer) params.append('customer', customer?.label);
        if (dateRange[0]) params.append('date_range_after', getDateString(dateRange[0]));
        if (dateRange[1]) params.append('date_range_before', getDateString(dateRange[1]));
        if (currentPage) params.append('page', currentPage.toString());
        if (itemsPerPage) params.append('paginate', itemsPerPage?.value.toString());
        // if (activity && activity.length !== 0) {
        //     params.append('activity', activity.map(item => item.value).join(','))
        // }
        activity && activity.forEach(activity => {
            params.append('activity', activity.value.toString());
        });
        if (is_Nonticket) params.append('non_ticket_time', `${1}`);
        params.append('is_approved', `${is_approved}`);

        return params.toString();
    }, [finalFilter])

    const { data, isFetching, refetch, isError, error } = usePaginatedTimeEntriesQuery(url)
    const [partialUpdateTimeEntry] = usePartialUpdateTimeEntryMutation()

    const handlePageChange = (newPage?: number) => {
        setFilters(prev => ({ ...prev, currentPage: newPage || 1 }));
        setFinalFilter(prev => ({ ...prev, currentPage: newPage || 1 }))
    }
    const handlePageSizeChange = (size: OptionType) => {
        setFilters(prev => ({ ...prev, itemsPerPage: size }));
        setFinalFilter(prev => ({ ...prev, itemsPerPage: size }))
    }
    const handleApplyFilter = () => {
        setFinalFilter(filters)
    }
    const handleResetFilter = () => {
        setFilters(initialState)
        setFinalFilter(initialState)
    }
    const handleApproveTime = async (e: ChangeEvent<HTMLInputElement>, id: number) => {
        const { checked } = e.target
        partialUpdateTimeEntry({ id, is_approved: checked })
    }

    useEffect(() => {
        refetch()
    }, [finalFilter, refetch])

    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("Ticket details are not available without network connectivity");
            window.scrollTo({ top: 0, behavior: "smooth" })
        }
    }, [isError])

    return (
        <ListView
            title="Approve Time"
            filter_label="Filter Time Entries"
            isLoading={isFetching}
            filters={filters}
            setFilters={setFilters}
            handleApplyFilter={handleApplyFilter}
            handleResetFilter={handleResetFilter}
        >
            <>
                <ErrorBox show={isError ? true : false} message={networkError} />
                {
                    !isError && data && data.timeentries?.length > 0 &&
                    <Flex w="full" p={2} justifyContent="center" alignItems="center" >
                        {
                            !isSmallScreen &&
                            <Box w="30%">
                                <Text fontSize={13} color={"#475f7b"}  >
                                    {data?.timeentries?.length > 0 && `Showing ${data?.from}-${data?.to} of ${data?.total} Tickets`}
                                </Text>
                            </Box>
                        }
                        <Box w={isSmallScreen ? "90%" : "70%"} display="flex" justifyContent="flex-end" alignItems="center">
                            <Box w={isMobileScreen ? "100%" : "80%"}>
                                <ResponsivePagination
                                    total={data.totalpage}
                                    current={currentPage || 1}
                                    onPageChange={page => handlePageChange(page)}
                                />
                            </Box>
                            {
                                !isMobileScreen &&
                                <Box w="20%">
                                    <Select
                                        value={itemsPerPage}
                                        options={pageSizeOptions}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: state.isFocused ? '#3182ce' : '#E2E8F0',
                                                boxShadow: state.isFocused ? '0 0 0 1px #3182ce' : '',
                                                fontSize: "12px",
                                            }),
                                            option: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "12px"
                                            })
                                        }}
                                        components={{
                                            DropdownIndicator: null,
                                            IndicatorSeparator: null,
                                        }}
                                        onChange={(value) => value && handlePageSizeChange(value)}
                                    />
                                </Box>
                            }
                        </Box>
                    </Flex>
                }
                <VStack align="start" w="full" gap={5} p={4} justifyContent={"center"} alignItems="center">
                    {
                        !isFetching ?
                            !isError &&
                            (data && data.timeentries?.length > 0 ? (
                                data.timeentries.map((item: TimeEntriesResource, index: number) => (
                                    <Fragment key={index} >
                                        <HStack w="full" gap={4} justifyContent="space-between" >
                                            <Heading size={["xs", "sm"]} >
                                                {`#${item.ticket}`}
                                            </Heading>
                                            <Heading size={["xs", "sm"]}>
                                                {getDateString(item?.finish ?? '')}
                                            </Heading>
                                            <Heading size={["xs", "sm"]}>
                                                {item?.driver}
                                            </Heading>
                                        </HStack>
                                        <SimpleGrid w="100%" columns={4} spacing={2} templateColumns="20% 35% 20% 25%" fontSize="1em" justifyContent="space-between">
                                            <Streak label="Task " size="large">
                                                <Text fontSize="0.8em">
                                                    {item.activity.name}
                                                </Text>{" "}
                                            </Streak>
                                            <Streak label="Company " size="large">
                                                <Text fontSize="0.8em">{item.customer}</Text>{" "}
                                            </Streak>
                                            <Streak label="Total " size="large">
                                                <Text fontSize="0.8em">{`${formatMinutesAsHours(item?.minutes)} Hours`}</Text>{" "}
                                            </Streak>
                                            <Streak label="Approve :" size="large">
                                                <Switch isChecked={item.is_approved} onChange={(e) => handleApproveTime(e, item.id)} display="inline" />
                                            </Streak>
                                        </SimpleGrid>
                                        <SimpleGrid w="100%" column={2} spacing={2} templateColumns="50% 50%">
                                            <Streak label="Start" size="large">
                                                <Text fontSize="0.8em">{getDateTimeString(item?.start)}</Text>{" "}
                                            </Streak>
                                            <Streak label="Finish" size="large">
                                                <Text fontSize="0.8em">{getDateTimeString(item?.finish)}</Text>{" "}
                                            </Streak>
                                        </SimpleGrid>
                                        <Divider />
                                    </Fragment>
                                ))
                            ) : (
                                <>
                                    <Text>No time entries found</Text>
                                </>
                            )) : (
                                <Box w="full" textAlign="center">
                                    <Spinner />
                                </Box>
                            )
                    }
                </VStack >
            </>
        </ListView >
    )
}
