import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "./store"

interface AuthState {
  token: string | null
}

const initialState: AuthState = {
  token: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    clearToken(state) {
      console.log("Clearing token")
      state.token = null
    },
  },
})

export const selectToken = (state: RootState) => state.auth.token

export const { setToken, clearToken } = authSlice.actions

export default authSlice.reducer
