import { useFormContext } from "react-hook-form"
import { isValid, parse, isDate, parseISO } from "date-fns"
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Input,
  InputLeftAddon,
  Button,
  useDisclosure,
  InputRightElement,
} from "@chakra-ui/react"
import { KnownIcon } from "../../widgets/KnownIcon"
import { DateModal } from "../DateModal"
import { formatDateOnly } from "../../../app/utils"

interface DateFieldProps extends FieldProps { }

type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

export function DateField({
  label,
  name,
  helper,
  registerOptions,
}: DateFieldProps) {
  const { register, formState, setValue, getValues, watch } = useFormContext()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dateValue = watch(name)

  const validateDate = (value: string | undefined) => {
    if (!value) {
      return "Please enter a date in MM/DD/YYYY format"
    }
    if (isDate(value) && isValid(new Date(value))) {
      return undefined // Valid date
    }
    if (typeof value === 'string' && value.trim() !== '') {
      // If the value is a string (e.g., initial UTC string), parse and format it
      const parsedValue = parseISO(value);
      if (isDate(parsedValue) && isValid(parsedValue)) {
        return parsedValue; // Format the parsed date to MM/DD/YYYY format
      }
    }

    return "Invalid date MM/DD/YYYY format"
  }

  // Default registration options
  const defaultRegisterOptions = {
    required: "Please Enter a Date",
    validate: {
      validDate: validateDate,
    },
    setValueAs: (value: any) => {

      if (isDate(value) && isValid(value)) {
        return value
      }
      const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/
      if (dateRegex.test(value)) {
        const parsedValue = parse(value, "MM/dd/yyyy", new Date())
        if (isDate(parsedValue) && isValid(parsedValue)) {
          return parsedValue
        }
      }
      return value
    },
  }

  // Merge provided registerOptions with default options
  const mergedRegisterOptions = {
    ...defaultRegisterOptions,
    ...registerOptions,
  }
  const handlePickDate = (value: Value) => {

    if (value instanceof Date) {
      const formattedDate = formatDateOnly(value) // Format the parsed date to MM/DD/YYYY format
      setValue(name, formattedDate)
    }
    onClose()
  }

  const transformInput = (value: any) => {
    if (isDate(value) && isValid(value)) {
      return formatDateOnly(value); // Format the date to MM/DD/YYYY format
    } else if (typeof value === 'string' && value.trim() !== '') {
      // If the value is a string (e.g., initial UTC string), parse and format it
      const parsedValue = parseISO(value);
      if (isDate(parsedValue) && isValid(parsedValue)) {
        return formatDateOnly(parsedValue); // Format the parsed date to MM/DD/YYYY format
      }
    } else {
      // return formatDateOnly(new Date())
      if (dateValue === undefined) {
        return formatDateOnly(new Date());
      }
    }
    return value;
  }

  return (
    <FormControl isInvalid={!!formState.errors?.[name]} isRequired>
      <FormLabel size="sm" fontWeight={600}>{label}</FormLabel>
      <InputGroup>
        <InputLeftAddon>
          <KnownIcon name="calendar" />
        </InputLeftAddon>
        <Input
          {...register(name, mergedRegisterOptions)}
          placeholder="Pick a date"
          value={transformInput(dateValue || "")}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={onOpen}>
            Pick
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>
        {formState.errors?.[name]?.message?.toString()}
      </FormErrorMessage>
      <DateModal
        isOpen={isOpen}
        onClose={onClose}
        title="Select a Date"
        initialDate={getValues(name)} // Pass the current value of the input box as initialDate
        onPick={handlePickDate}
        onCancel={onClose}
      >
        (...)
      </DateModal>
    </FormControl>
  )
}
