import { createSlice, createEntityAdapter } from "@reduxjs/toolkit"
import { components } from "../../app/api/schema"
import { equipmentsApi } from "./equipmentsApi"
import { RootState } from "../../app/store"

type EquipmentResource = components["schemas"]["Equipment"]

const equipmentsAdapter = createEntityAdapter<EquipmentResource>({
  selectId: (equipment) => equipment.id,
})

const initialState = equipmentsAdapter.getInitialState({
  status: "idle",
  error: null,
})

const equipmentsSlice = createSlice({
  name: "equipments",
  initialState,
  reducers: {
    // Reducer functions will go here if needed
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      equipmentsApi.endpoints.listEquipments.matchFulfilled,
      (state, action) => {
        equipmentsAdapter.setAll(state, action.payload)
      },
    )
    builder.addMatcher(
      equipmentsApi.endpoints.createEquipment.matchFulfilled,
      (state, action) => {
        equipmentsAdapter.addOne(state, action.payload)
      },
    )
    builder.addMatcher(
      equipmentsApi.endpoints.retrieveEquipment.matchFulfilled,
      (state, action) => {
        equipmentsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      equipmentsApi.endpoints.updateEquipment.matchFulfilled,
      (state, action) => {
        equipmentsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      equipmentsApi.endpoints.partialUpdateEquipment.matchFulfilled,
      (state, action) => {
        equipmentsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      equipmentsApi.endpoints.destroyEquipment.matchFulfilled,
      (state, action) => {
        equipmentsAdapter.removeOne(state, action.meta.arg.originalArgs)
      },
    )
  },
})

export default equipmentsSlice.reducer

export const {
  selectAll: selectAllEquipments,
  selectById: selectEquipmentById,
  selectIds: selectEquipmentIds,
  selectEntities: selectEquipmentEntities,
  selectTotal: selectTotalEquipments,
} = equipmentsAdapter.getSelectors<RootState>((state) => state.equipments)
