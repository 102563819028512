import { format, parseISO } from "date-fns"

import { Stream } from "../../components/Stream"
import { Strip } from "../../components/Strip"
import { StripLine } from "../../components/StripLine"
import { StripLabel } from "../../components/StripLabel"
import { StripIcon } from "../../components/StripIcon"
import { useListingPaginatedTicketDataQuery } from "./ticketsApi"
import { Box, Flex, Text } from "@chakra-ui/react"
import { PuffLoader } from "react-spinners"

interface LatesTicketsProps {
  limit: number
}

export function LatestTickets({ limit }: LatesTicketsProps) {

  const { data, isError, isLoading } = useListingPaginatedTicketDataQuery(`paginate=${limit}`)
  const formatDate = (date: string | undefined) => {
    return date
      ? format(parseISO(date), "MMMM d, yyyy")
      : null
  }

  return (
    isError ? (
      <Box w="full" textAlign="center" >
        <p>Unable to fetch tickets. Please try again later.</p>
      </Box>
    ) : (
      <>
        <Stream title="Latest Tickets">
          {isLoading ? (
            <Flex w="full" justifyContent="center" alignItems="center">
              <PuffLoader
                color="#3D82CE"
                size={80}
              />
            </Flex>
          ) : (
            <>
              {
                data?.tickets.length !== 0 ?
                  data?.tickets.map((ticket, index: number) => (
                    <Strip key={index} condensed>
                      <StripLine>
                        <StripLabel>{ticket?.customer}</StripLabel>
                        <StripLabel right>{formatDate(ticket?.reference_date)}</StripLabel>
                        <StripIcon name="link" />
                      </StripLine>
                      <StripLine fade>
                        <StripLabel>{ticket?.consignee_name}</StripLabel>
                        <StripLabel right>Actual Volume {ticket.actual_volume}</StripLabel>
                        <StripIcon name="cube" />
                      </StripLine>
                    </Strip>
                  )) :
                  <Text>You do not have any tickets available to view</Text>
              }
            </>
          )}
        </Stream>
      </>
    )
  )
}


