import { useState } from 'react'
import { DraftChargeValues } from './draftSlice'
import { Button, ButtonGroup, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, WrapItem, useMediaQuery } from "@chakra-ui/react";
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { ModalForm } from '../../components/forms/ModalForm';
import { ChargesForm } from '../charges/ChargesForm';
import { useParams } from 'react-router-dom';

interface DraftChargePanelOverviewProps {
    draftCharges: DraftChargeValues[]
    onSave(data: any): void
    onRemove(index: number): void
}

function DraftChargePanelOverview({ draftCharges, onSave, onRemove }: DraftChargePanelOverviewProps) {
    const { id } = useParams()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
    const modalSize = isLargerThanMobile ? "lg" : "full"
    const [defaultValues, setDefaultValues] = useState(null)
    const [isSurCharge, setSurcharge] = useState<boolean>(false)

    const handleAddButton = () => {
        setSurcharge && setSurcharge(false)
        setDefaultValues(null)
        setIsModalOpen(true)
    }
    const handleOpen = () => {
        setIsModalOpen(true)
    }
    const handleClose = () => {
        setIsModalOpen(false)
    }
    return (
        <>
            {
                draftCharges.length !== 0 ?
                    <div style={{ maxWidth: "calc(100vw - 100px)", width: "100%", overflowX: "auto" }}>
                        <Table size={["sm"]}>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Quantity</Th>
                                    <Th>Unit</Th>
                                    <Th>Rate</Th>
                                    <Th>Amount</Th>
                                    <Th> </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {draftCharges?.map((item, index) => (
                                    <Tr key={index} h="50px">
                                        <Td>{item?.description}</Td>
                                        <Td>{item?.quantity}</Td>
                                        <Td>{item?.unit}</Td>
                                        <Td>{item?.rate}</Td>
                                        <Td>{item?.amount}</Td>
                                        <Td>
                                            <WrapItem gap={2} verticalAlign="center">
                                                <IconButton
                                                    aria-label="Remove"
                                                    icon={<KnownIcon name="minus" />}
                                                    onClick={() => onRemove(index)}
                                                    size={["xs", "sm", "md"]}
                                                />
                                                {
                                                    item?.allow_surcharge === true &&
                                                    <IconButton
                                                        aria-label="Add_Surcharge"
                                                        icon={<KnownIcon name="percentage" />}
                                                        size={["xs", "sm", "md"]}
                                                        onClick={() => {
                                                            handleOpen()
                                                            const data = {
                                                                description: `Fuel Surcharge (${item?.description})`,
                                                                unit: "%",
                                                                rate: item.amount,
                                                                allow_surcharge: false
                                                            }
                                                            setDefaultValues(data as any)
                                                            setSurcharge && setSurcharge(true)
                                                        }
                                                        }
                                                    />
                                                }
                                            </WrapItem>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </div>
                    :
                    <Text>{"No charges have been added."}</Text>
            }
            {/* Button to open the dialog for adding new entries */}
            <ButtonGroup width="full" mt={3} flex="row" justifyContent="end">
                <Button colorScheme="gray" onClick={handleAddButton} size={["sm", "md"]}>
                    Add New Charge
                </Button>
            </ButtonGroup>
            {/* Dialog for adding new charge entry */}
            <ModalForm
                title="Add New Charge"
                isOpen={isModalOpen}
                size={modalSize}
                onClose={handleClose}
                onCancel={handleClose}
                onSave={onSave}
                defaultValues={defaultValues}
            >
                <ChargesForm isSurCharge={isSurCharge} />
            </ModalForm>
        </>
    )
}

export default DraftChargePanelOverview
