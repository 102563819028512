import { useAppSelector } from "../../app/hooks"
import { AutocompleteField } from "../../components/forms/fields/AutocompleteField"
import { selectAllTerminals } from "./terminalsSlice"

interface TerminalAutocompleteProps extends FieldProps {}

export function TerminalAutocomplete({
  label,
  name,
  registerOptions,
  helper,
}: TerminalAutocompleteProps) {
  const terminals = useAppSelector(selectAllTerminals)
  const suggestions = terminals.map((terminal) => ({
    value: terminal.id,
    text: terminal.name,
  }))

  return (
    <AutocompleteField
      label={label}
      name={name}
      suggestions={suggestions}
      registerOptions={registerOptions}
      helper={helper}
    />
  )
}
