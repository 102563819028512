import { bolmintApi } from "../../app/api"
import { operations } from "../../app/api/schema"

export const timeEntriesApi = bolmintApi.injectEndpoints({
  endpoints: (builder) => ({
    listTimeEntries: builder.query<
      operations["timeentries_list"]["responses"]["200"]["content"]["application/json"],
      any
    >({
      query: (ticketId?) => ({
        url: ticketId ? `/timeentries/?ticket=${ticketId}` : `/timeentries/`,
      }),
      providesTags: ["time_entries"]
    }),
    createTimeEntry: builder.mutation<
      operations["timeentries_create"]["responses"]["201"]["content"]["application/json"],
      any
    >({
      query: (resource) => ({
        url: "/timeentries/",
        method: "POST",
        body: resource,
      }),
      invalidatesTags: ["time_entries"]
    }),
    retrieveTimeEntry: builder.query<
      operations["timeentries_retrieve"]["responses"]["200"]["content"]["application/json"],
      number
    >({
      query: (timeEntryId) => ({
        url: `/timeentries/${timeEntryId}/`,
      }),
    }),
    updateTimeEntry: builder.mutation<
      operations["timeentries_update"]["responses"]["200"]["content"]["application/json"],
      operations["timeentries_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/timeentries/${resource.id}/`,
        method: "PUT",
        body: resource,
      }),
    }),
    partialUpdateTimeEntry: builder.mutation<
      operations["timeentries_partial_update"]["responses"]["200"]["content"]["application/json"],
      operations["timeentries_partial_update"]["requestBody"]["content"]["application/json"]
    >({
      query: ({ id, ...data }) => ({
        url: `/timeentries/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["time_entries"]
    }),
    destroyTimeEntry: builder.mutation<void, number>({
      query: (timeEntryId) => ({
        url: `/timeentries/${timeEntryId}/`,
        method: "DELETE",
      }),
    }),
    driverTimeEntry: builder.query<any, string>({
      query: (url) => ({
        url: `/timeentries/daily_summary?${url}`,
      }),
      providesTags: ["time_entries"]
    }),
    paginatedTimeEntries: builder.query<any, string>({
      query: (url) => ({
        url: `timeentries/?${url}`,
      }),
      providesTags: ["time_entries"]
    }),
  }),
})

export const {
  useListTimeEntriesQuery,
  useCreateTimeEntryMutation,
  useRetrieveTimeEntryQuery,
  useUpdateTimeEntryMutation,
  usePartialUpdateTimeEntryMutation,
  useDestroyTimeEntryMutation,
  useDriverTimeEntryQuery,
  usePaginatedTimeEntriesQuery,
} = timeEntriesApi
