import {
  Button,
  ButtonGroup,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react"

import { Panel, PanelProps } from "../Panel"
import { FormPanelDisplayProps } from "./FormPanelDisplay"
import { FormPanelControlsProps } from "./FormPanelControls"
import { ModalForm } from "./ModalForm"
import { useState } from "react"
import { useNetworkCheck } from "../../app/hooks"
import { useLocation } from "react-router-dom"

interface FormPanelProps extends Omit<PanelProps, "context"> {
  defaultValues?: any
  onSave(data: any): void
  children: [
    React.ReactElement<FormPanelDisplayProps>,
    React.ReactElement<FormPanelControlsProps>,
  ]
}

export function FormPanel({
  defaultValues,
  onSave,
  children,
  ...panelProps
}: FormPanelProps) {
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure()
  const location = useLocation().pathname
  const [context, setContext] = useState(null)
  const { isOnline } = useNetworkCheck()
  const [display, controls] = children
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
  const modalSize = isLargerThanMobile ? "lg" : "full"

  const handleSave = (data: any) => {
    console.log("Save", data)
    closeModal()
    setContext(data)
    onSave(data)
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <Panel context={context} {...panelProps}>
      {display}
      <ButtonGroup width="full" mt={3} flex="row" justifyContent="end">
        <Button onClick={openModal} size={["sm", "md"]} isDisabled={!location.includes("draft") && !isOnline ? true : false}>Modify</Button>
      </ButtonGroup>
      <ModalForm
        title={`Modify ${panelProps.title}`}
        defaultValues={defaultValues}
        isOpen={isModalOpen}
        size={modalSize}
        onClose={handleCancel}
        onCancel={handleCancel}
        onSave={handleSave}
      >
        {controls}
      </ModalForm>
    </Panel>
  )
}
