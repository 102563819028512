import { HStack, Box, Text } from "@chakra-ui/react"

interface InputStreakProps extends FieldInfoProps {
  children: React.ReactElement[] | React.ReactElement
}

export function InputStreak({ label, children }: InputStreakProps) {
  return (
    <Box w="full">
      <Text size="sm" fontWeight={600}>
        {label}
      </Text>
      <HStack align="left" gap={5} w="full">
        {children}
      </HStack>
    </Box>
  )
}
