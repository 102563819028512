import { FormPanel } from "../../components/forms/FormPanel"
import { FormPanelControls } from "../../components/forms/FormPanelControls"
import { FormPanelDisplay } from "../../components/forms/FormPanelDisplay"
import { BasicForm } from "./BasicForm"
import { BasicDisplay } from "./BasicDisplay"

interface BasicPanelProps {
  defaultValues: any
  onSave(data: any): void
  onRemove?(index: number): void
}

export function BasicPanel({ defaultValues, onSave, onRemove }: BasicPanelProps) {
  return (
    <FormPanel
      title="General Information"
      defaultValues={defaultValues}
      onSave={onSave}
    >
      <FormPanelDisplay>
        <BasicDisplay draftBasic={defaultValues ?? null} />
      </FormPanelDisplay>
      <FormPanelControls>
        <BasicForm onRemove={onRemove} />
      </FormPanelControls>
    </FormPanel>
  )
}
