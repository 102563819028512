import { bolmintApi } from "../../app/api"
import { operations } from "../../app/api/schema"

export const productsApi = bolmintApi.injectEndpoints({
  endpoints: (builder) => ({
    listProducts: builder.query<
      operations["products_list"]["responses"]["200"]["content"]["application/json"],
      void
    >({
      query: () => ({
        url: "/products/",
      }),
    }),
    createProduct: builder.mutation<
      operations["products_create"]["responses"]["201"]["content"]["application/json"],
      Partial<
        operations["products_create"]["requestBody"]["content"]["application/json"]
      >
    >({
      query: (resource) => ({
        url: "/products/",
        method: "POST",
        body: resource,
      }),
    }),
    retrieveProduct: builder.query<
      operations["products_retrieve"]["responses"]["200"]["content"]["application/json"],
      number
    >({
      query: (productId) => ({
        url: `/products/${productId}/`,
      }),
    }),
    updateProduct: builder.mutation<
      operations["products_update"]["responses"]["200"]["content"]["application/json"],
      operations["products_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/products/${resource.id}/`,
        method: "PUT",
        body: resource,
      }),
    }),
    partialUpdateProduct: builder.mutation<
      operations["products_partial_update"]["responses"]["200"]["content"]["application/json"],
      operations["products_partial_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/products/${resource.id}/`,
        method: "PATCH",
        body: resource,
      }),
    }),
    destroyProduct: builder.mutation<void, number>({
      query: (productId) => ({
        url: `/products/${productId}/`,
        method: "DELETE",
      }),
    }),
  }),
})

export const {
  useListProductsQuery,
  useCreateProductMutation,
  useRetrieveProductQuery,
  useUpdateProductMutation,
  usePartialUpdateProductMutation,
  useDestroyProductMutation,
} = productsApi
