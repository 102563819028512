import { WrapItem } from "@chakra-ui/react"
import { ReadonlyField } from "../../components/forms/fields/ReadonlyField"
import { ServiceTrait } from "../services/ServiceTrait"
import { DraftServicesValues } from "./draftSlice"

interface ServicesDisplayProps {
  entry?: DraftServicesValues
}

export function ServicesDisplay({ entry }: ServicesDisplayProps) {
  return (
    <>
      <WrapItem flexGrow={1}>
        <ReadonlyField label="Name">
          <ServiceTrait id={entry?.service} />
        </ReadonlyField>
      </WrapItem>
      <WrapItem>
        <ReadonlyField label="Quantity">{entry?.quantity || "-"}</ReadonlyField>
      </WrapItem>
      <WrapItem>
        <ReadonlyField label="Description">
          {entry?.description || "-"}
        </ReadonlyField>
      </WrapItem>
    </>
  )
}
