import { useTrait } from "../../app/hooks"
import { TraitLine } from "../../components/TraitLine"
import { selectCustomerById } from "./customersSlice"

interface CustomerTraitProps extends TraitProps { }

export function CustomerTrait({ id }: CustomerTraitProps) {
  const { trait, isNew } = useTrait(
    (customer) => `${customer.name}`,
    id,
    selectCustomerById,
  )

  return <TraitLine isNew={isNew}>{trait}</TraitLine>
}
