import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, InputGroup } from '@chakra-ui/react'
import { RegisterOptions, useFormContext } from 'react-hook-form'


interface InputFieldProps {
    label?: string
    name: string
    registerOptions?: RegisterOptions
    helper?: string
    readOnly?: boolean
}

function EmailField({
    label,
    name,
    registerOptions,
    helper,
    readOnly,
}: InputFieldProps) {
    const { register, formState } = useFormContext()
    const validateEmail = (value: string | undefined) => {
        if (value) {
            const isValiEmail = value && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
            if (isValiEmail) {
                return undefined
            } else {
                return "Invalid Email format."
            }
        }
    }

    // Default registration options
    const defaultRegisterOptions = {
        validate: {
            validateEmail: validateEmail,
        },
    }

    // Merge provided registerOptions with default options
    const mergedRegisterOptions = {
        ...defaultRegisterOptions,
        ...registerOptions,
    }

    return (
        <FormControl isInvalid={!!formState.errors?.[name]}>
            <FormLabel size="sm" fontWeight={600}>{label}</FormLabel>
            <InputGroup>
                <Input
                    {...register(name, mergedRegisterOptions)}
                    placeholder="Write it here..."
                    readOnly={readOnly}
                    type='email'
                />
            </InputGroup>
            <FormHelperText>{helper}</FormHelperText>
            <FormErrorMessage>{formState.errors?.[name]?.message?.toString()}</FormErrorMessage>
        </FormControl>
    )
}

export default EmailField
