import { TerminalAutocomplete } from "./TerminalAutocomplete"
import { LocationAutocomplete } from "./LocationAutocomplete"
import { LsdField } from "../../components/forms/fields/LsdField"
import { InputStreak } from "../../components/forms/InputStreak"
import { LsdPrefixField } from "../../components/forms/fields/LsdPrefixField"
import { useFormContext } from "react-hook-form"
import { Heading } from "@chakra-ui/react"
import { useLocation } from "react-router-dom"

interface ConsigneeFormProps {
  title?: string
}

export function ConsigneeForm({ title }: ConsigneeFormProps) {
  const { watch } = useFormContext()
  const location = useLocation().pathname
  // const terminalId = watch(location.includes("edit") ? "terminal" : "consignee.terminal")
  const terminalId = watch("consignee.terminal")

  return (
    <>
      <Heading as="h6" fontSize="large" alignSelf="start" >
        {title}
      </Heading>
      <TerminalAutocomplete
        label="Company Name"
        // name={location.includes("edit") ? "terminal" : "consignee.terminal"}
        name={"consignee.terminal"}
        helper="Pick the consignee who is receiving the shipment"
      // registerOptions={{ required: "Please Enter Company Name" }}
      />
      <InputStreak
        label="LSD/Well/Battery"
        helper="Enter an LSD and its prefix"
      >
        {/* <LsdPrefixField name={location.includes("edit") ? "lsdprefix" : "consignee.lsdprefix"} helper="Enter LSD prefix" /> */}
        <LsdPrefixField name={"consignee.lsdprefix"} helper="Enter LSD prefix" />
        {/* <LsdField name={location.includes("edit") ? "lsd" : "consignee.lsd"} helper="Enter an LSD" /> */}
        <LsdField name={"consignee.lsd"} helper="Enter an LSD" />
      </InputStreak>
      <LocationAutocomplete
        label="Destination Point"
        // name={location.includes("edit") ? "location" : "consignee.location"}
        name={"consignee.location"}
        terminalId={terminalId}
        helper="Enter the location name of where the shipment was delivered"
      // registerOptions={{ required: "Please Enter Destination Point" }}
      />
    </>
  )
}
