import { Box, Flex } from "@chakra-ui/react"

interface StripProps {
  condensed?: boolean
  children: React.ReactNode
}

export function Strip({ condensed = false, children }: StripProps) {
  return (
    <Box w="full" fontSize="sm">
      <Flex direction="column" gap={condensed ? 2 : 6}>
        {children}
      </Flex>
    </Box>
  )
}
