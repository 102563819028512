import { bolmintApi } from "../../app/api"
import { operations } from "../../app/api/schema"

export const chargesApi = bolmintApi.injectEndpoints({
  endpoints: (builder) => ({
    listChargesByTicketId: builder.query<
      operations["charges_list"]["responses"]["200"]["content"]["application/json"],
      number
    >({
      query: (ticketId) => ({
        url: `/charges/?ticket_id=${ticketId}`,
      }),
    }),
    createCharge: builder.mutation<
      operations["charges_create"]["responses"]["201"]["content"]["application/json"],
      any
    >({
      query: (resource) => ({
        url: "/charges/",
        method: "POST",
        body: resource,
      }),
      invalidatesTags: ['ticket']
    }),
    retrieveCharge: builder.query<
      operations["charges_retrieve"]["responses"]["200"]["content"]["application/json"],
      number
    >({
      query: (chargeId) => ({
        url: `/charges/${chargeId}/`,
      }),
    }),
    updateCharge: builder.mutation<
      operations["charges_update"]["responses"]["200"]["content"]["application/json"],
      operations["charges_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/charges/${resource.id}/`,
        method: "PUT",
        body: resource,
      }),
    }),
    partialUpdateCharge: builder.mutation<
      operations["charges_partial_update"]["responses"]["200"]["content"]["application/json"],
      operations["charges_partial_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/charges/${resource.id}/`,
        method: "PATCH",
        body: resource,
      }),
    }),
    destroyCharge: builder.mutation<void, number>({
      query: (chargeId) => ({
        url: `/charges/${chargeId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ['ticket']
    }),
    destroyChargesByTicket: builder.mutation<
      void,
      number>({
        query: (ticketId) => ({
          url: `/charges/${ticketId}/delete_charges_by_ticket/`,
          method: "DELETE",
        }),
        invalidatesTags: ['ticket']
      }),
  }),
})

export const {
  useListChargesByTicketIdQuery,
  useCreateChargeMutation,
  useRetrieveChargeQuery,
  useUpdateChargeMutation,
  usePartialUpdateChargeMutation,
  useDestroyChargeMutation,
  useDestroyChargesByTicketMutation,
} = chargesApi
