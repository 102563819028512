import React from 'react'
import { DraftLocationsValues } from '../draft/draftSlice'
import { ConsignorDisplay } from '../terminals/ConsignorDisplay'
import { ConsigneeDisplay } from '../terminals/ConsigneeDisplay'
import { Divider } from '@chakra-ui/react'

interface LocationsDesplayProps {
    defaultValues: DraftLocationsValues
}

function LocationsDisplay({ defaultValues }: LocationsDesplayProps) {
    return (
        <>
            <ConsignorDisplay title="Consignor / From" draftConsignor={defaultValues?.consignor ?? null} />
            <Divider orientation='horizontal' borderColor='blue.200' />
            <ConsigneeDisplay title="Consignee / To" draftConsignee={defaultValues?.consignee ?? null} />
        </>
    )
}

export default LocationsDisplay
