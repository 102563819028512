import React from 'react'
import { FormPanel } from '../../components/forms/FormPanel'
import { FormPanelDisplay } from '../../components/forms/FormPanelDisplay'
import { FormPanelControls } from '../../components/forms/FormPanelControls'
import { DroppOffForm } from './DroppOffForm'
import { DroppOffDisplay } from './DroppOffDisplay'


interface DroppOffPanelProps {
    defaultValues: any
    onSave(data: any): void
}

export function DroppOffPanel({ defaultValues, onSave }: DroppOffPanelProps) {
    return (
        <FormPanel
            title="Unload"
            onSave={onSave}
            defaultValues={defaultValues}>
            <FormPanelDisplay>
                <DroppOffDisplay draftExtra={defaultValues} />
            </FormPanelDisplay>
            <FormPanelControls>
                <DroppOffForm />
            </FormPanelControls>
        </FormPanel>
    )
}

