import { bolmintApi } from "../../app/api"
import { operations } from "../../app/api/schema"

export const equipmentsApi = bolmintApi.injectEndpoints({
  endpoints: (builder) => ({
    listEquipments: builder.query<
      operations["equipments_list"]["responses"]["200"]["content"]["application/json"],
      void
    >({
      query: () => ({
        url: "/equipments/",
      }),
    }),
    createEquipment: builder.mutation<
      operations["equipments_create"]["responses"]["201"]["content"]["application/json"],
      operations["equipments_create"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: "/equipments/",
        method: "POST",
        body: resource,
      }),
    }),
    retrieveEquipment: builder.query<
      operations["equipments_retrieve"]["responses"]["200"]["content"]["application/json"],
      number
    >({
      query: (equipmentId) => ({
        url: `/equipments/${equipmentId}/`,
      }),
    }),
    updateEquipment: builder.mutation<
      operations["equipments_update"]["responses"]["200"]["content"]["application/json"],
      operations["equipments_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/equipments/${resource.id}/`,
        method: "PUT",
        body: resource,
      }),
    }),
    partialUpdateEquipment: builder.mutation<
      operations["equipments_partial_update"]["responses"]["200"]["content"]["application/json"],
      operations["equipments_partial_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/equipments/${resource.id}/`,
        method: "PATCH",
        body: resource,
      }),
    }),
    destroyEquipment: builder.mutation<void, number>({
      query: (equipmentId) => ({
        url: `/equipments/${equipmentId}/`,
        method: "DELETE",
      }),
    }),
  }),
})

export const {
  useListEquipmentsQuery,
  useCreateEquipmentMutation,
  useRetrieveEquipmentQuery,
  useUpdateEquipmentMutation,
  usePartialUpdateEquipmentMutation,
  useDestroyEquipmentMutation,
} = equipmentsApi
