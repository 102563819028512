import { FocusView } from "../../layout/views/FocusView"
import { SessionLogin } from "../../features/session/SessionLogin"

export function LoginPage() {
  return (
    <FocusView>
      <SessionLogin />
    </FocusView>
  )
}
