import { Box, Heading } from "@chakra-ui/react"
import { InputStreak } from "../../components/forms/InputStreak"
import { ReadonlyField } from "../../components/forms/fields/ReadonlyField"
import { LocationTrait } from "./LocationTrait"
import { TerminalTrait } from "./TerminalTrait"

interface ConsigneeDisplayProps {
  draftConsignee: DraftConsigneeValues | null
  title?: string
}

export function ConsigneeDisplay({ draftConsignee, title }: ConsigneeDisplayProps) {
  return (
    <>
      <Heading as="h6" fontSize="large" alignSelf="start" w="full" >
        {title}
      </Heading>
      <ReadonlyField
        label="Company Name"
        helper="Consignee receiving the shipment"
      // isRequired={true}
      >
        {draftConsignee?.terminal ? (
          <TerminalTrait id={draftConsignee?.terminal} />
        ) : (
          "--"
        )}
      </ReadonlyField>
      <InputStreak
        label="LSD/Well/Battery"
        helper="Enter an LSD and its prefix"
      >
        <Box width={"25%"}>
          <ReadonlyField helper="LSD prefix">
            {draftConsignee?.lsdprefix ? (
              <LocationTrait
                terminalId={draftConsignee.terminal}
                id={draftConsignee.lsdprefix}
              />
            ) : (
              "--"
            )}
          </ReadonlyField>
        </Box>
        <Box width={"75%"}>
          <ReadonlyField helper="Well or battery LSD location">
            {draftConsignee?.lsd ? (
              <LocationTrait
                terminalId={draftConsignee?.terminal}
                id={draftConsignee?.lsd}
              />
            ) : (
              "--"
            )}
          </ReadonlyField>
        </Box>
      </InputStreak>
      <ReadonlyField
        label="Destination Point"
        helper="Consignee location"
      // isRequired={true}
      >
        {draftConsignee?.location ? (
          <LocationTrait
            terminalId={draftConsignee?.terminal}
            id={draftConsignee?.location}
          />
        ) : (
          "--"
        )}
      </ReadonlyField>
    </>
  )
}
