import { TerminalAutocomplete } from "./TerminalAutocomplete"
import { LocationAutocomplete } from "./LocationAutocomplete"
import { LsdField } from "../../components/forms/fields/LsdField"
import { InputStreak } from "../../components/forms/InputStreak"
import { LsdPrefixField } from "../../components/forms/fields/LsdPrefixField"
import { useFormContext } from "react-hook-form"
import { Heading } from "@chakra-ui/react"
import { useLocation } from "react-router-dom"

interface ConsignorFormProps {
  title?: string
}

export function ConsignorForm({ title }: ConsignorFormProps) {
  const { watch } = useFormContext()
  const location = useLocation().pathname
  // const terminalId = watch(location.includes("edit") ? "terminal" : "consignor.terminal");
  const terminalId = watch("consignor.terminal");

  return (
    <>
      <Heading as="h6" fontSize="large" alignSelf="start" >
        {title}
      </Heading>
      <TerminalAutocomplete
        label="Company Name"
        // name={location.includes("edit") ? "terminal" : "consignor.terminal"}
        name={"consignor.terminal"}
        helper="Pick the consignor who is sending the shipment"
      // registerOptions={{ required: "Please Enter Company Name" }}
      />
      <InputStreak
        label="LSD/Well/Battery"
        helper="Enter an LSD and its prefix"
      >
        {/* <LsdPrefixField name={location.includes("edit") ? "lsdprefix" : "consignor.lsdprefix"} helper="Enter LSD prefix" /> */}
        <LsdPrefixField name={"consignor.lsdprefix"} helper="Enter LSD prefix" />
        {/* <LsdField name={location.includes("edit") ? "lsd" : "consignor.lsd"} helper="Enter an LSD" /> */}
        <LsdField name={"consignor.lsd"} helper="Enter an LSD" />
      </InputStreak>
      <LocationAutocomplete
        label="Point of Origin"
        // name={location.includes("edit") ? "location" : "consignor.location"}
        name={"consignor.location"}
        terminalId={terminalId}
        helper="Enter the location name of where the shipment was received"
      // registerOptions={{ required: "Please Enter Point of Origin" }}
      />
    </>
  )
}
