import { Box, Heading, Text } from "@chakra-ui/react"

interface ViewHeaderProps {
  children: React.ReactNode
  subtitle?: string
}

export function ViewHeader({ children, subtitle }: ViewHeaderProps) {
  return (
    <Box w="full">
      <Heading fontSize={["lg", "2xl"]}>{children}</Heading>
      {subtitle && <Text>{subtitle}</Text>}
    </Box>
  )
}
