import { Params, useNavigate, useParams } from "react-router-dom"
import { DetailView } from "../../layout/views/DetailView"
import { useRetrieveTicketQuery, } from "../../features/tickets/ticketsApi"
import { ErrorBox } from "../../components/ErrorBox"
import { Box, Button, ButtonGroup } from "@chakra-ui/react"
import { useAppDispatch, useAppSelector, useNetworkCheck } from "../../app/hooks"
import {
  removeEquipment,
  removeProductDetail,
  removeServiceDetail,
  removeTicketTankGaugeRecord,
  selectTicketBasic,
  selectTicketDropOffDetail,
  selectTicketLocations,
  selectTicketPickUpDetail,
  selectTicketProducts,
  selectTicketServices,
  updateAttachments,
  updateTicketBasic,
  updateTicketDropOffDetail,
  updateTicketLocations,
  updateTicketPickUpDetail,
  updateTicketProductDetails,
  updateTicketServiceDetails,
} from "../../features/tickets/ticketsSlice"
import { BasicPanel } from "../../features/tickets/BasicPanel"
import { useListTerminalsQuery } from "../../features/terminals/terminalsApi"
import { Panel } from "../../components/Panel"
import { AttachmentField } from "../../components/forms/fields/AttachmentField"
import { ProductsPanel } from "../../features/draft/ProductsPanel"
import { ServicesPanel } from "../../features/draft/ServicesPanel"
import { KnownIcon } from "../../components/widgets/KnownIcon"
import { DraftLocationsPanel } from "../../features/locations/DraftLocationsPanel"
import { PickUpPanel } from "../../features/tickets/PickUpPanel"
import { DroppOffPanel } from "../../features/tickets/DroppOffPanel"
import { useScrollTo } from "../../app/hooks"
import { useEffect, useState } from "react"

export function TicketEditPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isOnline } = useNetworkCheck()
  const { ticketId } = useParams<Params>()
  const [setRef, scrollTo] = useScrollTo()
  const [networkError, setNetworkError] = useState<string>('')
  useListTerminalsQuery()

  // Retrive ticket information
  const { data: ticket, isLoading, isError, error, isSuccess } = useRetrieveTicketQuery(
    Number(ticketId),
  )
  const handleBack = () => {
    isOnline ? navigate(`/user/tickets/${ticketId}`) : navigate("/user/home")
  }

  /**
   * Handle data for each panel
   */
  const ticketBasic = useAppSelector(selectTicketBasic(Number(ticketId)))
  const ticketPickUpDetails = useAppSelector(selectTicketPickUpDetail(Number(ticketId)))
  const ticketDropoffDetails = useAppSelector(selectTicketDropOffDetail(Number(ticketId)))
  const ticketProducts = useAppSelector(selectTicketProducts(Number(ticketId)))
  const ticketServices = useAppSelector(selectTicketServices(Number(ticketId)))
  const ticketLocations = useAppSelector(selectTicketLocations(Number(ticketId)))

  const handleBasicSave = (data: any) => {
    dispatch(updateTicketBasic({ id: ticketId, ...data }))
    scrollTo("LocationsForm")
  }
  const handleAttachmentSave = (data: any) => {
    dispatch(updateAttachments({ id: ticketId, attachments: [...data] }))
  }
  const handleProductSave = async (data: any) => {
    dispatch(updateTicketProductDetails({ id: Number(ticketId), ...data }))
  }
  const handleEquipmentRemove = async (index: number) => {
    const equipmentId = ticketBasic?.equipments && ticketBasic?.equipments?.[index]
    await dispatch(removeEquipment({ id: Number(ticketId), ticket_equipment_id: Number(equipmentId) }))
  }
  const handleProductRemove = async (index: number) => {
    const ticketProductId = ticket?.productdetails && ticket?.productdetails[index]?.ticket_product_id
    await dispatch(removeProductDetail({ id: Number(ticketId), ticket_product_id: Number(ticketProductId) }))
  }
  const handleServiceSave = async (data: any) => {
    dispatch(updateTicketServiceDetails({ id: Number(ticketId), ...data }))
  }
  const handleServiceRemove = (index: number) => {
    const ticketServiceId = ticket?.servicedetails && ticket?.servicedetails[index]?.ticket_service_id
    dispatch(removeServiceDetail({ id: Number(ticketId), ticket_service_id: Number(ticketServiceId) }))
  }

  const handleRemoveTankGaugeRecord = (index: number) => {
    const tank_gauge_id = ticket?.tank_gauge_details && ticket?.tank_gauge_details[index]?.id
    if (tank_gauge_id) {
      dispatch(removeTicketTankGaugeRecord({ id: Number(ticketId), tank_gauge_id: Number(tank_gauge_id) }))
    }
  }
  const handleLocationsSave = (data: any) => {
    dispatch(updateTicketLocations({ id: ticketId, ...data }))
    scrollTo("productForm")
  }
  const handlePickUpSave = (data: any) => {
    dispatch(updateTicketPickUpDetail({ id: ticketId, ...data }))
    scrollTo("dropOffForm")
  }
  const handleDroppOffSave = (data: any) => {
    dispatch(updateTicketDropOffDetail({ id: ticketId, ...data }))
    scrollTo("serviceForm")
  }

  useEffect(() => {
    if (error && 'status' in error && error.status === 'FETCH_ERROR') {
      setNetworkError("Please try again later. The data you are attempting to view is either unavailable or your network connection is unstable");
      window.scrollTo({ top: 0, behavior: "smooth" })
    } else if (isSuccess && ticket) {
      // Clear the error if data fetch is successful
      setNetworkError("");
    }
  }, [isError, isSuccess, ticket])

  return (
    <DetailView
      title={ticket?.number || "Unknown"}
      subtitle="Ticket Edit"
      isLoading={isLoading}
    >
      <ErrorBox show={isError} error={error} message={networkError} />
      <BasicPanel defaultValues={ticketBasic}
        onSave={handleBasicSave}
        onRemove={handleEquipmentRemove}
      />
      <Box w="full" ref={setRef('LocationsForm')}>
        <DraftLocationsPanel
          defaultValues={ticketLocations}
          onSave={handleLocationsSave}
        />
      </Box>
      <Box w="full" ref={setRef('productForm')}>
        <ProductsPanel
          defaultValues={ticketProducts}
          onSave={handleProductSave}
          onRemove={handleProductRemove}
        />
      </Box>

      <Box w="full" ref={setRef('pickUpForm')}>
        <PickUpPanel
          defaultValues={ticketPickUpDetails}
          onSave={handlePickUpSave}
          onRemove={handleRemoveTankGaugeRecord}
        />
      </Box>
      <Box w="full" ref={setRef('dropOffForm')}>
        <DroppOffPanel defaultValues={ticketDropoffDetails} onSave={handleDroppOffSave} />
      </Box>

      <Box w="full" ref={setRef('servicesForm')}>
        <ServicesPanel
          defaultValues={ticketServices}
          onSave={handleServiceSave}
          onRemove={handleServiceRemove}
        />
      </Box>

      {ticket?.number && isOnline && (
        <Panel title="Attachments">
          <AttachmentField
            draftId={ticket?.number ?? null}
            onSave={handleAttachmentSave}
          />
        </Panel>
      )}
      < ButtonGroup w="full" justifyContent="flex-end">
        <Button
          colorScheme="blue"
          onClick={handleBack}
          size={["xs", "sm", "md"]}
          leftIcon={<KnownIcon name="back" />}
        >
          {isOnline ? "Back" : "Home"}
        </Button>
      </ButtonGroup>
    </DetailView >
  )
}
