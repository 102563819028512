import { Box, IconButton, Text } from "@chakra-ui/react"
import { ReactNode, useEffect, useRef } from "react"
import { useFieldArray } from "react-hook-form"
import { KnownIcon } from "../widgets/KnownIcon"

interface FieldArrayProps extends FieldProps {
  children: (fieldName: string) => ReactNode
  onRemove?(index: number): void
  isValue?: number | null
  setIsValue(data: number | null): void
}

export function FieldArray({ label, name, children, onRemove, isValue, setIsValue }: FieldArrayProps) {
  const { fields, append, remove } = useFieldArray({ name })

  const appendedFirst = useRef(false)
  useEffect(() => {
    if (fields.length === 0 && !appendedFirst.current) {
      appendedFirst.current = true
      append(null)
    }
  }, [append, fields])

  const handleRemove = (index: number) => {
    remove(index)
    onRemove && onRemove(index)
  }
  const handleAdd = () => {
    append(null)
    setIsValue(null)
  }

  return (
    <Box w="full">
      <Text size="sm" fontWeight={600}>
        {label}
      </Text>
      {fields.map((field, index) => (
        <Box w="full" key={field.id} display="flex" alignItems="center" gap={2}>
          {children(`${name}[${index}]`)}
          {index !== fields.length - 1 ? (
            <IconButton
              aria-label="Remove"
              icon={<KnownIcon name="minus" />}
              onClick={() => handleRemove(index)}
            />
          ) : (
            <IconButton
              aria-label="Add"
              icon={<KnownIcon name="plus" />}
              onClick={() => handleAdd()}
              // isDisabled={item1.current !== null ? false : true}
              isDisabled={isValue !== null ? false : true}
            />
          )}
        </Box>
      ))}
    </Box>
  )
}
