import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Select,
} from "@chakra-ui/react"
import { useFormContext } from "react-hook-form"
import { useLocation } from "react-router-dom";

interface SelectFieldProps extends FieldProps {
  options: { value: number | string; label: string }[]
  isRequired?: boolean
  setIsValue?(data: number | null): void
}

export function SelectField({
  label,
  name,
  registerOptions,
  helper,
  options,
  isRequired,
  setIsValue
}: SelectFieldProps) {
  const { register, formState, watch, getValues } = useFormContext()
  const location = useLocation().pathname
  const defaultRegisterOptions = {
    required: isRequired ? "Please pick one" : false,
    valueAsNumber: options?.some((option) => typeof option.value === "number")
  }
  const mergedRegisterOptions = {
    ...defaultRegisterOptions,
    ...registerOptions,
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (setIsValue) {
      setIsValue?.(e.target.value ? Number(e.target.value) : null)
    }
    register(name, mergedRegisterOptions).onChange(e);
  }
  return (
    <FormControl isInvalid={!!formState.errors?.[name]} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Select
          {...register(name, mergedRegisterOptions)}
          placeholder="Pick One"
          {...(location.includes('user/draft') && name === "ticketDraft" && { value: options[0].value })}   // add this only when adding hours from draft page for only draftTicket selection
          onChange={(e) => handleChange(e)}
        >
          {options?.map((option, index) => {
            return <option key={index} value={option.value}>
              {option.label}
            </option>
          })}
        </Select>
      </InputGroup>
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>
        {formState.errors?.[name]?.message?.toString()}
      </FormErrorMessage>
    </FormControl>
  )
}
