import { bolmintApi } from "../../app/api";
import { operations } from "../../app/api/schema";


export const activitiesApi = bolmintApi.injectEndpoints({
    endpoints: (builder) => ({
        listActivities: builder.query<
            operations["activity_list"]["responses"]["200"]["content"]["application/json"],
            void
        >({
            query: () => ({
                url: `/fetch-all-activites/`,
            }),
        }),
    })
})

export const {
    useListActivitiesQuery
} = activitiesApi
