import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Input,
} from "@chakra-ui/react"
import { RegisterOptions, useFormContext } from "react-hook-form"

interface NumberFieldProps {
  label?: string
  name: string
  registerOptions?: RegisterOptions
  helper?: string
  readOnly?: boolean
}

export function NumberField({
  label,
  name,
  registerOptions,
  helper,
  readOnly
}: NumberFieldProps) {
  const { register, formState: { errors } } = useFormContext()

  const updatedRegisterOptions = {
    ...registerOptions,
    setValueAs: (value: string | number | undefined) => {
      if (value === "" || value == null) {
        return null;
      }
      return Number(value);
    }, // Convert empty string to null and others to numbers
  };
  return (
    <FormControl isInvalid={errors[name] !== undefined} isRequired={registerOptions?.required ? true : false}>
      <FormLabel size="sm" fontWeight={600}>{label}</FormLabel>
      <InputGroup>
        <Input {...register(name, updatedRegisterOptions)} type="number" placeholder="Write it here..." readOnly={readOnly} />
      </InputGroup>
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>{errors[name]?.message?.toString()}</FormErrorMessage>
    </FormControl>
  )
}
