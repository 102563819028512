import {
  setHours,
  setMinutes,
  format,
  parse,
  differenceInMinutes,
  parseISO,
  isDate,
} from "date-fns"
import * as yup from "yup"
import { components } from "../app/api/schema"

export function combineDateTime(date: Date | string, timeString: string) {
  let time: Date

  // Parse the time string into a Date object
  if (timeString.includes("AM") || timeString.includes("PM")) {
    time = parse(timeString, "h:mm a", new Date())
  } else {
    const [hours, minutes] = timeString.split(":")
    time = setHours(
      setMinutes(new Date(), parseInt(minutes, 10)),
      parseInt(hours, 10),
    )
  }

  const combinedDateTime = setHours(
    setMinutes(new Date(date), time.getMinutes()),
    time.getHours(),
  )
  const formattedDateTime = format(combinedDateTime, "yyyy-MM-dd'T'HH:mm:ssxxx")

  return formattedDateTime
}

export function formatMinutesAsHours(minutes?: number | null) {
  if (minutes) {
    return (minutes / 60.0).toFixed(2)
  } else {
    return 0
  }
}

export function formatDateOnly(dateTime?: string | Date | null) {
  if (dateTime instanceof Date) {
    return format(dateTime, "MM/dd/yyyy")
  } else if (dateTime) {
    return format(new Date(dateTime), "MM/dd/yyyy")
  } else {
    return ""
  }
}

export function formatTimeDateOnly(dateTime?: string | Date | null) {
  if (dateTime instanceof Date) {
    return format(dateTime, "MM/dd/yyyy hh:mm a")
  } else if (dateTime) {
    return format(new Date(dateTime), "MM/dd/yyyy hh:mm a")
  } else {
    return ""
  }
}

export function formatTimeOnly(dateTime?: string | Date | null) {
  if (dateTime instanceof Date) {
    return format(dateTime, "HH:mm a")
  } else if (dateTime) {
    return format(new Date(dateTime), "HH:mm a")
  } else {
    return ""
  }
}

export function calculateDeltaMinutes(
  time1: string | Date,
  time2: string | Date,
): number {
  let parsedTime1
  let parsedTime2
  if (typeof time1 === "string" && typeof time2 === "string") {
    if (time1.length === 8 && time2.length === 8) {
      // Assuming time1 and time2 are in "HH:mm AM" format
      parsedTime1 = parse(time1, "hh:mm a", new Date())

      parsedTime2 = parse(time2, "hh:mm a", new Date())
    } else {
      // Assuming time1 and time2 are in ISO datetime format
      parsedTime1 = parseISO(time1)
      parsedTime2 = parseISO(time2)
    }
  } else {
    parsedTime1 = isDate(time1) ? time1 : new Date(time1)
    parsedTime2 = isDate(time2) ? time2 : new Date(time2)
  }
  return differenceInMinutes(parsedTime2, parsedTime1)
}

export function getRole(string: string) {
  const userRoles = [
    { value: "customer", label: "Customer" },
    { value: "driver", label: "Driver" },
    { value: "driver-lead", label: "Driver Lead" },
    { value: "admin", label: "Admin" },
    { value: "finance-lead", label: "Finance Lead" },
  ]

  return userRoles.find((role) => role.value === string)?.label
}

export function capFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getDateString(string: string | Date) {
  let formattedDate
  if (typeof string === "string") {
    const date = parseISO(string)
    formattedDate = format(date, "MMMM d, yyyy")
  } else {
    const date = new Date(string)
    formattedDate = format(date, "yyyy-MM-dd")
  }
  return formattedDate
}

export function getDateTimeString(string: string) {
  let formattedDate
  const date = parseISO(string)
  formattedDate = format(date, "MMMM d, yyyy  hh:mm a")
  return formattedDate
}

export const getCharacterValidationError = (str: string) => {
  return `Your password must have at least 1 ${str} character`
}
export const baseValidationSchema = yup
  .object({
    new_password: yup
      .string()
      .required("please enter this field")
      // check minimum characters
      .min(8, "Password must have at least 8 characters")
      .trim()
      // different error messages for different requirements
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        getCharacterValidationError("special"),
      ),
    confirm_new_password: yup
      .string()
      .required("please enter this field")
      .oneOf([yup.ref("new_password")], "Your passwords do not match."),
  })
  .required()

type entryResource = components["schemas"]["TimeEntry"][]
export const getGroupedTimeEntiryValue = (data: entryResource = []) => {
  return data?.reduce((acc: any, entry: any) => {
    acc[entry.activity.name] =
      (acc[entry.activity.name] || 0) +
      calculateDeltaMinutes(entry.start, entry.finish)
    return acc
  }, {})
}
