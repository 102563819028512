import { createSlice, createEntityAdapter } from "@reduxjs/toolkit"
import { components } from "../../app/api/schema"
import { productsApi } from "./productsApi"
import { RootState } from "../../app/store"

export type ProductResource = components["schemas"]["Product"]

const productsAdapter = createEntityAdapter<ProductResource>({
  selectId: (product) => product.id,
})

const initialState = productsAdapter.getInitialState({
  status: "idle",
  error: null,
})

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    // Reducer functions will go here if needed
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      productsApi.endpoints.listProducts.matchFulfilled,
      (state, action) => {
        productsAdapter.setAll(state, action.payload)
      },
    )
    builder.addMatcher(
      productsApi.endpoints.createProduct.matchFulfilled,
      (state, action) => {
        productsAdapter.addOne(state, action.payload)
      },
    )
    builder.addMatcher(
      productsApi.endpoints.retrieveProduct.matchFulfilled,
      (state, action) => {
        productsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      productsApi.endpoints.updateProduct.matchFulfilled,
      (state, action) => {
        productsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      productsApi.endpoints.partialUpdateProduct.matchFulfilled,
      (state, action) => {
        productsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      productsApi.endpoints.destroyProduct.matchFulfilled,
      (state, action) => {
        productsAdapter.removeOne(state, action.meta.arg.originalArgs)
      },
    )
  },
})

export default productsSlice.reducer

export const {
  selectAll: selectAllProducts,
  selectById: selectProductById,
  selectIds: selectProductIds,
  selectEntities: selectProductEntities,
  selectTotal: selectTotalProducts,
} = productsAdapter.getSelectors<RootState>((state) => state.products)
