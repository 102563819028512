import {
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react"
import { useListServicesQuery } from "../services/servicesApi"
import { useAppSelector } from "../../app/hooks"
import { selectAllServices } from "../services/servicesSlice"
import { KnownIcon } from "../../components/widgets/KnownIcon"
import { ModalForm } from "../../components/forms/ModalForm"
import { ChargesForm } from "../charges/ChargesForm"
import { useState } from "react"
import { useNetworkCheck } from "../../app/hooks"
import { TicketResource } from "../../pages/user/TicketDetailPage"
export interface TicketServicesOverviewProps {
  ticket?: TicketResource
  onSave(data: any): void
}

export function TicketServicesOverview({
  ticket,
  onSave
}: TicketServicesOverviewProps) {
  useListServicesQuery()
  const services = useAppSelector(selectAllServices)
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
  const modalSize = isLargerThanMobile ? "lg" : "full"
  const [defaultValues, setDefaultValues] = useState(null)

  const getService = (id: number) =>
    services.find((service: { id: number }) => service.id === id)

  const isInCharges = (id: number) => {
    // const name = getService(id)?.name
    return ticket?.chargedetails.some((item) => item.record_id === id)
  }

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure()

  const { isOnline } = useNetworkCheck()
  return (
    <>
      {
        ticket?.servicedetails?.length !== 0 ?
          <div style={{ maxWidth: "calc(100vw - 90px)", width: "100%", overflowX: "auto" }}>
            <Table size={{ base: "sm", md: "sm", lg: "md" }}>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Amount</Th>
                  <Th>Unit</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {ticket?.servicedetails?.map((ticketService, index) => (
                  <Tr key={index} h="50px">
                    <Td>{getService(ticketService.service_id)?.name}</Td>
                    <Td>{ticketService.quantity}</Td>
                    <Td>{getService(ticketService.service_id)?.unit}</Td>
                    <Td>
                      {
                        !isInCharges(ticketService.ticket_service_id) ?
                          <IconButton
                            aria-label="create-charge"
                            icon={<KnownIcon name="dollar" />}
                            size={["xs", "sm", "md"]}
                            onClick={() => {
                              openModal()
                              const { service_id, description, ...rest } = ticketService
                              isInCharges(service_id)
                              const data = {
                                description: getService(ticketService.service_id)?.name,
                                unit: getService(ticketService.service_id)?.unit,
                                allow_surcharge: true,
                                section: "items",
                                record_id: ticketService.ticket_service_id,
                                ...rest,
                              }
                              setDefaultValues(data as any)
                            }}
                            isDisabled={!isOnline ? true : false}
                          />
                          :
                          null
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
          :
          <Text>{"There are no additional items"}</Text>
      }
      {/* Dialog for adding new charge entry */}
      <ModalForm
        title="Add New Entry"
        isOpen={isModalOpen}
        size={modalSize}
        onClose={closeModal}
        onCancel={closeModal}
        onSave={onSave}
        defaultValues={defaultValues}
      >
        <ChargesForm />
      </ModalForm>
    </>
  )
}
