import { useParams, Link, useNavigate } from "react-router-dom"
import { usePartialUpdateTicketMutation, useRetrieveTicketQuery, useRetriveTicketAttachmentsQuery } from "../../features/tickets/ticketsApi"
// import SignatureCanvas from 'react-signature-canvas'
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    useDisclosure,
    useMediaQuery,
    VStack,
} from "@chakra-ui/react"
import { useCustomToast, useUploadFileToS3 } from "../../app/hooks"
import { forwardRef, useEffect, useState } from "react"
import { PrintContent } from "../print/TicketDetailPrint"
import { Stream } from "../../components/Stream"
import { ImageWithSkeleton } from "../../components/forms/fields/ImageWithSkeleton"
import { Strip } from "../../components/Strip"
import { Streak } from "../../components/Streak"
import { Attachment } from "../../components/forms/fields/AttachmentField"
import { KnownIcon } from "../../components/widgets/KnownIcon"
import { useRetriveSignatureQuery } from "../../features/aws/awsApi"
import { ModalForm } from "../../components/forms/ModalForm"
import StampForm from "../../features/tickets/StampForm"
import OverlayLoader from "../../components/OverlayLoader"
import { ErrorBox } from "../../components/ErrorBox"
import { PuffLoader } from "react-spinners"
interface PrintContentProps {

}

export const StampTicketDetail = forwardRef<HTMLDivElement, PrintContentProps>(
    ({ }: PrintContentProps, ref) => {
        const navigate = useNavigate()
        const { upload } = useUploadFileToS3()
        const { ticketId } = useParams()
        const { showToast } = useCustomToast();
        const { isOpen, onClose, onOpen } = useDisclosure()
        const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
        const modalSize = isLargerThanMobile ? "lg" : "full"
        const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false)
        const [networkError, setNetworkError] = useState("")

        const [partialUpdateTicket] = usePartialUpdateTicketMutation()

        const { data: ticket, isLoading, isError, error } = useRetrieveTicketQuery(Number(ticketId))
        const ticketStatus = ticket?.status;  //get ticket status 
        const isDigitalSigned = ticket?.digital_signed;
        const ticketNumber = ticket?.number;
        const shouldSkipAttachmentsQuery = isLoading || isError || !ticketNumber

        const { data: attachments, isLoading: isAttachmentLoading } = useRetriveTicketAttachmentsQuery(Number(ticketId), { skip: shouldSkipAttachmentsQuery })
        const { data: signatureData, isLoading: isSignatureDataLoading } = useRetriveSignatureQuery(ticketNumber, { skip: !(ticketNumber && ticket.digital_signed) })

        const defaultValues = {
            cost_class: null,
            minor_class: null,
            major_class: null,
            other: null,
            digital_sign: null
        }

        const handelStampDetails = async (data: any) => {
            setIsFormSubmit(true);
            const { digital_sign, other, minor_class, major_class, cost_class } = data;
            try {
                const key = ticketNumber && await upload({
                    files: digital_sign,
                    folderType: "signature",
                    draftId: ticketNumber
                })
                if (!key) throw new Error("Field to upload file in s3")
                const payload = {
                    id: Number(ticketId),
                    cost_class,
                    minor_class,
                    major_class,
                    other,
                    digital_signed: true,
                    signature: key,
                    status: "stamped",
                };
                // Update ticket details
                const updateResponse = await partialUpdateTicket(payload);
                if (!("data" in updateResponse)) {
                    throw new Error(`Failed to update ticket details: ${JSON.stringify(updateResponse)}`);
                }
                // Show success toast message
                showToast({
                    status: "success",
                    description:
                        "You have successfully authorized the ticket",
                });
            } catch (error) {
                console.error("Error during file upload process:", error);
                showToast({
                    status: "error",
                    description:
                        "An error occurred, Please try again",
                });
            } finally {
                setIsFormSubmit(false);
            }
        };

        useEffect(() => {
            if (error && 'status' in error && error.status === 'FETCH_ERROR') {
                setNetworkError("Ticket details are not available without network connectivity");
                window.scrollTo({ top: 0, behavior: "smooth" })
            }
        }, [isError])


        return (
            <>
                <VStack w="full" ref={ref}  >
                    <ErrorBox show={isError ? true : false} error={error} message={networkError} />
                    {
                        !isError &&
                        <>
                            < Box w={"100%"} overflowX={"auto"} maxW={"calc(100vw - 50px)"} border="2px solid #bee3f8" borderRadius={"5px"}>
                                {
                                    (!isLoading && !isSignatureDataLoading && ticket) ?
                                        <Box w="750px" minH={"750px"} my={0} mx={"auto"}>
                                            <PrintContent ticket={ticket} charges={ticket?.chargedetails || []} signatureData={signatureData?.[0]} />
                                        </Box>
                                        :
                                        <Flex w="full" minH={"750px"} alignItems="center" justifyContent="center">
                                            <PuffLoader
                                                color="#3D82CE"
                                                size={80}
                                            />
                                        </Flex>
                                }
                            </Box>
                            <ButtonGroup w="full" justifyContent="flex-end" maxW={"calc(100vw - 40px)"}>
                                {
                                    ticketStatus === "approved" && !isDigitalSigned &&
                                    <>
                                        < Button
                                            colorScheme="blue"
                                            p={4} size={["xs", "sm", "md"]}
                                            onClick={() => onOpen()}
                                            isLoading={isFormSubmit}
                                        >
                                            Stamp
                                        </Button>
                                    </>
                                }

                                <Button colorScheme="blue"
                                    as={Link}
                                    to={`/print/tickets/${ticketId}?withCharge=${true}`}
                                    target="_blank"
                                    size={["xs", "sm", "md"]}
                                    p={4}
                                    rightIcon={<KnownIcon name="roundDollar" size={5} />}
                                >
                                    Print
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    onClick={() => navigate('/user/tickets')}
                                    size={["xs", "sm", "md"]}
                                    p={4}
                                    leftIcon={<KnownIcon name="back" />}
                                >
                                    All Tickets
                                </Button>
                            </ButtonGroup>

                            <VStack w="full" spacing={4} ref={ref} p={5} alignItems="flex-start">
                                <Stream title="Attachments">
                                    <Flex gap={4} my={4} wrap="wrap" w="full">
                                        {
                                            !isAttachmentLoading ?
                                                attachments?.length !== 0 ? attachments?.map((attachment: Attachment) => (
                                                    <Box position="relative" key={attachment.file_key}>
                                                        <ImageWithSkeleton
                                                            key={attachment.file_key}
                                                            attachment={attachment}
                                                            showRemoveIcon={false}
                                                        />
                                                    </Box>
                                                )) : (
                                                    <Strip>
                                                        <Streak size="large">{`There are no attachments`}</Streak>
                                                    </Strip>
                                                )
                                                :
                                                <Flex w="full" justifyContent="center" alignItems="center">
                                                    <PuffLoader
                                                        color="#3D82CE"
                                                        size={80}
                                                    />
                                                </Flex>
                                        }
                                    </Flex>
                                </Stream>
                            </VStack>
                        </>
                    }
                </VStack >

                <ModalForm
                    title={`Stamp details`}
                    defaultValues={defaultValues}
                    isOpen={isOpen}
                    size={modalSize}
                    onClose={onClose}
                    onCancel={onClose}
                    onSave={handelStampDetails}
                >
                    <StampForm />
                </ModalForm>
                {
                    isFormSubmit && <OverlayLoader />
                }
            </>

        )
    },
)
