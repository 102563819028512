import {
    Button,
    ButtonGroup,
    IconButton,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    WrapItem,
    useDisclosure,
    useMediaQuery,
} from "@chakra-ui/react"
import { components } from "../../app/api/schema"
import { ModalForm } from "../../components/forms/ModalForm"
import { ChargesForm } from "../charges/ChargesForm"
import { useState } from "react"
import { KnownIcon } from "../../components/widgets/KnownIcon"
import { useNetworkCheck } from "../../app/hooks"

export interface ChargePanelOverviewProps {
    charges: components["schemas"]["Charge"][]
    onSave(data: any): void
    onRemove(index: number): void
}

export function ChargePanelOverview({ charges, onSave, onRemove }: ChargePanelOverviewProps) {

    const [isSurCharge, setSurcharge] = useState<boolean>(false)
    const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
    const modalSize = isLargerThanMobile ? "lg" : "full"
    const [defaultValues, setDefaultValues] = useState(null)

    const handleAddButton = () => {
        setSurcharge && setSurcharge(false)
        setDefaultValues(null)
        openModal()
    }
    const {
        isOpen: isModalOpen,
        onOpen: openModal,
        onClose: closeModal,
    } = useDisclosure()
    const { isOnline } = useNetworkCheck()
    return (
        <>
            {
                charges?.length !== 0 ?
                    <div style={{ maxWidth: "calc(100vw - 90px)", width: "100%", overflowX: "auto" }}>
                        <Table size={["sm"]}>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Quantity</Th>
                                    <Th>Unit</Th>
                                    <Th>Rate</Th>
                                    <Th>Amount</Th>
                                    <Th> </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {charges?.map((item, index) => (
                                    <Tr key={index} h="50px">
                                        <Td>{item?.description}</Td>
                                        <Td>{item?.quantity}</Td>
                                        <Td>{item?.unit}</Td>
                                        <Td>{item?.rate}</Td>
                                        <Td>{item?.amount}</Td>
                                        <Td>
                                            <WrapItem gap={2} verticalAlign="center">
                                                <IconButton
                                                    aria-label="Remove"
                                                    icon={<KnownIcon name="minus" />}
                                                    onClick={() => onRemove(index)}
                                                    size={["xs", "sm", "md"]}
                                                    isDisabled={!isOnline ? true : false}
                                                />
                                                {
                                                    item.allow_surcharge === true &&
                                                    <IconButton
                                                        aria-label="Add_Surcharge"
                                                        icon={<KnownIcon name="percentage" />}
                                                        size={["xs", "sm", "md"]}
                                                        onClick={() => {
                                                            openModal()
                                                            const data = {
                                                                description: `Fuel Surcharge (${item.description})`,
                                                                unit: "%",
                                                                rate: item?.amount,
                                                                allow_surcharge: false,
                                                            }
                                                            setSurcharge && setSurcharge(true)
                                                            setDefaultValues(data as any)
                                                        }
                                                        }
                                                        isDisabled={!isOnline ? true : false}
                                                    />
                                                }
                                            </WrapItem>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </div>
                    :
                    <Text>{"No charges have been added."}</Text>
            }
            {/* Button to open the dialog for adding new entries */}
            <ButtonGroup width="full" mt={3} flex="row" justifyContent="end">
                <Button colorScheme="gray" onClick={handleAddButton} size={["sm", "md"]} isDisabled={!isOnline ? true : false}>
                    Add New Charge
                </Button>
            </ButtonGroup>
            {/* Dialog for adding new charge entry */}
            <ModalForm
                title="Add New Charge"
                isOpen={isModalOpen}
                size={modalSize}
                onClose={closeModal}
                onCancel={closeModal}
                onSave={onSave}
                defaultValues={defaultValues}
            >
                <ChargesForm isSurCharge={isSurCharge} />
            </ModalForm>
        </>
    )
}
