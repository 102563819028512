import { useForm, FormProvider } from "react-hook-form"

interface FormProps {
  defaultValues?: any
  children: React.ReactNode | React.ReactNode[]
}

export function Form({ defaultValues, children }: FormProps) {
  const methods = useForm({ defaultValues })

  return (
    <FormProvider {...methods}>
      <form>{children}</form>
    </FormProvider>
  )
}
