import { FormPanel } from '../../components/forms/FormPanel'
import { FormPanelDisplay } from '../../components/forms/FormPanelDisplay'
import { FormPanelControls } from '../../components/forms/FormPanelControls'
import LocationsForm from './LocationsForm'
import LocationsDisplay from './LocationsDisplay'


interface ConsignorPanelProps {
    defaultValues: any
    onSave(data: any): void
}

export function DraftLocationsPanel({ defaultValues, onSave }: ConsignorPanelProps) {
    return (
        <FormPanel
            title="Locations"
            defaultValues={defaultValues}
            onSave={onSave}
        >
            <FormPanelDisplay>
                <LocationsDisplay defaultValues={defaultValues} />
            </FormPanelDisplay>
            <FormPanelControls>
                <LocationsForm />
            </FormPanelControls>
        </FormPanel>
    )
}


