import React, { useState, useEffect } from "react"
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Input,
  List,
  ListItem,
  Box,
  Text,
} from "@chakra-ui/react"
import { useFormContext } from "react-hook-form"
import { useAppSelector } from "../../../app/hooks"
import { LocationResource, selectAllLocations } from "../../../features/terminals/locationsSlice"

export interface AutocompleteSuggestion {
  value: number
  text: string
}

interface AutocompleteFieldProps extends FieldProps {
  suggestions: AutocompleteSuggestion[]
  onMatch?: (selectedSuggestion: AutocompleteSuggestion) => void
}

export function AutocompleteField({
  label,
  name,
  suggestions,
  helper,
  onMatch,
  registerOptions
}: AutocompleteFieldProps) {
  const {
    formState: { errors },
    setValue,
    getValues,
    register,
    trigger
  } = useFormContext()

  const locations = useAppSelector(selectAllLocations)
  const findLocation = (locations: LocationResource[], inputValue: number | string) => {
    let location;
    if (typeof inputValue === "number") {
      location = locations.find((loc) => loc.id === inputValue)
    } else {
      location = locations.find((loc) => loc.name === inputValue.trim());
    }
    return location ? { value: location.id, text: location.name || "unknown" } : null;
  }
  const [inputValue, setInputValue] = useState<string>("")
  const [filteredSuggestions, setFilteredSuggestions] = useState<
    AutocompleteSuggestion[]
  >([])
  const [showSuggestions, setShowSuggestions] = useState(false)

  // Set inputValue based on initial field value when component mounts
  useEffect(() => {
    const initialValue = getValues(name) // Get initial value of the field
    let initialSuggestion;
    if (name.includes("location") && initialValue) {
      initialSuggestion = findLocation(locations, initialValue)
    } else {
      initialSuggestion = suggestions.find(
        (suggestion) => suggestion.value === initialValue,
      )
    }
    if (initialSuggestion) {
      setInputValue(initialSuggestion.text) // Set inputValue to text of initial suggestion
    }
    else {
      setInputValue(initialValue)
    }
  }, [getValues, name, suggestions])

  const handleSelectSuggestion = (suggestion: AutocompleteSuggestion) => {
    setInputValue(suggestion.text)
    setValue(name, suggestion.value)
    onMatch && onMatch(suggestion)
    setShowSuggestions(false)
    trigger(name)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setInputValue(value)
    if (value.trim().length > 0) {
      setValue(name, value)
      const filtered = suggestions.filter((suggestion) =>
        suggestion.text.toLowerCase().includes(value.toLowerCase()),
      )
      setFilteredSuggestions(filtered)
      setShowSuggestions(value !== "" && filtered.length > 0)
    } else {
      // Handle empty input value
      setValue(name, undefined)
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  }

  const handleBlur = () => {
    let isExist;
    if (name === "location") {
      isExist = findLocation(locations, inputValue)
    } else {
      isExist = suggestions.find((suggestion) => suggestion.text === inputValue.trim())
    }
    //check if value already exist 
    if (isExist) {
      handleSelectSuggestion(isExist)
    }
    setTimeout(() => {
      if (filteredSuggestions.length === 1) {
        handleSelectSuggestion(filteredSuggestions[0])
      } else {
        setShowSuggestions(false)
      }
    }, 200)
    trigger(name)
  }


  return (
    <FormControl isInvalid={errors[name] !== undefined} isRequired={registerOptions?.required ? true : false} >
      <FormLabel size="sm" fontWeight={600}>{label}</FormLabel>
      <InputGroup>
        <Input {...register(name, registerOptions)}
          value={inputValue}
          placeholder="Start typing..."
          onChange={handleInputChange}
          onBlur={handleBlur}
        />
      </InputGroup>
      {showSuggestions && (
        <Box position="absolute" width="100%" zIndex="dropdown">
          <List bg="white" boxShadow="lg">
            {filteredSuggestions.map((suggestion, index) =>
              <ListItem
                key={suggestion.value}
                cursor="pointer"
                onClick={() => {
                  handleSelectSuggestion(suggestion)
                }}
                px={4}
                py={2}
                _hover={{ bg: "gray.100" }}
              >
                <Text>{suggestion.text}</Text>
              </ListItem>
            )}
          </List>
        </Box>
      )}
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>{errors[name]?.message?.toString()}</FormErrorMessage>
    </FormControl >
  )
}
