import { useState, useEffect } from "react"
import {
    FormControl,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
} from "@chakra-ui/react"
import { useFormContext } from "react-hook-form"
import Select from 'react-select'
import { SearchSuggestions } from "../../../features/customers/CustomerAutocomplete"



interface SearchDropdownFieldProps extends FieldProps {
    suggestions: SearchSuggestions[]
    onMatch?: (selectedSuggestion: SearchSuggestions) => void
}

export function SearchDropdownField({
    label,
    name,
    suggestions,
    helper,
    onMatch,
    registerOptions
}: SearchDropdownFieldProps) {
    const {
        formState: { errors },
        setValue,
        getValues,
        register,
        trigger
    } = useFormContext()

    const [defaultIndex, setDefaultIndex] = useState<number | null>()

    // Set inputValue based on initial field value when component mounts
    useEffect(() => {
        const initialValue = getValues(name) // Get initial value of the field
        const index = suggestions.findIndex(
            (suggestion) => suggestion.value === initialValue,
        ) // Find suggestion based on initial field value
        if (index >= 0) {
            setValue(name, suggestions[index]?.value)
            setDefaultIndex(index)
        }
    }, [getValues, name, suggestions])

    const handleSelect = (selectedOption: SearchSuggestions | null) => {
        const index = suggestions.findIndex((item) => item === selectedOption)
        if (selectedOption) {
            setValue(name, selectedOption.value)
            setDefaultIndex(index)
        } else {
            setValue(name, "")
            setDefaultIndex(null)
        }
        trigger(name)
    };

    return (
        <FormControl isInvalid={errors[name] !== undefined} isRequired>
            <FormLabel size="sm" fontWeight={600}>
                {label}
            </FormLabel>
            <Select
                className={errors[name]?.message ? "errorStyle" : ""}
                {...register(name, registerOptions)}
                placeholder="Select customer"
                value={defaultIndex != null ? suggestions[defaultIndex] : null}
                options={suggestions}
                onChange={(e) => handleSelect(e)}
                isClearable
            />
            <FormHelperText>{helper}</FormHelperText>
            <FormErrorMessage>{errors[name]?.message?.toString()}</FormErrorMessage>
        </FormControl >
    )
}
