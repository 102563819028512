import { bolmintApi } from "../../app/api"
import { operations } from "../../app/api/schema"

export const servicesApi = bolmintApi.injectEndpoints({
  endpoints: (builder) => ({
    listServices: builder.query<
      operations["services_list"]["responses"]["200"]["content"]["application/json"],
      void
    >({
      query: () => ({
        url: "/services/",
      }),
    }),
    createService: builder.mutation<
      operations["services_create"]["responses"]["201"]["content"]["application/json"],
      Partial<
        operations["services_create"]["requestBody"]["content"]["application/json"]
      >
    >({
      query: (resource) => ({
        url: "/services/",
        method: "POST",
        body: resource,
      }),
    }),
    retrieveService: builder.query<
      operations["services_retrieve"]["responses"]["200"]["content"]["application/json"],
      number
    >({
      query: (serviceId) => ({
        url: `/services/${serviceId}/`,
      }),
    }),
    updateService: builder.mutation<
      operations["services_update"]["responses"]["200"]["content"]["application/json"],
      operations["services_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/services/${resource.id}/`,
        method: "PUT",
        body: resource,
      }),
    }),
    partialUpdateService: builder.mutation<
      operations["services_partial_update"]["responses"]["200"]["content"]["application/json"],
      operations["services_partial_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/services/${resource.id}/`,
        method: "PATCH",
        body: resource,
      }),
    }),
    destroyService: builder.mutation<void, number>({
      query: (serviceId) => ({
        url: `/services/${serviceId}/`,
        method: "DELETE",
      }),
    }),
  }),
})

export const {
  useListServicesQuery,
  useCreateServiceMutation,
  useRetrieveServiceQuery,
  useUpdateServiceMutation,
  usePartialUpdateServiceMutation,
  useDestroyServiceMutation,
} = servicesApi
