import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, InputGroup, InputLeftAddon, InputRightElement, useDisclosure } from "@chakra-ui/react"
import { KnownIcon } from "../../widgets/KnownIcon"
import { DateTimeModal } from "../DateTimeModal";
import { useFormContext } from "react-hook-form";
import { formatTimeDateOnly } from "../../../app/utils";
import { isDate, isValid, parse } from "date-fns";


interface DatetimeFieldProps extends FieldProps {
    onChange?: (value: string) => void
    error?: string | null
    isRequired?: boolean
}
type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

export function DatetimeField({
    label,
    name,
    helper,
    registerOptions,
    onChange,
    error,
    isRequired
}: DatetimeFieldProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { register, setValue, watch, formState, trigger } = useFormContext()
    const dateTimeValue = watch(name)
    const handlePickDate = (value: Value) => {
        if (value instanceof Date) {
            const formattedDate = formatTimeDateOnly(value) // Format the parsed date to MM/DD/YYYY hh:mm a format
            setValue(name, formattedDate)
            trigger(name)
        }
        onClose()
    }

    const validateTimeDate = (value: string | undefined) => {
        if (!value) {
            return "Please enter a date in MM/DD/YYYY HH:mm AM/PM format";
        }
        if (isDate(value) && isValid(new Date(value))) {
            return undefined // Valid date
        }
        return "Invalid format";
    };

    const defaultRegisterOptions = {
        validate: {
            validDate: validateTimeDate,
        },
        setValueAs: (value: any) => {
            if (onChange) {
                onChange(value)
            }
            if (isDate(value) && isValid(value)) {
                return value
            }
            const dateTimeRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4} (0[1-9]|1[0-2]):([0-5][0-9]) (am|pm)$/i;
            if (dateTimeRegex.test(value)) {
                const parsedValue = parse(value, "MM/dd/yyyy hh:mm a", new Date())
                if (isDate(parsedValue) && isValid(parsedValue)) {
                    return parsedValue
                }
            }
            return value
        },
    }

    // Merge provided registerOptions with default options
    const mergedRegisterOptions = {
        ...defaultRegisterOptions,
        ...registerOptions,
    }
    const transformInput = (value: any) => {
        if (isDate(value) && isValid(value)) {
            return formatTimeDateOnly(value); // Format the date to MM/DD/YYYY format
        } else if (typeof value === 'string' && value.trim() !== '') {
            // If the value is a string (e.g., initial UTC string), parse and format it
            const parsedValue = parse(value, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date());
            if (isDate(parsedValue) && isValid(parsedValue)) {
                return formatTimeDateOnly(parsedValue); // Format the parsed date to MM/DD/YYYY format
            }
        }
        return value;
    }
    return (
        <>
            <FormControl isInvalid={!!error || !!formState.errors?.[name]} isRequired={isRequired}>
                <FormLabel>{label}</FormLabel>
                <InputGroup>
                    <InputLeftAddon>
                        <KnownIcon name="clock" />
                    </InputLeftAddon>
                    <Input
                        {...register(name, mergedRegisterOptions)}
                        placeholder="MM/DD/YYYY HH:MM AM/PM"
                        // onChange={(e) => setValue(name, e.target.value)}
                        value={transformInput(dateTimeValue || "")}
                    />
                    <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={onOpen}>
                            Pick
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <FormHelperText>{helper}</FormHelperText>
                <FormErrorMessage>
                    {error || formState.errors?.[name]?.message?.toString()}
                </FormErrorMessage>

                <DateTimeModal
                    isOpen={isOpen}
                    onClose={onClose}
                    title="Select a Date and Time"
                    onPick={handlePickDate}
                    onCancel={onClose}
                >
                    (...)
                </DateTimeModal>

            </FormControl>
        </>
    )


}