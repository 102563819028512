import { FormCollectionPanel } from "../../components/forms/FormCollectionPanel"
import { FormPanelControls } from "../../components/forms/FormPanelControls"
import { FormPanelDisplay } from "../../components/forms/FormPanelDisplay"
import { ServicesDisplay } from "./ServicesDisplay"
import { ServicesForm } from "./ServicesForm"

interface ServicesPanelProps {
  defaultValues: any
  onSave(data: any): void
  onRemove(index: number): void
}

export function ServicesPanel({ defaultValues, onSave, onRemove }: ServicesPanelProps) {
  return (
    <FormCollectionPanel
      title="Additional Billable Items"
      subtitle="Additional products and services to be billed with this ticket"
      entries={defaultValues}
      onSave={onSave}
      onRemove={onRemove}
    >
      <FormPanelDisplay>
        <ServicesDisplay />
      </FormPanelDisplay>
      <FormPanelControls>
        <ServicesForm />
      </FormPanelControls>
    </FormCollectionPanel>
  )
}
