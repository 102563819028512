import { Badge } from "@chakra-ui/react"

interface TraitLineProps {
  isNew?: boolean
  children: React.ReactNode | React.ReactNode[]
}

export function TraitLine({ isNew = false, children }: TraitLineProps) {
  return (
    <>
      {children} {isNew && <Badge>new</Badge>}
    </>
  )
}
