import { Box } from "@chakra-ui/react"

interface FocusViewProps {
  children: React.ReactNode
}

export function FocusView({ children }: FocusViewProps) {
  return (
    <Box bg="white" rounded="lg" p={6} w="full">
      {children}
    </Box>
  )
}
