import { Box, Flex } from "@chakra-ui/react"

interface StripLineProps {
  children: React.ReactNode
  fade?: boolean
  strong?: boolean
  wrap?: boolean
}

export function StripLine({
  children,
  strong = false,
  fade = false,
  wrap = false,
}: StripLineProps) {
  return (
    <Box
      w="full"
      color={fade ? "gray.500" : undefined}
      fontSize={!strong ? "xs" : undefined}
      fontWeight={strong ? "700" : undefined}
    >
      <Flex
        alignItems="center"
        columnGap={4}
        rowGap={2}
        wrap={wrap ? "wrap" : "unset"}
      >
        {children}
      </Flex>
    </Box>
  )
}
