import React from "react"
import { SelectField } from "../../components/forms/fields/SelectField"
import { useAppSelector } from "../../app/hooks"
import { selectCustomerById } from "../customers/customersSlice"
import { useLocation, useParams } from "react-router-dom"
import { selectDraftNumber } from "../draft/draftSlice"

interface DraftSelectProps extends FieldProps {
    isRequired?: boolean
}
interface Options {
    value: string;
    text: string;

}

const DraftSelect: React.FC<DraftSelectProps> = (
    props: DraftSelectProps,
) => {
    const draft = useAppSelector((state) => state.drafts.drafts)
    const fallbackCustomerName = (id: number) => useAppSelector(
        (state) => selectCustomerById(state, id || 0)?.name,
    )
    const location = useLocation().pathname
    const { id } = useParams();
    const draftIndex: number = id ? parseInt(id) - 1 : 0;
    const draftNumber = useAppSelector(selectDraftNumber(draftIndex));

    const getOptions = () => {
        if (location.includes('user/draft')) {
            const currentDraft = draft.find((draft) => draft.number === draftNumber);
            const customerName = currentDraft?.basic?.customer
                ? typeof currentDraft.basic.customer === "number"
                    ? fallbackCustomerName(currentDraft.basic.customer)
                    : currentDraft.basic.customer
                : 'No Customer Selected';

            const text = customerName ? `${customerName} : ${currentDraft?.number}` : '';

            return [{
                value: currentDraft?.number || "",
                label: text,
            }];
        } else {
            return draft.map((item) => {
                const customerName = item?.basic?.customer
                    ? typeof item.basic.customer === "number"
                        ? fallbackCustomerName(item.basic.customer)
                        : item.basic.customer
                    : 'No Customer Selected';

                const text = customerName ? `${customerName} : ${item.number}` : '';

                return {
                    value: item.number || "",
                    label: text,
                };
            });
        }
    };
    const options = getOptions()

    return <SelectField {...props} options={options} isRequired={props.isRequired} />
}

export default DraftSelect