import { IconButton, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useMediaQuery } from '@chakra-ui/react'
import { useState } from 'react'
import { ActivityTrait } from '../timeEntries/ActivityTrait'
import { calculateDeltaMinutes, formatMinutesAsHours } from '../../app/utils'
import { ModalForm } from '../../components/forms/ModalForm'
import { LocalEntry } from '../timeEntries/localEntriesSlice'
import { ACTIVITY_OPTIONS } from '../timeEntries/choices'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { selectDraftCharges } from './draftSlice'
import { KnownIcon } from '../../components/widgets/KnownIcon'
import { ChargesForm } from '../charges/ChargesForm'
import { selectAllActivities } from '../activities/activitySlice'

interface DraftTimeEntriesAggregated {
    timeEntries: LocalEntry[]
    onSave(data: any): void
}

function DraftTimeEntriesAggregated({ timeEntries, onSave }: DraftTimeEntriesAggregated) {

    const { id } = useParams()
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0
    const draftCharges = useAppSelector(selectDraftCharges(draftIndex))
    const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
    const modalSize = isLargerThanMobile ? "lg" : "full"
    const [defaultValues, setDefaultValues] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const grouped: { [activity: string]: number } = timeEntries.reduce(
        (acc: any, entry: any) => {
            acc[entry.activity] =
                (acc[entry.activity] || 0) +
                calculateDeltaMinutes(entry.start, entry.finish)
            return acc
        },
        {},
    )
    const grandTotal = Object.values(grouped).reduce(
        (acc, totalHours) => acc + totalHours,
        0,
    )

    const handleClose = () => {
        setIsModalOpen(false)
    }
    const handleOpen = () => {
        setIsModalOpen(true)
    }

    const Activities = useAppSelector(selectAllActivities)
    const getActivityLabel = (activity: number) => {
        return Activities.find((item) => item.id === activity)?.name
    }

    const isInCharge = (activity: number) => {
        const act = getActivityLabel(activity)
        return draftCharges?.some((item) =>
            item.description === act
        )
    }

    return (
        <>
            {
                timeEntries.length !== 0 ?
                    <div style={{ maxWidth: "calc(100vw - 100px)", width: "100%", overflowX: "auto" }}>
                        <Table variant="simple" size={{ base: "sm", md: "sm", lg: "md" }}>
                            <Thead>
                                <Tr>
                                    <Th>Activity</Th>
                                    <Th>Hours</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {Object.entries(grouped).map(([activity, totalHours]) => (
                                    <Tr key={activity} h="50px">
                                        <Td>
                                            <ActivityTrait choice={activity} />
                                        </Td>
                                        <Td>{formatMinutesAsHours(totalHours)}</Td>
                                        <Td>
                                            {
                                                !isInCharge(Number(activity)) ?
                                                    <>
                                                        <IconButton
                                                            aria-label="create-charge"
                                                            icon={<KnownIcon name="dollar" />}
                                                            size={["xs", "sm", "md"]}
                                                            onClick={() => {
                                                                handleOpen()
                                                                const data = {
                                                                    description: getActivityLabel(Number(activity)),
                                                                    quantity: formatMinutesAsHours(totalHours),
                                                                    unit: "Hour",
                                                                    allow_surcharge: true
                                                                }
                                                                setDefaultValues(data as any)
                                                            }}
                                                        />
                                                    </>
                                                    :
                                                    null
                                            }
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    <Th fontWeight={700} color="black">
                                        Total Hours:
                                    </Th>
                                    <Th color="black">{formatMinutesAsHours(grandTotal)}</Th>
                                    <Th></Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </div>
                    :
                    <Text>{"There are no time entries"}</Text>
            }

            {/* Dialog for adding new charge entry */}
            <ModalForm
                title="Add New Entry"
                isOpen={isModalOpen}
                size={modalSize}
                onClose={handleClose}
                onCancel={handleClose}
                onSave={onSave}
                defaultValues={defaultValues}
            >
                <ChargesForm />
            </ModalForm>
        </>

    )
}

export default DraftTimeEntriesAggregated
