import {
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react"
import { formatMinutesAsHours, getGroupedTimeEntiryValue } from "../../app/utils"
import { ModalForm } from "../../components/forms/ModalForm"
import { ChargesForm } from "../charges/ChargesForm"
import { useMemo, useState } from "react"
import { KnownIcon } from "../../components/widgets/KnownIcon"
import { ACTIVITY_OPTIONS } from "./choices"
import { useNetworkCheck } from "../../app/hooks"
import { TicketResource } from "../../pages/user/TicketDetailPage"

export interface TimeEntriesAggregatedProps {
  ticket?: TicketResource
  onSave(data: any): void
}

export function TimeEntriesAggregated({
  ticket,
  onSave
}: TimeEntriesAggregatedProps) {
  const grouped: { [activity: string]: number } = useMemo(() => getGroupedTimeEntiryValue(ticket?.timeentries), [ticket?.timeentries]);
  const grandTotal = Object?.values(grouped)?.reduce(
    (acc, totalHours) => acc + totalHours,
    0,
  )
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
  const modalSize = isLargerThanMobile ? "lg" : "full"

  const [defaultValues, setDefaultValues] = useState(null)

  //check that particular service is already in charges list or not
  const isInCharge = (activity: string) => {
    return ticket?.chargedetails.some((item) =>
      item.description === activity
    )
  }
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure()
  const { isOnline } = useNetworkCheck()

  return (
    <>
      {
        ticket?.timeentries.length !== 0 ?
          <div style={{ maxWidth: "calc(100vw - 90px)", width: "100%", overflowX: "auto" }}>
            <Table variant="simple" size={{ base: "sm", md: "sm", lg: "md" }}>
              <Thead>
                <Tr>
                  <Th>Activity</Th>
                  <Th>Hours</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.entries(grouped).map(([activity, totalHours]) => (
                  <Tr key={activity} h="50px">
                    <Td>
                      {activity}
                      {/* <ActivityTrait choice={activity} /> */}
                    </Td>
                    <Td>{formatMinutesAsHours(totalHours)}</Td>
                    <Td>
                      {
                        !isInCharge(activity) ?
                          <>
                            <IconButton
                              aria-label="create-charge"
                              icon={<KnownIcon name="dollar" />}
                              size={["xs", "sm", "md"]}
                              onClick={() => {
                                const data = {
                                  description: activity,
                                  quantity: formatMinutesAsHours(totalHours),
                                  unit: "Hour",
                                  allow_surcharge: true,
                                }
                                setDefaultValues(data as any)
                                openModal()
                              }}
                              isDisabled={!isOnline ? true : false}
                            />
                          </>
                          :
                          null
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th fontWeight={700} color="black">
                    Total Hours:
                  </Th>
                  <Th color="black">{formatMinutesAsHours(grandTotal)}</Th>
                  <Th></Th>
                </Tr>
              </Tfoot>
            </Table>
          </div>
          :
          <Text>{"There are no time entries"}</Text>
      }

      {/* Dialog for adding new charge entry */}
      <ModalForm
        title="Add New Entry"
        isOpen={isModalOpen}
        size={modalSize}
        onClose={closeModal}
        onCancel={closeModal}
        onSave={onSave}
        defaultValues={defaultValues}
      >
        <ChargesForm />
      </ModalForm>
    </>
  )
}
