import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    UnorderedList,
    ListItem,
} from "@chakra-ui/react";
import { AlertStatus } from "../app/hooks"; // Import the type

interface MessageBoxProps {
    show: boolean;
    status: AlertStatus;
    message?: string | string[];
    error?: any;
}

export function MessageBox({ show, status, message, error }: MessageBoxProps) {
    return show ? (
        <Alert status={status} rounded="md">
            <AlertIcon />
            <AlertTitle>
                {!Array.isArray(message) ? (
                    message
                ) : (
                    <UnorderedList>
                        {message.map((item, index) => (
                            <ListItem key={index}>{item}</ListItem>
                        ))}
                    </UnorderedList>
                )}
            </AlertTitle>
            <AlertDescription>{error?.data?.detail}</AlertDescription>
        </Alert>
    ) : null;
}
