import { Link, Params, useNavigate, useParams } from "react-router-dom"
import { DetailView } from "../../layout/views/DetailView"
import {
  usePartialUpdateTicketMutation,
  useRetrieveTicketQuery,
} from "../../features/tickets/ticketsApi"
import { ErrorBox } from "../../components/ErrorBox"
import { Stream } from "../../components/Stream"
import { StripLine } from "../../components/StripLine"
import { Strip } from "../../components/Strip"
import { Streak } from "../../components/Streak"
import { TicketBasicDetails } from "../../features/tickets/TicketBasicDetails"
import { Box, Button, Flex, useDisclosure, useMediaQuery } from "@chakra-ui/react"
import { formatMinutesAsHours, getGroupedTimeEntiryValue, } from "../../app/utils"
import { Fragment, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector, useCustomToast, useNetworkCheck, useUploadFileToS3 } from "../../app/hooks"
import { selectSession } from "../../features/session/sessionSlice"
import { Attachment } from "../../components/forms/fields/AttachmentField"
import { ImageWithSkeleton } from "../../components/forms/fields/ImageWithSkeleton"
import { KnownIcon } from "../../components/widgets/KnownIcon"
import { ProductResource } from "../../features/products/productsSlice"
import { components } from "../../app/api/schema"
import { useRetriveAttachmentsQuery } from "../../features/aws/awsApi"
import SavePDFButton from "../print/SavePDFButton"
import { ModalForm } from "../../components/forms/ModalForm"
import StampForm from "../../features/tickets/StampForm"
import { PuffLoader } from "react-spinners"
import OverlayLoader from "../../components/OverlayLoader"


type TankGaugeDetailsResource = components["schemas"]["Tank_Gauge_Record"]
type ServiceDetailsResource = components["schemas"]["Service"]
type ChargeDetailsResource = components["schemas"]["Charge"]
export type TicketResource = components["schemas"]["Ticket"]

export function TicketDetailPage() {
  const navigate = useNavigate()
  const { isOnline } = useNetworkCheck()
  const { showToast } = useCustomToast();
  const { ticketId } = useParams<Params>()
  const [isSmallScreen] = useMediaQuery('(max-width: 850px)');
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
  const { isOpen, onClose: closeModal, onOpen } = useDisclosure()
  const { upload } = useUploadFileToS3()
  const session = useAppSelector(selectSession)
  const role = session?.profile.role //get role of current user
  const modalSize = isLargerThanMobile ? "lg" : "full"

  const [networkError, setNetworkError] = useState<string>('')
  const [driverHours, setDriverHours] = useState({})
  const [grandTotal, setGrandTotal] = useState<number>()
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false)

  const [partialUpdateTicket, { isLoading: isUpdating }] = usePartialUpdateTicketMutation()
  const { data, isLoading, isError, error, isSuccess } = useRetrieveTicketQuery(Number(ticketId))

  const ticketNumber = data?.number
  const timeEntries = data?.timeentries
  const shouldSkipAttachmentsQuery = isLoading || isError || !ticketNumber

  const { data: attachments, isLoading: isAttachmentLoading } = useRetriveAttachmentsQuery(ticketNumber, { skip: shouldSkipAttachmentsQuery });

  const stampDefaultValues = {
    cost_class: null,
    minor_class: null,
    major_class: null,
    other: null,
    digital_sign: null
  }

  const handleGoCharges = () => {
    navigate(`/user/tickets/${ticketId}/charges/`)
  }

  const handleGoEdit = () => {
    navigate(`/user/tickets/${ticketId}/edit/`)
  }

  const handleGoPrint = () => {
    navigate(`/print/tickets/${ticketId}/`)
  }

  const handleAllTickets = () => {
    isOnline ? navigate("/user/tickets") : navigate("user/home")
  }

  const handleStatusChange = async (status: "approved" | "unapproved") => {
    const body = {
      id: Number(ticketId),
      status: data?.digital_signed ? "stamped" : status,
    };
    await partialUpdateTicket(body);
  };

  const handelStampDetails = async (data: any) => {
    setIsFormSubmit(true);
    const { digital_sign, other, minor_class, major_class, cost_class } = data;
    try {
      const key = ticketNumber && await upload({ files: digital_sign, draftId: ticketNumber, folderType: "signature" })

      if (!key) throw new Error("Field to upload file in s3")

      // Prepare the payload for ticket update
      const payload = {
        id: Number(ticketId),
        cost_class,
        minor_class,
        major_class,
        other,
        digital_signed: true,
        signature: key,
        ...(role === "driver-lead" && { status: "stamped" }),
      };

      // Update ticket details
      const updateResponse = await partialUpdateTicket(payload);
      if (!("data" in updateResponse)) {
        throw new Error(`Failed to update ticket details: ${JSON.stringify(updateResponse)}`);
      }

      // Show success toast message
      showToast({
        status: 'success',
        description: 'The client has successfully authorized the ticket and signed it',
      });

    } catch (error) {
      console.error("Error during file upload process:", error);
      showToast({
        status: 'error',
        description: "There was an issue capturing the client's signature, Please try again",
      });
    } finally {
      setIsFormSubmit(false);
    }
  };


  const memoizedDriverHours: { [activity: string]: number } = useMemo(() => {
    if (timeEntries) {
      return getGroupedTimeEntiryValue(timeEntries)
    }
    return {}
  }, [timeEntries])
  const memoizedGrandTotal = useMemo(() => {
    if (timeEntries) {
      const total = Object.values(memoizedDriverHours).reduce(
        (acc, totalHours) => acc + totalHours,
        0,
      )
      return total
    }
    return 0
  }, [memoizedDriverHours])

  useEffect(() => {
    setDriverHours(memoizedDriverHours)
    setGrandTotal(memoizedGrandTotal)
  }, [memoizedDriverHours, memoizedGrandTotal, timeEntries])

  useEffect(() => {
    if (error && 'status' in error && error.status === 'FETCH_ERROR') {
      setNetworkError("Please try again later. The data you are attempting to view is either unavailable or your network connection is unstable");
      window.scrollTo({ top: 0, behavior: "smooth" })
    } else if (isSuccess && data) {
      // Clear the error if data fetch is successful
      setNetworkError("");
    }
  }, [isError, isSuccess, data])
  return (
    <DetailView
      title={ticketNumber || "Unkonwn"}
      subtitle="Ticket Details"
      isLoading={isLoading}
    >
      <ErrorBox show={isError} error={error} message={networkError} />
      {
        !networkError &&
        <>
          <Stream title="General Information">
            <TicketBasicDetails ticket={data} />
          </Stream>
          <Stream title="Locations">
            <Strip>
              <StripLine wrap>
                <Streak label="From Company:" size="large">
                  {data?.consignor_terminal?.name || "--"}
                </Streak>
                <Streak label="Origin Location:" size="medium">
                  {data?.consignor_location?.name || "--"}
                </Streak>
              </StripLine>
            </Strip>
            <Strip>
              <StripLine wrap>
                <Streak label="To Company:" size="large">
                  {data?.consignee_terminal?.name || "--"}
                </Streak>
                <Streak label="Destination Location:" size="medium">
                  {data?.consignee_location?.name || "--"}
                </Streak>
              </StripLine>
            </Strip>
          </Stream>

          {data?.products?.length !== 0 ? (
            <Stream title="Label Information">
              {data?.products.map((product: ProductResource, index: number) => (
                <Strip key={index}>
                  <StripLine wrap>
                    <Streak label="Shipping Name:" size="large">
                      {product?.name}
                    </Streak>
                    <Streak label="Qty:" size="small">
                      {data?.productdetails?.[index]?.quantity || "--"}
                    </Streak>
                    <Streak label="Pkg:" size="small">
                      {data?.productdetails?.[index]?.packages || "--"}
                    </Streak>
                    <Streak label="Toxic:" size="small">
                      {data?.productdetails?.[index]?.toxic_by_inhalation
                        ? "Yes"
                        : "No"}
                    </Streak>
                    <Streak label="Subsidiary Class:" size="small">
                      {data?.productdetails?.[index]?.subsidiary_class || "--"}
                    </Streak>
                  </StripLine>
                </Strip>
              ))}
            </Stream>
          ) : null}

          <Stream title="Load">
            <Strip>
              <StripLine wrap>
                <Streak label="Estimated Volume:" size="medium">
                  {data?.estimated_volume || "--"}
                </Streak>
                <Streak label="Sequence:" size="small">
                  {data?.sequence || "--"}
                </Streak>
                <Streak label="SW Percent:" size="small">
                  {data?.sw_percent || "--"}
                </Streak>
                <Streak label="Confirmed Empty:" size="large">
                  {data?.confirmed_empty ? "Yes" : "No"}
                </Streak>
                <Streak label="Residue Last Contained:" size="large">
                  {data?.residue_last_contained?.name || "--"}
                </Streak>
              </StripLine>
              {
                data?.tank_gauge_details && data?.tank_gauge_details.length > 0 &&
                data?.tank_gauge_details.map((record: TankGaugeDetailsResource, index: number) => (
                  <Fragment key={index}>
                    <StripLine>
                      <Streak label="Tank Gauge Start:" size="small">
                        {record?.start || "--"}
                      </Streak>
                      <Streak label="Tank Gauge Finish:" size="small">
                        {record?.finish || "--"}
                      </Streak>
                    </StripLine>
                  </Fragment>
                ))
              }
            </Strip>
          </Stream>

          <Stream title="Unload">
            <Strip>
              <StripLine wrap>
                <Streak label="Actual Volume:" size="medium">
                  {data?.actual_volume || "--"}
                </Streak>
              </StripLine>
              <StripLine>
                <Streak label="Description of Work:" size="large">
                  {data?.work_description || "--"}
                </Streak>
              </StripLine>
            </Strip>
          </Stream>

          <Stream title="Driver Hours">
            {timeEntries?.length !== 0 ? (
              Object.entries(driverHours).map(([activity, totalHours], index) => (
                <Strip key={index}>
                  <StripLine>
                    <Streak label="Activity:" size="small">
                      {activity}
                    </Streak>
                    <Streak label="Total Hours:" size="small">
                      {formatMinutesAsHours(totalHours as number)}
                    </Streak>
                  </StripLine>
                </Strip>
              ))
            ) : (
              <Strip>
                <Streak size="large">{`There is no entry`}</Streak>
              </Strip>
            )}
            {timeEntries?.length !== 0 ? (
              <StripLine>
                <Streak label="Grand Total:" size="large">
                  {formatMinutesAsHours(grandTotal)}
                </Streak>
              </StripLine>
            ) : null}
          </Stream>
          <Stream title="Additional Services">
            {data?.services?.length !== 0 ? (
              data?.services.map((service: ServiceDetailsResource, index: number) => (
                <Strip key={index}>
                  <StripLine wrap>
                    <Streak label="Service Name:" size="medium">
                      {service.name || "--"}
                    </Streak>
                    <Streak label="Qty:" size="small">
                      {data?.servicedetails?.[index]?.quantity || "--"}
                    </Streak>
                    <Streak label="Description:" size="large">
                      {data?.servicedetails?.[index]?.description || "--"}
                    </Streak>
                  </StripLine>
                </Strip>
              ))
            ) : (
              <Strip>
                <Streak size="large">{`No additional services have been added`}</Streak>
              </Strip>
            )}
          </Stream>
          <Stream title="Charges">
            {data?.chargedetails?.length !== 0 ? (
              data?.chargedetails.map((charge: ChargeDetailsResource, index: number) => (
                <Strip key={index}>
                  <StripLine wrap>
                    <Streak label="Service Name:" size="large">
                      {charge?.description || "--"}
                    </Streak>
                    <Streak label="Qty:" size="small">
                      {charge?.quantity || "--"}
                    </Streak>
                    <Streak label="Unit:" size="small">
                      {charge?.unit || "--"}
                    </Streak>
                    <Streak label="Rate:" size="small">
                      {charge?.rate || "--"}
                    </Streak>
                    <Streak label="Amount:" size="small">
                      {charge?.amount || "--"}
                    </Streak>
                  </StripLine>
                </Strip>
              ))
            ) : (
              <Strip>
                <Streak size="large">{`No charges have been added.`}</Streak>
              </Strip>
            )}
          </Stream>
          <Stream title="Attachments">
            <Flex gap={4} my={4} justifyContent="flex-start" wrap="wrap">
              {
                !isAttachmentLoading ?
                  attachments && attachments?.length !== 0 ? attachments?.map((attachment: Attachment) => (
                    <Box position="relative" key={attachment.file_key}>
                      <ImageWithSkeleton
                        key={attachment.file_key}
                        attachment={attachment}
                        showRemoveIcon={false}
                      />
                    </Box>
                  )) : (
                    <Strip>
                      <Streak size="large">{`There are no attachments`}</Streak>
                    </Strip>
                  )
                  :
                  <Flex w="full" justifyContent="center" alignItems="center">
                    <PuffLoader
                      color="#3D82CE"
                      size={80}
                    />
                  </Flex>
              }
            </Flex>
          </Stream>
          <Flex w="full"
            flexDirection={isSmallScreen ? "column" : "row"}
            gap={isSmallScreen ? 2 : 3}
            justifyContent={isSmallScreen ? "center" : "flex-end"}
            alignItems={isSmallScreen ? "center" : "flex-end"}
          >
            {(role === "driver-lead" || role === "driver") && (
              <>
                <Button
                  as={isOnline ? Link : 'button'}
                  {...(isOnline ? { to: `/print/tickets/${ticketId}?withCharge=${true}`, target: "_blank" } : {})}
                  colorScheme="blue"
                  size={["xs", "sm"]}
                  w={isSmallScreen ? "45%" : "auto"}
                  rightIcon={<KnownIcon name="roundDollar" size={5} />}
                  isDisabled={isOnline ? false : true}
                  p={4}
                >
                  Print with
                </Button>
              </>
            )}
            {(role === "driver-lead" || role === "driver") && (
              <>
                <Button
                  as={isOnline ? Link : 'button'}
                  {...(isOnline ? { to: `/print/tickets/${ticketId}?withCharge=${false}`, target: "_blank" } : {})}
                  colorScheme="blue"
                  size={["xs", "sm"]}
                  w={isSmallScreen ? "45%" : "auto"}
                  rightIcon={<KnownIcon name="roundDollar" size={5} />}
                  isDisabled={isOnline ? false : true}
                  p={4}
                >
                  Print without
                </Button>
              </>
            )}
            {role === "driver-lead" &&
              (data?.status === "submitted" || data?.status === "unapproved") && (
                <Button
                  size={["xs", "sm"]}
                  p={4}
                  w={isSmallScreen ? "45%" : "auto"}
                  colorScheme="blue"
                  onClick={() => handleStatusChange("approved")}
                  isLoading={isUpdating}
                  isDisabled={(data?.chargedetails.length === 0 || !isOnline) ? true : false}
                >
                  Approve
                </Button>
              )}
            {/* {session?.profile?.role === "driver-lead" && data && (
          <SavePDFButton
            content={<PrintContent ticket={data} charges={charges} />}
          />
        )} */}
            {((role === "driver-lead" || role === "driver") && (data?.status == "submitted" || data?.status == "unapproved")) && (
              <Button colorScheme="blue"
                onClick={handleGoCharges}
                size={["xs", "sm"]}
                p={4}
                w={isSmallScreen ? "45%" : "auto"}
                isDisabled={!isOnline ? true : false}
              >
                Charges
              </Button>
            )}
            {(role === "driver-lead" && data?.status === "approved") && (
              <Button colorScheme="blue"
                onClick={() => handleStatusChange("unapproved")}
                isLoading={isUpdating}
                size={["xs", "sm"]}
                p={4}
                w={isSmallScreen ? "45%" : "auto"}
                isDisabled={!isOnline ? true : false}
              >
                Unapprove
              </Button>
            )}
            {(role === "driver-lead" || role === "driver") &&
              (data?.status === "submitted" || data?.status === "unapproved") && (
                <Button
                  colorScheme="blue"
                  onClick={handleGoEdit}
                  size={["xs", "sm"]}
                  p={4}
                  w={isSmallScreen ? "45%" : "auto"}
                  isDisabled={!isOnline ? true : false}
                >
                  Edit
                </Button>
              )}
            {(role === "driver" && data?.status == "submitted" && !data?.digital_signed) &&
              <Button
                colorScheme="blue"
                onClick={() => onOpen()}
                size={["xs", "sm"]}
                p={4}
                w={isSmallScreen ? "45%" : "auto"}
                isDisabled={!isOnline ? true : false}
                isLoading={isFormSubmit}
              >
                Signature
              </Button>
            }
            <Button colorScheme="blue" onClick={handleAllTickets}
              size={["xs", "sm"]}
              p={4}
              w={isSmallScreen ? "45%" : "auto"}
              leftIcon={<KnownIcon name="back" />}
            >
              {isOnline ? `All Tickets` : `Back to Home`}
            </Button>
          </Flex>


          <ModalForm
            title={`Stamp details`}
            defaultValues={stampDefaultValues}
            isOpen={isOpen}
            size={modalSize}
            onClose={closeModal}
            onCancel={closeModal}
            onSave={handelStampDetails}
          >
            <StampForm />
          </ModalForm>

          {/* overlay loader while form submission */}
          {
            isFormSubmit && <OverlayLoader />
          }
        </>
      }
    </DetailView>

  )
}
