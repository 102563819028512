import { WrapItem } from "@chakra-ui/react"
import { ReadonlyField } from "../../components/forms/fields/ReadonlyField"
import { formatTimeDateOnly } from "../../app/utils"
import { ActivityTrait } from "./ActivityTrait"
import { LocalEntry } from "./localEntriesSlice"
import { selectActivityById } from "../activities/activitySlice"
import { useAppSelector } from "../../app/hooks"

interface TimeEntryDisplayProps {
  entry?: LocalEntry
}

export function TimeEntryDisplay({ entry }: TimeEntryDisplayProps) {
  const activity = useAppSelector(state => selectActivityById(state, Number(entry?.activity)));
  return (
    <>
      <WrapItem alignItems="flex-end" flexGrow={1}>
        <ReadonlyField label="Activity">
          <ActivityTrait choice={entry?.activity} />
        </ReadonlyField>
      </WrapItem>
      <WrapItem alignItems="flex-end">
        <ReadonlyField label="Start">{formatTimeDateOnly(entry?.start)}</ReadonlyField>
      </WrapItem>
      <WrapItem alignItems="flex-end">
        <ReadonlyField label="Finish">{formatTimeDateOnly(entry?.finish)}</ReadonlyField>
      </WrapItem>
      <WrapItem alignItems="flex-end">
        <ReadonlyField label="Ticket Draft">{entry?.ticketDraft || "--"}</ReadonlyField>
      </WrapItem>
    </>
  )
}
