import React, { useState } from 'react'
import { DraftServicesValues, selectDraftCharges } from './draftSlice'
import { IconButton, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useMediaQuery } from '@chakra-ui/react'
import { useAppSelector } from '../../app/hooks'
import { selectAllServices } from '../services/servicesSlice'
import { useParams } from 'react-router-dom'
import { KnownIcon } from '../../components/widgets/KnownIcon'
import { ChargesForm } from '../charges/ChargesForm'
import { ModalForm } from '../../components/forms/ModalForm'


interface DraftTicketServicesOverviewProps {
    draftServices: DraftServicesValues[]
    onSave(data: any): void
}
function DraftTicketServicesOverview({ draftServices, onSave }: DraftTicketServicesOverviewProps) {
    const { id } = useParams()
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0
    const draftCharges = useAppSelector(selectDraftCharges(draftIndex)) ?? []
    const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
    const modalSize = isLargerThanMobile ? "lg" : "full"
    const [defaultValues, setDefaultValues] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const services = useAppSelector(selectAllServices)
    const getService = (id: number) =>
        services.find((service) => service.id === id)
    const isInCharges = (id: number) => {
        const name = getService(id)?.name
        return draftCharges?.some((item) => item.description === name)
    }

    const handleOpen = () => {
        setIsModalOpen(true)
    }
    const handleClose = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            {
                draftServices?.length !== 0 ?
                    <div style={{ maxWidth: "calc(100vw - 100px)", width: "100%", overflowX: "auto" }}>
                        <Table size={{ base: "sm", md: "sm", lg: "md" }}>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Amount</Th>
                                    <Th>Unit</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {draftServices?.map((draftService, index) => (
                                    <Tr key={index} h="50px">
                                        <Td>{typeof draftService?.service == "number" ? getService(Number(draftService?.service))?.name : draftService?.service}</Td>
                                        <Td>{draftService.quantity}</Td>
                                        <Td>{typeof draftService?.service == "number" ? getService(Number(draftService?.service))?.unit : null}</Td>
                                        <Td>
                                            {
                                                !isInCharges(Number(draftService?.service)) ?
                                                    <IconButton
                                                        aria-label="create-charge"
                                                        icon={<KnownIcon name="dollar" />}
                                                        size={["xs", "sm", "md"]}
                                                        onClick={() => {
                                                            handleOpen()
                                                            const { service, description, ...rest } = draftService
                                                            const data = {
                                                                description: getService(Number(draftService.service))?.name,
                                                                unit: getService(Number(draftService.service))?.unit,
                                                                ...rest,
                                                                allow_surcharge: true
                                                            }
                                                            setDefaultValues(data as any)
                                                        }}
                                                    />
                                                    :
                                                    null
                                            }
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </div>
                    :
                    <Text>{"There are no additional items"}</Text>
            }
            {/* Dialog for adding new charge entry */}
            <ModalForm
                title="Add New Entry"
                isOpen={isModalOpen}
                size={modalSize}
                onClose={handleClose}
                onCancel={handleClose}
                onSave={onSave}
                defaultValues={defaultValues}
            >
                <ChargesForm />
            </ModalForm>
        </>
    )
}

export default DraftTicketServicesOverview
