import { Box } from '@chakra-ui/react'
import { PuffLoader } from 'react-spinners'

function OverlayLoader() {
    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
            bg="rgba(0, 0, 0, 0.3)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex="overlay"
        >
            <PuffLoader
                color="#3D82CE"
                size={80}
            />
        </Box>
    )
}

export default OverlayLoader
