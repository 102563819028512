import { Box, FormControl, FormLabel, Grid, GridItem, IconButton } from "@chakra-ui/react"
import { Fragment, ReactNode, useEffect, useRef } from "react"
import { useFieldArray } from "react-hook-form"
import { KnownIcon } from "../widgets/KnownIcon"


interface DualFieldArrayProps extends FieldProps {
    children: (fieldName1: string, fieldName2: string) => ReactNode
    onRemove?(index: number): void
}

function FieldGroupArray({ name, label, children, onRemove }: DualFieldArrayProps) {

    const { fields, append, remove } = useFieldArray({ name })
    const appendedFirst = useRef(false)
    useEffect(() => {
        if (fields.length === 0 && !appendedFirst.current) {
            appendedFirst.current = true
            append({})
        }
    }, [append, fields])


    const handleAppend = () => append({});
    const handleRemove = (index: number) => {
        remove(index)
        onRemove && onRemove(index)
    };

    return (
        <Box w="full" >
            <FormControl >
                <FormLabel size="sm" fontWeight={600}>{label}</FormLabel>
                <Grid templateColumns="1fr 1fr auto" gap={2} >
                    <GridItem>
                        <FormLabel size="sm" fontWeight={600}>Start</FormLabel>
                    </GridItem>
                    <GridItem>
                        <FormLabel size="sm" fontWeight={600}>Finish</FormLabel>
                    </GridItem>
                    <GridItem>
                        <IconButton
                            aria-label={"Add"}
                            icon={<KnownIcon name={"plus"} />}
                            onClick={handleAppend}
                        />
                    </GridItem>
                    {fields.map((field, index) => {
                        const fieldName1 = `${name}[${index}].start`
                        const fieldName2 = `${name}[${index}].finish`

                        return (
                            <Fragment key={field.id}>
                                <GridItem>{(children(fieldName1, fieldName2) as React.ReactElement<any, string | React.JSXElementConstructor<any>>)?.props?.children?.[0] ?? null}</GridItem>
                                <GridItem>{(children(fieldName1, fieldName2) as React.ReactElement<any, string | React.JSXElementConstructor<any>>)?.props.children[1] ?? null}</GridItem>
                                <GridItem>
                                    {
                                        fields.length > 1 &&
                                        <IconButton
                                            aria-label={"Remove"}
                                            icon={<KnownIcon name={"minus"} />}
                                            onClick={() => handleRemove(index)}
                                        />
                                    }
                                </GridItem>
                            </Fragment>
                        )
                    })}
                </Grid>
            </FormControl >
        </Box>

    )
}
export default FieldGroupArray;
