import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  useMediaQuery,
  ButtonGroup,
  Button,
  WrapItem,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Panel } from "../../components/Panel"
import { formatMinutesAsHours, calculateDeltaMinutes } from "../../app/utils"
import { ActivityTrait } from "./ActivityTrait"
import { LocalEntry, addLocalEntry, removeLocalEntriesbyActivity, selectLocalEntries } from "./localEntriesSlice"
import { useMemo } from "react"
import { TimeEntryForm } from "./TimeEntryForm"
import { ModalForm } from "../../components/forms/ModalForm"
import { KnownIcon } from "../../components/widgets/KnownIcon"

interface DriverHoursPanelProps {
  draftNumber: string
  draftDriverHourEntries: LocalEntry[]
}

export function DriverHoursPanel({ draftNumber, draftDriverHourEntries }: DriverHoursPanelProps) {
  const dispatch = useAppDispatch()
  const localEntries = useAppSelector(selectLocalEntries) //retrive local entries
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure()
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
  const modalSize = isLargerThanMobile ? "lg" : "full"

  //group same activity's hours 
  const grouped: { [activity: number]: number } = useMemo(() => {
    return draftDriverHourEntries?.reduce((acc: any, entry: any) => {
      acc[entry.activity] =
        (acc[entry.activity] || 0) +
        calculateDeltaMinutes(entry.start, entry.finish)
      return acc
    }, {})
  }, [draftDriverHourEntries])
  const grandTotal = useMemo(() => {
    return Object.values(grouped).reduce(
      (acc, totalHours) => acc + totalHours,
      0,
    )
  }, [grouped])

  const handleSave = async (data: any) => {
    dispatch(addLocalEntry(data))
  }

  const handleRemove = (draftNumber: string, activity: number) => {
    const filteredEntries = localEntries.filter((item) => {
      return !(item.activity == activity && item.ticketDraft == draftNumber)
    })
    dispatch(removeLocalEntriesbyActivity(filteredEntries))
  }

  return (
    <>
      <Panel title="Driver Hours" subtitle={draftDriverHourEntries.length === 0 ? "No hours have yet been linked to this ticket" : "Just as reference here"}>
        {
          draftDriverHourEntries.length !== 0 ?
            <div style={{ maxWidth: "calc(100vw - 70px)", width: "100%", overflowX: "auto" }}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Activity</Th>
                    <Th>Total Hours</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.entries(grouped).map(([activity, minutes]) => (
                    <Tr key={activity}>
                      <Td>
                        <ActivityTrait choice={activity} />
                      </Td>
                      <Td>{formatMinutesAsHours(minutes)}</Td>
                      <Td>

                        <WrapItem gap={2} verticalAlign="center">
                          <IconButton
                            aria-label="Remove"
                            icon={<KnownIcon name="minus" />}
                            onClick={() => {
                              draftNumber && handleRemove(draftNumber, Number(activity))
                            }}
                          />
                        </WrapItem>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th fontWeight={700} color="black">
                      Total Hours:
                    </Th>
                    <Th color="black">{formatMinutesAsHours(grandTotal)}</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </div>
            :
            null
        }
        <ButtonGroup width="full" mt={3} flex="row" justifyContent="end">
          <Button colorScheme="gray" size={["sm", "md"]} onClick={openModal} isDisabled={!draftNumber ? true : false}>
            Add New Entry
          </Button>
        </ButtonGroup>

        <ModalForm
          title="Add New Entry"
          isOpen={isModalOpen}
          size={modalSize}
          onClose={closeModal}
          onCancel={closeModal}
          onSave={handleSave}
        >
          <TimeEntryForm />
        </ModalForm>
      </Panel>

    </>
  )
}
