import { useAppSelector } from "../../app/hooks"
import { AutocompleteField } from "../../components/forms/fields/AutocompleteField"
import { selectAllProducts } from "./productsSlice"

interface ProductAutocompleteProps extends FieldProps {}

export function ProductAutocomplete({
  label,
  name,
  registerOptions,
  helper,
}: ProductAutocompleteProps) {
  const products = useAppSelector(selectAllProducts)
  const suggestions = products.map((product) => ({
    value: product.id,
    text: product.name,
  }))

  return (
    <AutocompleteField
      label={label}
      name={name}
      suggestions={suggestions}
      registerOptions={registerOptions}
      helper={helper}
    />
  )
}
