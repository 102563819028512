import { FormPanel } from "../../components/forms/FormPanel"
import { FormPanelControls } from "../../components/forms/FormPanelControls"
import { FormPanelDisplay } from "../../components/forms/FormPanelDisplay"
import { PickUpDisplay } from "./PickUpDisplay"
import { PickUpForm } from "./PickUpForm"

interface PickUpProps {
  defaultValues: any
  onSave(data: any): void
  onRemove?(index: number): void
}

export function PickUpPanel({ defaultValues, onSave, onRemove }: PickUpProps) {
  return (
    <FormPanel
      title="Load"
      onSave={onSave}
      defaultValues={defaultValues}>
      <FormPanelDisplay>
        <PickUpDisplay draftExtra={defaultValues} />
      </FormPanelDisplay>
      <FormPanelControls>
        <PickUpForm
          onRemove={onRemove}
        />
      </FormPanelControls>
    </FormPanel>
  )
}
