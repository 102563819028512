import { createContext } from "react"
import { Box, Heading, Text, Flex, VStack } from "@chakra-ui/react"

import { KnownIcon, KnownIconName } from "./widgets/KnownIcon"

export const PanelContext = createContext<any>(null)

export interface PanelProps {
  title: string
  subtitle?: string
  icon?: KnownIconName
  context?: any
  children: React.ReactNode | React.ReactNode[]
}

export function Panel({
  title,
  subtitle,
  icon,
  children,
  context,
}: PanelProps) {
  return (
    <PanelContext.Provider value={context}>
      <Box
        w="full"
        fontSize="md"
        border="2px solid"
        borderColor="blue.200"
        rounded="lg"
        padding={[3, 3, 4, 5, 6]}
      >
        <VStack w="full" gap={6} alignItems={"flex-start"}>
          <Box w="full" mb={2}>
            <Flex align="center" fontSize="lg">
              <Heading as="h4" fontSize="lg" flexGrow={1}>
                {title}
              </Heading>
              {icon && <KnownIcon name={icon} />}
            </Flex>
            {subtitle && <Text fontSize="sm">{subtitle}</Text>}
          </Box>
          {children}
        </VStack>
      </Box>
    </PanelContext.Provider>
  )
}
