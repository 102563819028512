import React from 'react'
import { ReadonlyField } from '../../components/forms/fields/ReadonlyField'

interface DraftDroppOffProps {
    draftExtra: DroppOffValues
}

export function DroppOffDisplay({ draftExtra }: DraftDroppOffProps) {
    return (
        <>
            <ReadonlyField label="Actual Volume">
                {draftExtra?.actual_volume || "--"}
            </ReadonlyField>
            <ReadonlyField
                label="Description of Work"
                helper="Describe the work in few words"
            >
                {draftExtra?.work_description || "--"}
            </ReadonlyField>
        </>
    )
}

