import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  LinkBox,
  LinkOverlay,
  Flex,
  Box,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

interface TapTileProps {
  title: string
  subtitle?: string
  signature?: string
  href: string
  draftNumber?: string | null
  children?: React.ReactNode
}

export function TapTile({
  title,
  subtitle,
  signature,
  href,
  draftNumber,
  children,
}: TapTileProps) {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(href)
  }

  return (
    <LinkBox w="full">
      <Card colorScheme="blue" minH={40} w="full" bg="blue.300" rounded="lg">
        <CardHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Heading size={["md", "lg"]}>
                <LinkOverlay onClick={handleClick}>
                  {title}
                </LinkOverlay>
              </Heading>
              {subtitle && <Text>{subtitle}</Text>}
            </Box>
            {draftNumber ? (
              <Box>
                <Heading size={["md", "lg"]}>{draftNumber}</Heading>
              </Box>
            ) : null}
          </Flex>
        </CardHeader>
        <CardBody>{children}</CardBody>
        <CardFooter justifyContent="flex-end">
          <Text>{signature}</Text>
        </CardFooter>
      </Card>
    </LinkBox>
  )
}
