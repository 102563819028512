import { Box, VStack, Flex, ButtonGroup, Button } from "@chakra-ui/react"

import { ViewHeader } from "./parts/ViewHeader"
import { ViewStream } from "./parts/ViewStream"
import { PuffLoader } from 'react-spinners';
import { useLocation, useNavigate } from "react-router-dom";
import { KnownIcon } from "../../components/widgets/KnownIcon";
import { useNetworkCheck } from "../../app/hooks";

interface DetailViewProps {
  children: React.ReactNode
  title: string
  subtitle?: string
  isLoading?: boolean
}

export function DetailView({
  title,
  subtitle,
  children,
  isLoading = false,
}: DetailViewProps) {
  const navigate = useNavigate()
  const { isOnline } = useNetworkCheck()
  const location = useLocation().pathname
  return (
    <Box w="full" h="full" >
      <VStack h="full" >
        <ViewStream>
          {
            isLoading ?
              <Flex w="full" h="full" justifyContent="center" alignItems="center">
                <PuffLoader
                  color="#3D82CE"
                  size={80}
                />
              </Flex>
              :
              <>
                <Flex w="100%" justifyContent="space-between" alignContent="center" >
                  <ViewHeader subtitle={subtitle}>{title}</ViewHeader>
                  {
                    !isOnline && !location.includes("draft") &&
                    <Button
                      size={["xs", "sm", "md"]}
                      p={4} colorScheme="blue"
                      onClick={() => navigate("/user/home")}
                      leftIcon={<KnownIcon name="back" />}
                    >
                      Home
                    </Button>
                  }
                </Flex>
                {children}
              </>
          }
        </ViewStream>
      </VStack>
    </Box>
  )
}
