import { useTrait } from "../../app/hooks"
import { TraitLine } from "../../components/TraitLine"
import { selectProductById } from "./productsSlice"

interface ProductTraitProps extends TraitProps {}

export function ProductTrait({ id }: ProductTraitProps) {
  const { trait, isNew } = useTrait(
    (product) => product.name,
    id,
    selectProductById,
  )

  return <TraitLine isNew={isNew}>{trait}</TraitLine>
}
