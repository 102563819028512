import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Input,
} from "@chakra-ui/react"

import { RegisterOptions, useFormContext } from "react-hook-form"

interface InputFieldProps {
  label?: string
  name: string
  registerOptions?: RegisterOptions
  helper?: string
  readOnly?: boolean
}

export function InputField({
  label,
  name,
  registerOptions,
  helper,
  readOnly,
}: InputFieldProps) {
  const { register, formState } = useFormContext()
  return (
    <FormControl isInvalid={!!formState.errors?.[name]}>
      <FormLabel size="sm" fontWeight={600}>{label}</FormLabel>
      <InputGroup>
        <Input {...register(name, registerOptions)}
          placeholder="Write it here..."
          readOnly={readOnly}
        />
      </InputGroup>
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>{formState.errors?.[name]?.message?.toString()}</FormErrorMessage>
    </FormControl>
  )
}
